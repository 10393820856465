.c-0021 {
    width: 100%;
    overflow: hidden;
    padding: 0 0 50px 0;

    &__content {
        padding: 30px 30px 0;
        width: 100%;
        @include fromPhone {
            padding: 50px 30px 0;
        }
        @include fromTablet {
            padding: 48px 40px 0;
        }
        @include fromDesktop {
            padding: 50px 50px 0;
        }
        @include fromDesktop {
            padding: 50px 80px 0;
        }
        &__subheadline h3 {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 0;
            @include fromPhone {
                font-size: 25px;
                line-height: 36px;
            }
            @include fromDesktop {
                font-size: 30px;
                line-height: 36px;
            }
        }
        &__headline h2 {
            font-family: $font-primary-regular;
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 20px;
            @include fromPhone {
                font-size: 40px;
                line-height: 60px;
                margin-bottom: 47px;
            }
            @include fromDesktop {
                font-size: 50px;
                line-height: 60px;
                margin-bottom: 65px;
            }
        }
    }

    &__sliderWrapper {
        width: 100%;
       
        .slick-list {
            padding: 0 0 20px 0;
            width: 100%;
            @include fromPhone {
                padding: 0 30px 20px;
            }
            @include fromTablet {
                padding: 0 40px 20px;
            }
            @include fromDesktop {
                padding: 0 50px 20px;
            }
            @include fromDesktop {
                padding: 0 60px 20px;
            }
        }

        .slick-slide {
            width: 420px;
            margin: 0 0;
            max-width: 422px;
        }

        .slick-next {
            position: absolute;
            top: calc((((100vw / 4) * 3) / 2) - 27px);
            width: 55px;
            height: 55px;
            border-radius: 50%;
            background: rgba(16, 16, 16, 0.6);
            &:hover {
                background: rgba(16, 16, 16, 0.6);
                opacity: 0.4;
            }
            &:focus {
                background: rgba(16, 16, 16, 0.6);
            }
            z-index: 1;
            right: -27px;
            @include fromPhone {
                top: calc((((100vw / 4) * 3) / 2) - 27px);
                right: -39px;
                width: 75px;
                height: 75px;
            }
            @include fromTablet {
                top: calc((((768px / 4) * 3) / 2) - 37px);
                right: 30px;
            }
            @include fromDesktop {
                top: calc((((950px / 4) * 3) / 2) - 37px);
                right: 80px;
            }
            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                width: 100%;
                height: 100%;
                background-image: url(../Icons/relaunch/icon_arrow_weiss_right.svg);
                background-repeat: no-repeat;
                background-size: 16px 16px;
                background-position: 10px center;
                @include fromPhone {
                    background-size: 19px 19px;
                    background-position: 10px center;
                }
                @include fromTablet {
                    background-size: 19px 19px;
                    background-position: center center;
                }
            }
            &.slick-disabled {
                opacity: 0;
                cursor: default;
            }
        }

        .slick-prev {
            position: absolute;
            top: calc((((100vw / 4) * 3) / 2) - 27px);
            width: 55px;
            height: 55px;
            z-index: 1;
            border-radius: 50%;
            background: rgba(16, 16, 16, 0.6);
            &:hover {
                background: rgba(16, 16, 16, 0.6);
                opacity: 0.4;
            }
            &:focus {
                background: rgba(16, 16, 16, 0.6);
            }
            left: -27px;
            
            @include fromPhone {
                top: calc((((100vw / 4) * 3) / 2) - 27px);
                left: -39px;
                width: 75px;
                height: 75px;
            }
            @include fromTablet {
                top: calc((((768px / 4) * 3) / 2) - 37px);
                left: 30px;
            }
            @include fromDesktop {
                top: calc((((950px / 4) * 3) / 2) - 37px);
                left: 80px;
            }
            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                width: 100%;
                height: 100%;
                background-image: url(../Icons/relaunch/icon_arrow_weiss_left.svg);
                background-repeat: no-repeat;
                background-size: 16px 16px;
                background-position: 30px center;
                @include fromPhone {
                    background-size: 19px 19px;
                    background-position: 43px center;
                }
                @include fromTablet {
                    background-size: 19px 19px;
                    background-position: center center;
                }
            }
            &.slick-disabled {
                opacity: 0;
                cursor: default;
            }
        }

        .c-0021__sliderItem {
            width: 390px;
            max-width: calc(100vw - 30px);
            height: 820px;
            padding: 0 0 0 20px;

            @include fromPhone {
                max-width: 390px;
                //padding: 0 15px;
            }

            .m-0060 {
                .a-00600 {
                    height: 270px;
                    width: 100%;
                    img {
                        height: 270px;
                        width: auto;
                    }
                }
            }
            .m-0350__default-wrapper {
                .a-00600 {
                    height: 270px;
                    width: 100%;
                    img {
                        height: 270px;
                        width: auto;
                    }
                }
            }
            .m-0350__wrapper {
                .a-00600 {
                    height: 270px;
                    width: 100%;
                    img {
                        height: 270px;
                        width: auto;
                    }
                }
            }
        }
        ul.slick-dots {
            position: static;
            padding: 0 0 15px; 
            @include fromTablet {
                padding: 15px 0 33px;
            }
            @include fromDesktop {
                padding: 46px 0 53px;
            }
            li {
               width: 15px;
               height: 15px;
               margin: 0 15px 0 0;
               &.slick-active, &:hover {
                   button {
                       background-color: #000;
                       border: 2px solid #000;
                   } 
               }
               button {
                   width: 15px;
                   height: 15px;
                   background-color: #D2D2D2;
                   border: 2px solid #D2D2D2;
                   &::before {
                       width: 15px;
                       height: 15px;
                   }
               } 
           } 
        }
    }
}
