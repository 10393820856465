@charset "UTF-8";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1244px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, .price, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, .price, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .price, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1244px) {
    .container {
      max-width: 1220px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1244px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1243.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1244px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1243.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1244px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1244px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1244px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1244px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1244px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1244px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1244px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1244px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p, h2, .price,
  h3 {
    orphans: 3;
    widows: 3; }
  h2, .price,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-left: 11px;
  padding-right: 11px; }
  @media (min-width: 768px) {
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (min-width: 992px) {
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
      padding-left: 12px;
      padding-right: 12px; } }

.row {
  margin-left: -11px;
  margin-right: -11px; }
  @media (min-width: 768px) {
    .row {
      margin-left: -15px;
      margin-right: -15px; } }
  @media (min-width: 992px) {
    .row {
      margin-left: -12px;
      margin-right: -12px; } }

.container, .container-fluid {
  padding-left: 11px;
  padding-right: 11px; }
  @media (min-width: 768px) {
    .container, .container-fluid {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (min-width: 992px) {
    .container, .container-fluid {
      padding-left: 12px;
      padding-right: 12px; } }

/* Webfont: Alpha-Regular */
@font-face {
  font-family: 'mmc-regular';
  src: url("../Fonts/Mmc/MMC-Regular.woff2") format("woff2"), url("../Fonts/Mmc/MMC-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap; }

/* Webfont: Alpha-Bold */
@font-face {
  font-family: 'mmc-bold';
  src: url("../Fonts/Mmc/MMC-Bold.woff2") format("woff2"), url("../Fonts/Mmc/MMC-Bold.woff") format("woff");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap; }

body {
  min-width: 320px;
  overflow-x: hidden;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto;
  font-display: swap; }
  @media (min-width: 992px) {
    body {
      font-size: 16px; } }

* {
  margin: 0;
  padding: 0; }

.hero {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: black; }
  @media (min-width: 992px) {
    .hero {
      font-size: 30px;
      line-height: 40px; } }

h1, .h1, .h1alternative {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: black; }
  @media (min-width: 992px) {
    h1, .h1, .h1alternative {
      font-size: 28px;
      line-height: 38px; } }

h2, .price, .h2 {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 29px;
  color: black; }
  @media (min-width: 992px) {
    h2, .price, .h2 {
      font-size: 20px; } }

h3, .h3 {
  font-size: 14px;
  line-height: normal; }
  @media (min-width: 992px) {
    h3, .h3 {
      font-size: 20px; } }

h4, .h4 {
  font-size: 14px;
  line-height: normal; }
  @media (min-width: 992px) {
    h4, .h4 {
      font-size: 18px; } }

h5, .h5 {
  font-size: 14px; }

h6, .h6 {
  font-size: 14px; }

p {
  word-wrap: break-word;
  hyphens: auto;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto; }
  @media (min-width: 992px) {
    p {
      font-size: 16px; } }
  p:empty {
    margin: 0;
    padding: 0; }

.cta {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .cta {
      font-size: 14px; } }

.price {
  font-size: 14px; }

.tag {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #575656; }
  @media (min-width: 992px) {
    .tag {
      font-size: 14px; } }

.note {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #575656; }
  @media (min-width: 992px) {
    .note {
      font-size: 12px; } }

.rating {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 60px;
  line-height: 45px;
  color: #ED0000; }

strong {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-weight: normal; }

.text-color-white {
  color: white; }

.text-color-red {
  color: #ED0000; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.subHeadline {
  font-size: 30px;
  text-transform: uppercase; }
  .subHeadline .subHeadline--black {
    color: black; }
  .subHeadline .subHeadline--black {
    color: black; }

.image-embed-item {
  display: block;
  max-width: 100%;
  height: auto; }

a {
  letter-spacing: 0.05px;
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #575656;
  font-weight: normal;
  line-height: 21px; }
  a:hover {
    text-decoration: none;
    color: black; }
  a:visited {
    color: #ED0000; }

section {
  margin-top: 40px; }
  @media (min-width: 768px) {
    section {
      margin-top: 20px; } }

.background {
  background-color: #E4E4E5; }

.btn:focus, .btn:active, .btn:active:focus {
  outline: 0; }

.btn-editMode {
  color: white;
  background-color: #29aab3;
  border-color: #29aab3; }

.contentContainer {
  padding: 30px; }

.hiddenAnimate {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear; }
  .hiddenAnimate.show {
    visibility: visible;
    opacity: 1; }

.row-eq-height {
  display: flex;
  flex-wrap: wrap; }

.row::before, .row::after {
  flex-basis: 0;
  order: 1; }

.scrollTop {
  position: relative;
  left: 50%;
  transform: translateX(-50%); }

.fromPhone {
  display: none !important; }
  @media (min-width: 576px) {
    .fromPhone {
      display: block !important; } }
  .fromPhone--flex {
    display: none !important; }
    @media (min-width: 576px) {
      .fromPhone--flex {
        display: flex !important; } }

.fromPhonePlus {
  display: none !important; }
  @media (min-width: 577px) {
    .fromPhonePlus {
      display: block !important; } }
  .fromPhonePlus--flex {
    display: none !important; }
    @media (min-width: 577px) {
      .fromPhonePlus--flex {
        display: flex !important; } }

.fromTablet {
  display: none !important; }
  @media (min-width: 768px) {
    .fromTablet {
      display: block !important; } }
  .fromTablet--flex {
    display: none !important; }
    @media (min-width: 768px) {
      .fromTablet--flex {
        display: flex !important; } }

.toTablet {
  display: none !important; }
  @media (max-width: 767px) {
    .toTablet {
      display: block !important; } }
  .toTablet--flex {
    display: none !important; }
    @media (max-width: 767px) {
      .toTablet--flex {
        display: flex !important; } }

.mobile {
  display: block; }
  @media (min-width: 992px) {
    .mobile {
      display: none !important; } }
  .mobile--flex {
    display: flex; }
    @media (min-width: 992px) {
      .mobile--flex {
        display: none !important; } }

.mobile-only {
  display: block; }
  @media (min-width: 992px) {
    .mobile-only {
      display: none !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .mobile-only {
      display: none !important; } }
  .mobile-only--flex {
    display: flex; }
    @media (min-width: 992px) {
      .mobile-only--flex {
        display: none !important; } }

.mobile-to-576 {
  display: block; }
  @media (min-width: 992px) {
    .mobile-to-576 {
      display: none !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .mobile-to-576 {
      display: none !important; } }
  @media (min-width: 577px) {
    .mobile-to-576 {
      display: none !important; } }
  .mobile-to-576--flex {
    display: flex; }
    @media (min-width: 992px) {
      .mobile-to-576--flex {
        display: none !important; } }

.desktop {
  display: none !important; }
  @media (min-width: 992px) {
    .desktop {
      display: block !important; } }
  .desktop--flex {
    display: none !important; }
    @media (min-width: 992px) {
      .desktop--flex {
        display: flex !important; } }

.extendedDesktop {
  display: none !important; }
  @media (min-width: 992px) {
    .extendedDesktop {
      display: block !important; } }
  .extendedDesktop--flex {
    display: none !important; }
    @media (min-width: 992px) {
      .extendedDesktop--flex {
        display: flex !important; } }

.wideDesktop {
  display: none !important; }
  @media (min-width: 1244px) {
    .wideDesktop {
      display: block !important; } }
  .wideDesktop--flex {
    display: none !important; }
    @media (min-width: 1244px) {
      .wideDesktop--flex {
        display: flex !important; } }

.extraWide {
  display: none !important; }
  @media (min-width: 1920px) {
    .extraWide {
      display: block !important; } }
  .extraWide--flex {
    display: none !important; }
    @media (min-width: 1920px) {
      .extraWide--flex {
        display: flex !important; } }

.tablet {
  display: none !important; }
  @media (min-width: 768px) and (max-width: 991px) {
    .tablet {
      display: block !important; } }
  .tablet--flex {
    display: none !important; }
    @media (min-width: 768px) and (max-width: 991px) {
      .tablet--flex {
        display: flex !important; } }

.extendedTablet {
  display: none !important; }
  @media (min-width: 577px) and (max-width: 991px) {
    .extendedTablet {
      display: block !important; } }
  .extendedTablet--flex {
    display: none !important; }
    @media (min-width: 577px) and (max-width: 991px) {
      .extendedTablet--flex {
        display: flex !important; } }

.icon--modellbereich_ggk {
  max-width: 26px;
  position: relative;
  top: 2px; }

.mat-select-value {
  color: #575656 !important; }

.alert {
  font-size: 12px;
  line-height: 17px;
  padding: 0 8px;
  color: #575656;
  margin-bottom: 0; }

.checkboxgroup .alert {
  position: relative;
  top: -15px; }

@media (max-width: 767px) {
  .mainContent > .container {
    padding-left: 0;
    padding-right: 0; } }

.ce-align-left {
  text-align: left; }

.ce-align-center {
  text-align: center; }

.ce-align-right {
  text-align: right; }

.ce-table td,
.ce-table th {
  vertical-align: top; }

.ce-textpic,
.ce-image,
.ce-nowrap .ce-bodytext,
.ce-gallery,
.ce-row,
.ce-uploads li,
.ce-uploads div {
  overflow: hidden; }

.ce-left .ce-gallery,
.ce-column {
  float: left; }

.ce-center .ce-outer {
  position: relative;
  float: right;
  right: 50%; }

.ce-center .ce-inner {
  position: relative;
  float: right;
  right: -50%; }

.ce-right .ce-gallery {
  float: right; }

.ce-gallery figure {
  display: table;
  margin: 0; }

.ce-gallery figcaption {
  display: table-caption;
  caption-side: bottom; }

.ce-gallery img {
  display: block; }

.ce-gallery iframe {
  border-width: 0; }

.ce-border img,
.ce-border iframe {
  border: 2px solid #000000;
  padding: 0px; }

.ce-intext.ce-right .ce-gallery,
.ce-intext.ce-left .ce-gallery,
.ce-above .ce-gallery {
  margin-bottom: 10px; }

.ce-intext.ce-right .ce-gallery {
  margin-left: 10px; }

.ce-intext.ce-left .ce-gallery {
  margin-right: 10px; }

.ce-below .ce-gallery {
  margin-top: 10px; }

.ce-column {
  margin-right: 10px; }

.ce-column:last-child {
  margin-right: 0; }

.ce-row {
  margin-bottom: 10px; }

.ce-row:last-child {
  margin-bottom: 0; }

.ce-above .ce-bodytext {
  clear: both; }

.ce-intext.ce-left ol,
.ce-intext.ce-left ul {
  padding-left: 40px;
  overflow: auto; }

/* Headline */
.ce-headline-left {
  text-align: left; }

.ce-headline-center {
  text-align: center; }

.ce-headline-right {
  text-align: right; }

/* Uploads */
.ce-uploads {
  margin: 0;
  padding: 0; }

.ce-uploads li {
  list-style: none outside none;
  margin: 1em 0; }

.ce-uploads img {
  float: left;
  padding-right: 1em;
  vertical-align: top; }

.ce-uploads span {
  display: block; }

/* Table */
.ce-table {
  width: 100%;
  max-width: 100%; }

.ce-table th,
.ce-table td {
  padding: 0.5em 0.75em;
  vertical-align: top; }

.ce-table thead th {
  border-bottom: 2px solid #dadada; }

.ce-table th,
.ce-table td {
  border-top: 1px solid #dadada; }

.ce-table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.ce-table-bordered th,
.ce-table-bordered td {
  border: 1px solid #dadada; }

/* Space */
.frame-space-before-extra-small {
  margin-top: 1em; }

.frame-space-before-small {
  margin-top: 2em; }

.frame-space-before-medium {
  margin-top: 3em; }

.frame-space-before-large {
  margin-top: 4em; }

.frame-space-before-extra-large {
  margin-top: 5em; }

.frame-space-after-extra-small {
  margin-bottom: 1em; }

.frame-space-after-small {
  margin-bottom: 2em; }

.frame-space-after-medium {
  margin-bottom: 3em; }

.frame-space-after-large {
  margin-bottom: 4em; }

.frame-space-after-extra-large {
  margin-bottom: 5em; }

/* Frame */
.frame-ruler-before:before {
  content: '';
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  margin-bottom: 2em; }

.frame-ruler-after:after {
  content: '';
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  margin-top: 2em; }

.frame-indent {
  margin-left: 15%;
  margin-right: 15%; }

.frame-indent-left {
  margin-left: 33%; }

.frame-indent-right {
  margin-right: 33%; }

.container > .row > div > .row > .frame-default > section > .container {
  width: auto; }

button {
  outline: 0 !important; }
  button[type='submit'] {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw; }
    @media (min-width: 992px) {
      button[type='submit'] {
        left: 0;
        right: 0;
        width: 100%;
        max-width: 530px;
        margin: 0 auto; } }
  button[disabled] {
    opacity: 0.3; }

.loading-spinner {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px; }
  .loading-spinner .icon {
    width: 60px;
    height: 55px;
    margin: 0 auto; }
  .loading-spinner svg {
    opacity: 0.7;
    animation: blink 2s ease-in-out infinite; }

@keyframes blink {
  50% {
    opacity: 0; }
  100% {
    opacity: 0.7; } }

.col--copy {
  padding: 0 27px; }

.fuelData p, .copyright {
  margin-top: 33px; }

.copyright strong {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-weight: normal; }

.legalTexts, .fuelDataExtra {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto;
  font-weight: 400 !important; }
  @media (min-width: 992px) {
    .legalTexts, .fuelDataExtra {
      font-size: 16px; } }
  .legalTexts strong, .legalTexts em, .legalTexts b, .fuelDataExtra strong, .fuelDataExtra em, .fuelDataExtra b {
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    font-style: normal;
    font-weight: 400 !important; }
    @media (min-width: 992px) {
      .legalTexts strong, .legalTexts em, .legalTexts b, .fuelDataExtra strong, .fuelDataExtra em, .fuelDataExtra b {
        font-size: 16px; } }

footer {
  padding-top: 20px;
  padding-bottom: 80px; }
  @media (min-width: 992px) {
    footer {
      padding-bottom: 0; } }

#footer.show {
  transition: opacity 400ms; }

#footer.hide {
  opacity: 0;
  visibility: hidden; }

footer > div.container > div > div > div.fuelData > div > div > p:nth-child(1) strong {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: black;
  font-weight: 400 !important; }
  @media (min-width: 992px) {
    footer > div.container > div > div > div.fuelData > div > div > p:nth-child(1) strong {
      font-size: 28px;
      line-height: 38px; } }

ngb-modal-window .modal-content {
  border-radius: 0; }

.scaleIn .modal-content {
  animation-name: scaleIn;
  animation-duration: 0.3s; }

@keyframes scaleIn {
  0% {
    transform: scale(0.5); }
  75% {
    transform: scale(1.05); }
  100% {
    transform: scale(1); } }

.fadeIn .modal-content {
  animation-name: fadeIn;
  animation-duration: 0.3s; }

@keyframes fadeIn {
  0% {
    opacity: 0.5; }
  75% {
    opacity: 0.9; }
  100% {
    opacity: 1; } }

ngb-modal-window.px-modal-overlay {
  z-index: 1070; }

ngb-modal-window.fullscreen {
  height: 100vh;
  padding-bottom: 90px;
  background-color: white; }
  @media (min-width: 768px) {
    ngb-modal-window.fullscreen {
      padding-bottom: 59px; } }
  ngb-modal-window.fullscreen .modal-dialog {
    height: 100%;
    max-width: 100%; }
    @media (max-width: 767px) {
      ngb-modal-window.fullscreen .modal-dialog {
        margin: 0; } }
    @media (min-width: 768px) {
      ngb-modal-window.fullscreen .modal-dialog {
        margin: auto auto; } }
  ngb-modal-window.fullscreen .modal-content {
    border: 0 !important;
    height: 100%; }
  ngb-modal-window.fullscreen .modal-body {
    padding: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden; }
    ngb-modal-window.fullscreen .modal-body strong, ngb-modal-window.fullscreen .modal-body b {
      font-family: 'mmc-bold', Helvetica, Arial, sans-serif;
      font-weight: normal; }

@media (max-width: 575px) {
  ngb-modal-window.narrow .modal-dialog {
    margin: 27px; } }

ngb-modal-window.narrow .modal-content {
  border: 0 !important; }

ngb-modal-backdrop.px-modal-overlay {
  z-index: 1070 !important; }

.modal-body strong, .modal-body b {
  font-family: 'mmc-bold', Helvetica, Arial, sans-serif;
  font-weight: normal; }

.sticky-spacer.active {
  height: 44px; }

.mainContent sup {
  cursor: pointer; }

.a-00100 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; }
  .a-00100--a {
    height: 51px;
    width: 47px; }
    .a-00100--a svg {
      max-width: 100%;
      height: auto; }
    @media (min-width: 992px) {
      .a-00100--a {
        height: 60px;
        width: 56px; } }
  .a-00100--b {
    height: 50px;
    width: 164px;
    color: white;
    font-size: 12px; }
    @media (min-width: 992px) {
      .a-00100--b {
        height: 59px;
        width: 191px; } }
    .a-00100--b img {
      object-fit: contain;
      object-position: 0;
      max-height: 60px;
      max-width: 100%; }
      @media (min-width: 992px) {
        .a-00100--b img {
          height: 60px; } }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .a-00100--b img {
          display: none; } }
  .a-00100__image-ie {
    display: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .a-00100__image-ie {
        display: block;
        height: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: -20px;
        left: 0;
        right: 0;
        bottom: 0;
        background-position: 0 50%; } }

.a-00200 {
  padding-left: 17px;
  height: 39px;
  width: 320px;
  background-color: #E3E5E6;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: none;
  box-shadow: none; }
  .a-00200__arrow {
    font-size: 18px;
    color: #9E9FA0;
    font-weight: bold; }
  .a-00200__text {
    font-size: 12px;
    color: black;
    font-weight: bold;
    margin-left: 8px; }

.a-00300 span {
  padding: 3px 6px;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: black; }
  @media (min-width: 992px) {
    .a-00300 span {
      font-size: 30px;
      line-height: 40px; } }
  @media (min-width: 992px) {
    .a-00300 span {
      padding: 7px 12px;
      line-height: 48px; } }

.a-00300--a span {
  color: white;
  background-color: #575656; }

.a-00300--b span {
  color: white;
  background-color: #ED0000; }

.a-00300--c span {
  color: black;
  background-color: white; }

.a-00300--d span {
  color: #ED0000;
  background-color: white; }

.a-00400 {
  width: 30px;
  height: 30px;
  background-color: #ED0000;
  display: flex;
  justify-content: center;
  align-items: center; }
  .a-00400 span {
    color: white;
    line-height: 10px; }
    .a-00400 span svg {
      width: 100%;
      height: 100%;
      max-width: 13px;
      max-height: 13px; }
      .a-00400 span svg g, .a-00400 span svg path, .a-00400 span svg polygon, .a-00400 span svg rect {
        fill: white !important; }

.a-00510--c img {
  width: 116px;
  height: 105px;
  object-fit: contain; }

.a-00520__map {
  max-width: 122px;
  margin-left: auto;
  max-height: 69px; }

.a-00600 {
  position: relative; }
  .a-00600 .gradient {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    z-index: 2; }
  .a-00600--a .gradient {
    height: 46%; }
  .a-00600--b .gradient {
    height: 42%; }
  .a-00600--c .gradient {
    height: 42%; }
  .a-00600--c .galleryWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden; }
  .a-00600--c .galleryElement {
    width: 50%;
    position: relative;
    z-index: 1; }
    .a-00600--c .galleryElement:nth-child(2) {
      left: 1px; }
    .a-00600--c .galleryElement:nth-child(3) {
      top: 1px; }
    .a-00600--c .galleryElement:nth-child(4) {
      top: 1px;
      left: 1px; }
  .a-00600--d .gradient, .a-00600--e .gradient {
    height: 58%; }

.a-00700 {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
  transition: all 500ms cubic-bezier(0.75, -0.005, 0.265, 0.995);
  transition-timing-function: cubic-bezier(0.75, -0.005, 0.265, 0.995);
  cursor: pointer; }
  @media (max-width: 992px) {
    .a-00700:hover {
      background-color: #575656; } }
  .a-00700.disabled {
    background-color: #B1B2B3; }
  .a-00700 .elementWrapper {
    display: flex;
    align-items: center; }
  .a-00700 .icon {
    margin-left: 20px;
    width: 36px;
    height: 23px; }
    .a-00700 .icon svg {
      width: 100%;
      height: 100%; }
      .a-00700 .icon svg g, .a-00700 .icon svg path, .a-00700 .icon svg polygon, .a-00700 .icon svg rect {
        fill: #fff !important; }
      @media (min-width: 992px) {
        .a-00700 .icon svg g, .a-00700 .icon svg path, .a-00700 .icon svg polygon, .a-00700 .icon svg rect {
          fill: black !important; } }
  .a-00700 .text {
    margin-left: 18px;
    color: white;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .a-00700 .text {
        font-size: 14px; } }
    @media (min-width: 992px) {
      .a-00700 .text {
        color: black; } }
  .a-00700 .arrow {
    float: right;
    margin-right: 17px;
    width: 9px;
    height: 24px;
    color: white; }
    .a-00700 .arrow svg {
      width: 100%;
      height: 100%; }
      .a-00700 .arrow svg g, .a-00700 .arrow svg path, .a-00700 .arrow svg polygon, .a-00700 .arrow svg rect {
        fill: #fff !important; }
      @media (min-width: 992px) {
        .a-00700 .arrow svg g, .a-00700 .arrow svg path, .a-00700 .arrow svg polygon, .a-00700 .arrow svg rect {
          fill: black !important; } }
  @media (min-width: 992px) {
    .a-00700__easing, .a-00700 .text, .a-00700 svg g, .a-00700 svg path, .a-00700 svg polygon {
      transition: all 500ms cubic-bezier(0.75, -0.005, 0.265, 0.995);
      transition-timing-function: cubic-bezier(0.75, -0.005, 0.265, 0.995); } }
  @media (min-width: 992px) {
    .a-00700__easing {
      position: absolute;
      width: 0;
      top: 0;
      /*Change 'top' to 'bottom' for an upwards transition*/
      height: 50px;
      background-color: black; } }
  .a-00700__wrapper {
    position: relative;
    height: 50px;
    width: 100%;
    background-color: black;
    transition: all 0.6s; }
    @media (min-width: 992px) {
      .a-00700__wrapper {
        background-color: white; }
        .a-00700__wrapper:hover .a-00700 {
          z-index: 10; }
          .a-00700__wrapper:hover .a-00700 svg g, .a-00700__wrapper:hover .a-00700 svg path, .a-00700__wrapper:hover .a-00700 svg polygon {
            fill: black !important; } }
      @media (min-width: 992px) and (min-width: 992px) {
        .a-00700__wrapper:hover .a-00700 svg g, .a-00700__wrapper:hover .a-00700 svg path, .a-00700__wrapper:hover .a-00700 svg polygon {
          fill: white !important; } }
    @media (min-width: 992px) {
          .a-00700__wrapper:hover .a-00700__easing {
            width: 100%; }
          .a-00700__wrapper:hover .a-00700 .arrow, .a-00700__wrapper:hover .a-00700 .text {
            color: black; } }
      @media (min-width: 992px) and (min-width: 992px) {
        .a-00700__wrapper:hover .a-00700 .arrow, .a-00700__wrapper:hover .a-00700 .text {
          color: white; } }
  .a-00700--b {
    max-width: 267px; }
  @media (max-width: 992px) {
    .a-00700--c:hover {
      background-color: #575656; } }
  .a-00700--c .icon svg g, .a-00700--c .icon svg path, .a-00700--c .icon svg polygon, .a-00700--c .icon svg rect {
    fill: black !important; }
  @media (min-width: 992px) {
    .a-00700--c .icon svg g, .a-00700--c .icon svg path, .a-00700--c .icon svg polygon, .a-00700--c .icon svg rect {
      fill: black !important; } }
  .a-00700--c .text {
    color: white; }
    @media (min-width: 992px) {
      .a-00700--c .text {
        color: white; } }
  .a-00700--c .arrow {
    color: white; }
    .a-00700--c .arrow svg g, .a-00700--c .arrow svg path, .a-00700--c .arrow svg polygon, .a-00700--c .arrow svg rect {
      fill: white !important; }
    @media (min-width: 992px) {
      .a-00700--c .arrow svg g, .a-00700--c .arrow svg path, .a-00700--c .arrow svg polygon, .a-00700--c .arrow svg rect {
        fill: white !important; } }
  @media (min-width: 992px) {
    .a-00700--c .a-00700__easing {
      background-color: white; } }
  .a-00700--c.a-00700__wrapper {
    background-color: black; }
    @media (min-width: 992px) {
      .a-00700--c.a-00700__wrapper {
        background-color: black; }
        .a-00700--c.a-00700__wrapper:hover .a-00700 svg g, .a-00700--c.a-00700__wrapper:hover .a-00700 svg path, .a-00700--c.a-00700__wrapper:hover .a-00700 svg polygon {
          fill: black !important; } }
    @media (min-width: 992px) and (min-width: 992px) {
      .a-00700--c.a-00700__wrapper:hover .a-00700 svg g, .a-00700--c.a-00700__wrapper:hover .a-00700 svg path, .a-00700--c.a-00700__wrapper:hover .a-00700 svg polygon {
        fill: black !important; } }
    @media (min-width: 992px) {
        .a-00700--c.a-00700__wrapper:hover .a-00700 .arrow, .a-00700--c.a-00700__wrapper:hover .a-00700 .text {
          color: black; } }
    @media (min-width: 992px) and (min-width: 992px) {
      .a-00700--c.a-00700__wrapper:hover .a-00700 .arrow, .a-00700--c.a-00700__wrapper:hover .a-00700 .text {
        color: black; } }
  .a-00700--d .icon svg g, .a-00700--d .icon svg path, .a-00700--d .icon svg polygon, .a-00700--d .icon svg rect {
    fill: white !important; }
  @media (min-width: 992px) {
    .a-00700--d .icon svg g, .a-00700--d .icon svg path, .a-00700--d .icon svg polygon, .a-00700--d .icon svg rect {
      fill: white !important; } }
  .a-00700--d .text {
    color: white; }
    @media (min-width: 992px) {
      .a-00700--d .text {
        color: white; } }
  .a-00700--d .arrow {
    color: white; }
    .a-00700--d .arrow svg g, .a-00700--d .arrow svg path, .a-00700--d .arrow svg polygon, .a-00700--d .arrow svg rect {
      fill: white !important; }
    @media (min-width: 992px) {
      .a-00700--d .arrow svg g, .a-00700--d .arrow svg path, .a-00700--d .arrow svg polygon, .a-00700--d .arrow svg rect {
        fill: white !important; } }
  .a-00700--d .a-00700__easing {
    display: none; }
  .a-00700--d.a-00700__wrapper {
    background-color: black; }
    .a-00700--d.a-00700__wrapper:hover {
      background-color: #575656; }
    .a-00700--d.a-00700__wrapper:active {
      background-color: #ED0000; }
      .a-00700--d.a-00700__wrapper:active .arrow {
        color: white; }
        .a-00700--d.a-00700__wrapper:active .arrow svg g, .a-00700--d.a-00700__wrapper:active .arrow svg path, .a-00700--d.a-00700__wrapper:active .arrow svg polygon, .a-00700--d.a-00700__wrapper:active .arrow svg rect {
          fill: white !important; }
        @media (min-width: 992px) {
          .a-00700--d.a-00700__wrapper:active .arrow svg g, .a-00700--d.a-00700__wrapper:active .arrow svg path, .a-00700--d.a-00700__wrapper:active .arrow svg polygon, .a-00700--d.a-00700__wrapper:active .arrow svg rect {
            fill: white !important; } }
    .a-00700--d.a-00700__wrapper.disabled, .a-00700--d.a-00700__wrapper[disabled] {
      opacity: 1;
      background-color: #cccccc; }
  .a-00700--e .icon svg g, .a-00700--e .icon svg path, .a-00700--e .icon svg polygon, .a-00700--e .icon svg rect {
    fill: white !important; }
  @media (min-width: 992px) {
    .a-00700--e .icon svg g, .a-00700--e .icon svg path, .a-00700--e .icon svg polygon, .a-00700--e .icon svg rect {
      fill: white !important; } }
  .a-00700--e .text {
    color: white; }
    @media (min-width: 992px) {
      .a-00700--e .text {
        color: white; } }
  .a-00700--e .arrow {
    color: white; }
    .a-00700--e .arrow svg g, .a-00700--e .arrow svg path, .a-00700--e .arrow svg polygon, .a-00700--e .arrow svg rect {
      fill: white !important; }
    @media (min-width: 992px) {
      .a-00700--e .arrow svg g, .a-00700--e .arrow svg path, .a-00700--e .arrow svg polygon, .a-00700--e .arrow svg rect {
        fill: white !important; } }
  .a-00700--e .a-00700__easing {
    display: none; }
  .a-00700--e.a-00700__wrapper {
    background-color: black; }
    @media (min-width: 992px) {
      .a-00700--e.a-00700__wrapper:hover .a-00700 svg g, .a-00700--e.a-00700__wrapper:hover .a-00700 svg path, .a-00700--e.a-00700__wrapper:hover .a-00700 svg polygon {
        fill: black !important; } }
  @media (min-width: 992px) and (min-width: 992px) {
    .a-00700--e.a-00700__wrapper:hover .a-00700 svg g, .a-00700--e.a-00700__wrapper:hover .a-00700 svg path, .a-00700--e.a-00700__wrapper:hover .a-00700 svg polygon {
      fill: black !important; } }
    @media (min-width: 992px) {
      .a-00700--e.a-00700__wrapper:hover .a-00700 .arrow, .a-00700--e.a-00700__wrapper:hover .a-00700 .text {
        color: black; } }
  @media (min-width: 992px) and (min-width: 992px) {
    .a-00700--e.a-00700__wrapper:hover .a-00700 .arrow, .a-00700--e.a-00700__wrapper:hover .a-00700 .text {
      color: black; } }
    .a-00700--e.a-00700__wrapper:hover {
      background-color: white; }
    .a-00700--e.a-00700__wrapper:active {
      background-color: #ED0000; }
    .a-00700--e.a-00700__wrapper.disabled, .a-00700--e.a-00700__wrapper[disabled] {
      opacity: 1;
      background-color: #cccccc; }

.a-00800 {
  height: 44px;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  border: 0;
  cursor: pointer; }
  @media (min-width: 992px) {
    .a-00800 {
      max-width: 267px; } }
  .a-00800:hover {
    background-color: #575656; }
  .a-00800:active {
    background-color: #ED0000; }
  .a-00800.disabled, .a-00800[disabled] {
    opacity: 1;
    background-color: #cccccc; }
  .a-00800 .elementWrapper {
    display: flex;
    align-items: center; }
  .a-00800 .text {
    text-transform: uppercase;
    padding: 0 15px;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: white; }
    @media (min-width: 992px) {
      .a-00800 .text {
        font-size: 14px; } }
  .a-00800--b {
    max-width: 267px; }
  .a-00800--c {
    max-width: 267px;
    background-color: white;
    border: 1px solid black; }
    .a-00800--c .text {
      color: black; }
    .a-00800--c:hover {
      background-color: white; }
      .a-00800--c:hover .text {
        color: #575656; }
  .a-00800--mobile-sticky {
    position: fixed !important;
    bottom: 50px;
    z-index: 1; }
    @media (min-width: 768px) {
      .a-00800--mobile-sticky {
        bottom: 59px; } }
    @media (min-width: 992px) {
      .a-00800--mobile-sticky {
        position: relative !important;
        bottom: 0; } }

.a-00900 {
  height: 44px;
  width: 100%;
  background-color: black;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .a-00900:hover {
    background-color: #575656; }
  .a-00900:focus {
    background-color: #ED0000; }
  .a-00900.disabled {
    background-color: #B1B2B3; }
  .a-00900 .elementWrapper {
    display: flex;
    align-items: center; }
  .a-00900 .icon {
    width: 18px;
    position: absolute;
    left: 20px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .a-00900 .icon.calendar_datepicker_footer_weiss {
        height: 22px;
        top: 9px; } }
    .a-00900 .icon svg {
      width: 100%;
      height: 100%; }
      .a-00900 .icon svg g, .a-00900 .icon svg path, .a-00900 .icon svg polygon, .a-00900 .icon svg rect {
        fill: #fff !important; }
  .a-00900 .cta, .a-00900 .text {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: white !important; }
    @media (min-width: 992px) {
      .a-00900 .cta, .a-00900 .text {
        font-size: 14px; } }
  @media (min-width: 768px) {
    .a-00900.big {
      max-width: 286px;
      margin: 0 auto; } }
  @media (min-width: 992px) {
    .a-00900.big {
      max-width: 480px; } }
  .a-00900--b {
    background-color: white; }
    .a-00900--b .icon {
      /* START - Only for Preview purpose - REMOVE if not needed anymore*/
      width: 20px;
      height: 20px;
      background: black;
      /* END - Only for Preview purpose - REMOVE if not needed anymore*/ }
    .a-00900--b .cta, .a-00900--b .text {
      color: black !important; }
    .a-00900--b:hover {
      background-color: white; }
      .a-00900--b:hover .cta, .a-00900--b:hover .text {
        color: #575656 !important; }

.a-01000 {
  height: 44px;
  width: 100%;
  max-width: 267px;
  background-color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  cursor: pointer; }
  .a-01000 .icon {
    position: absolute;
    display: inline-block;
    right: 30px;
    opacity: 0;
    transition: opacity 0.2s, right 0.2s;
    /* START - Only for Preview purpose - REMOVE if not needed anymore*/
    /* END - Only for Preview purpose - REMOVE if not needed anymore*/ }
    .a-01000 .icon svg {
      width: 8px;
      height: 12px;
      margin-bottom: 2px; }
  .a-01000 .text, .a-01000 span {
    text-transform: uppercase;
    text-align: center;
    padding: 0 15px;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: black; }
    @media (min-width: 992px) {
      .a-01000 .text, .a-01000 span {
        font-size: 14px; } }
  .a-01000:hover .icon {
    right: 20px;
    opacity: 1;
    animation-name: a-01000MoveFade;
    animation-duration: .4s; }
    .a-01000:hover .icon svg g, .a-01000:hover .icon svg path, .a-01000:hover .icon svg polygon, .a-01000:hover .icon svg rect {
      fill: black !important; }
  .a-01000:active {
    background-color: black; }
    .a-01000:active .text {
      color: white; }
  .a-01000 .elementWrapper {
    display: flex;
    align-items: center; }
  .a-01000--b {
    background-color: black;
    border-color: white; }
    .a-01000--b .icon svg g, .a-01000--b .icon svg path, .a-01000--b .icon svg polygon, .a-01000--b .icon svg rect {
      fill: white !important; }
    .a-01000--b .text {
      color: white; }
    .a-01000--b:hover .icon svg g, .a-01000--b:hover .icon svg path, .a-01000--b:hover .icon svg polygon, .a-01000--b:hover .icon svg rect {
      fill: white !important; }
    .a-01000--b:focus {
      background-color: white; }
      .a-01000--b:focus .text {
        color: black; }
  .a-01000--c {
    background-color: "transparent";
    border: 2px solid white; }
    .a-01000--c .text {
      color: white; }
    .a-01000--c:hover {
      background-color: #B1B2B3; }
  .a-01000.disabled {
    border-color: #B1B2B3; }
    .a-01000.disabled .text {
      color: #B1B2B3; }
    .a-01000.disabled .icon {
      display: none; }

@keyframes a-01000MoveFade {
  0% {
    right: 30px;
    opacity: 0; }
  80% {
    right: 17px;
    opacity: 1; }
  100% {
    right: 20px; } }

@keyframes a-01000MoveOut {
  0% {
    right: 20px; }
  100% {
    right: 30px; } }

.a-01100 .text {
  text-transform: uppercase;
  text-align: center;
  margin-top: 4px;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: black; }
  @media (min-width: 992px) {
    .a-01100 .text {
      font-size: 14px; } }

.a-01100 .icon {
  display: inline-block;
  margin-left: 5px;
  color: black;
  position: relative; }
  .a-01100 .icon svg {
    width: 8px;
    height: 12px;
    margin-bottom: 2px; }

.a-01100:hover .text {
  color: #575656; }

.a-01100:hover .icon {
  animation-name: move;
  animation-duration: .2s; }
  .a-01100:hover .icon svg g, .a-01100:hover .icon svg path, .a-01100:hover .icon svg polygon, .a-01100:hover .icon svg rect {
    fill: #575656 !important; }

.a-01100:focus .text {
  color: #ED0000; }

.a-01100:focus .icon svg g, .a-01100:focus .icon svg path, .a-01100:focus .icon svg polygon, .a-01100:focus .icon svg rect {
  fill: #ED0000 !important; }

.a-01100.disabled .text {
  color: #B1B2B3; }

.a-01100.disabled .icon svg g, .a-01100.disabled .icon svg path, .a-01100.disabled .icon svg polygon, .a-01100.disabled .icon svg rect {
  fill: #B1B2B3 !important; }

@keyframes move {
  0% {
    left: 0; }
  40% {
    left: 5px; }
  100% {
    left: 0; } }

.a-01200 {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  border-bottom: 1px solid #B1B2B3; }
  .a-01200 .elementWrapper {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .a-01200 .icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    text-align: center; }
    .a-01200 .icon svg {
      max-width: 100%;
      max-height: 100%; }
  .a-01200 .text {
    padding: 0 15px;
    text-transform: uppercase;
    text-align: left;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: black; }
    @media (min-width: 992px) {
      .a-01200 .text {
        font-size: 14px; } }
  .a-01200 .arrow {
    display: inline-block;
    align-items: center;
    -webkit-align-items: center;
    width: 9px;
    height: 100%; }
    .a-01200 .arrow svg {
      width: 100%;
      height: 100%; }
  .a-01200:hover .icon svg g, .a-01200:hover .icon svg path, .a-01200:hover .icon svg polygon, .a-01200:hover .icon svg rect {
    fill: #575656 !important; }
  .a-01200:hover .text {
    color: #575656; }
  .a-01200:hover .arrow svg g, .a-01200:hover .arrow svg path, .a-01200:hover .arrow svg polygon, .a-01200:hover .arrow svg rect {
    fill: #575656 !important; }
  .a-01200:focus .icon svg g, .a-01200:focus .icon svg path, .a-01200:focus .icon svg polygon, .a-01200:focus .icon svg rect {
    fill: #ED0000 !important; }
  .a-01200:focus .text {
    color: #ED0000; }
  .a-01200:focus .arrow svg g, .a-01200:focus .arrow svg path, .a-01200:focus .arrow svg polygon, .a-01200:focus .arrow svg rect {
    fill: #ED0000 !important; }
  .a-01200.disabled .icon svg g, .a-01200.disabled .icon svg path, .a-01200.disabled .icon svg polygon, .a-01200.disabled .icon svg rect {
    fill: #B1B2B3 !important; }
  .a-01200.disabled .text {
    color: #B1B2B3; }
  .a-01200.disabled .arrow svg g, .a-01200.disabled .arrow svg path, .a-01200.disabled .arrow svg polygon, .a-01200.disabled .arrow svg rect {
    fill: #B1B2B3 !important; }
  .a-01200--b .icon svg g, .a-01200--b .icon svg path, .a-01200--b .icon svg polygon, .a-01200--b .icon svg rect {
    fill: white !important; }
  .a-01200--b .text {
    color: white; }
  .a-01200--b .arrow svg g, .a-01200--b .arrow svg path, .a-01200--b .arrow svg polygon, .a-01200--b .arrow svg rect {
    fill: white !important; }
  .a-01200--b:hover .icon svg g, .a-01200--b:hover .icon svg path, .a-01200--b:hover .icon svg polygon, .a-01200--b:hover .icon svg rect {
    fill: #B1B2B3 !important; }
  .a-01200--b:hover .text {
    color: #B1B2B3; }
  .a-01200--b:hover .arrow svg g, .a-01200--b:hover .arrow svg path, .a-01200--b:hover .arrow svg polygon, .a-01200--b:hover .arrow svg rect {
    fill: #B1B2B3 !important; }
  .a-01200--b.disabled .icon svg g, .a-01200--b.disabled .icon svg path, .a-01200--b.disabled .icon svg polygon, .a-01200--b.disabled .icon svg rect {
    fill: #575656 !important; }
  .a-01200--b.disabled .text {
    color: #575656; }
  .a-01200--b.disabled .arrow svg g, .a-01200--b.disabled .arrow svg path, .a-01200--b.disabled .arrow svg polygon, .a-01200--b.disabled .arrow svg rect {
    fill: #575656 !important; }
  .a-01200--c, .a-01200--d {
    padding-left: 18px;
    padding-right: 21px;
    height: 73px; }
    .a-01200--c .icon, .a-01200--d .icon {
      width: 31px;
      height: auto; }
      .a-01200--c .icon svg g, .a-01200--c .icon svg path, .a-01200--c .icon svg polygon, .a-01200--c .icon svg rect, .a-01200--d .icon svg g, .a-01200--d .icon svg path, .a-01200--d .icon svg polygon, .a-01200--d .icon svg rect {
        fill: #ED0000 !important; }
    .a-01200--c.disabled .icon svg g, .a-01200--c.disabled .icon svg path, .a-01200--c.disabled .icon svg polygon, .a-01200--c.disabled .icon svg rect, .a-01200--d.disabled .icon svg g, .a-01200--d.disabled .icon svg path, .a-01200--d.disabled .icon svg polygon, .a-01200--d.disabled .icon svg rect {
      fill: #B1B2B3 !important; }
  .a-01200--d {
    cursor: pointer; }
    .a-01200--d .arrow {
      transform: rotate(90deg); }

.a-01300 {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  border-bottom: 1px solid #B1B2B3; }
  .a-01300 .elementWrapper {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .a-01300 .icon {
    display: inline-block;
    width: 24px;
    height: 24px; }
    .a-01300 .icon svg {
      max-width: 100%;
      max-height: 100%; }
  .a-01300 .cta {
    margin-left: 12px;
    text-transform: uppercase;
    text-align: left;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .a-01300 .cta {
        font-size: 14px; } }
  .a-01300:hover .cta {
    color: #B1B2B3; }
  .a-01300:hover .icon svg g, .a-01300:hover .icon svg path, .a-01300:hover .icon svg polygon, .a-01300:hover .icon svg rect {
    fill: #B1B2B3 !important; }
  .a-01300:focus .cta {
    color: #ED0000; }
  .a-01300:focus .icon svg g, .a-01300:focus .icon svg path, .a-01300:focus .icon svg polygon, .a-01300:focus .icon svg rect {
    fill: #ED0000 !important; }
  .a-01300.disabled .cta {
    color: #575656; }
  .a-01300.disabled .icon svg g, .a-01300.disabled .icon svg path, .a-01300.disabled .icon svg polygon, .a-01300.disabled .icon svg rect {
    fill: #575656 !important; }

.a-01400__content-wrapper {
  padding: 0 25px; }

.a-01400__labels, .a-01400 .mat-tab-labels {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: black;
  overflow: hidden; }

.a-01400 .indicator {
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #ED0000;
  transition: left 0.6s cubic-bezier(0.4, 0, 0.8, 1.4); }

.a-01400__section, .a-01400 .mat-tab-label {
  position: relative;
  display: flex;
  color: white !important;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  height: 60px;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px; }
  .a-01400__section:first-child::after, .a-01400 .mat-tab-label:first-child::after {
    right: 0px; }
  .a-01400__section:nth-child(3)::before, .a-01400 .mat-tab-label:nth-child(3)::before {
    left: 0px; }

.a-01400 .mat-tab-list {
  width: 100%; }

.a-01400 .mat-tab-body-content {
  padding-bottom: 50px; }

.a-01400 .mat-tab-label {
  opacity: 1;
  white-space: normal !important;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif; }
  .a-01400 .mat-tab-label:first-child::after {
    display: none; }
  .a-01400 .mat-tab-label:nth-child(3)::before {
    display: none; }

.a-01400 .mat-tab-label-content {
  color: white;
  white-space: normal !important; }

.a-01400 .mat-ink-bar {
  background-color: #ED0000 !important;
  height: 6px; }

.tabSeparator, .a-01400__section:first-child::after, .a-01400 .mat-tab-label:first-child::after, .a-01400__section:nth-child(3)::before, .a-01400 .mat-tab-label:nth-child(3)::before {
  content: '';
  display: block;
  height: 28px;
  width: 1px;
  background-color: #B1B2B3;
  position: absolute;
  top: 19px; }

.a-01500 {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .a-01500 {
      font-size: 14px; } }
  .a-01500 .content-collapsed {
    display: none; }
  .a-01500 .content-shown {
    display: block; }
  .a-01500.collapsed .content-collapsed {
    display: block; }
  .a-01500.collapsed .content-shown {
    display: none; }
  .a-01500__collapse p {
    padding-top: 5px; }
  .a-01500__collapse--b p {
    color: white; }
  .a-01500 .text {
    text-transform: uppercase;
    text-align: left;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: black; }
    @media (min-width: 992px) {
      .a-01500 .text {
        font-size: 14px; } }
  .a-01500 .arrow {
    display: flex;
    align-items: center;
    color: black; }
    .a-01500 .arrow svg {
      width: 16px;
      height: 9px; }
  .a-01500:hover .text, .a-01500:hover .arrow {
    color: #575656; }
  .a-01500:hover .icon {
    background: #575656; }
  .a-01500:focus .text, .a-01500:focus .arrow {
    color: black; }
  .a-01500:focus .icon {
    background: black; }
  .a-01500.disabled .text, .a-01500.disabled .arrow {
    color: #B1B2B3; }
  .a-01500.disabled .icon {
    background: #B1B2B3; }
  .a-01500[aria-expanded="true"] .arrow {
    transform: rotate(180deg); }
  .a-01500--b {
    border-bottom: 1px solid black; }
    .a-01500--b .text {
      color: white; }
    .a-01500--b .arrow {
      color: white; }
    .a-01500--b:hover .text, .a-01500--b:hover .arrow {
      color: #B1B2B3; }
    .a-01500--b:hover .icon {
      background: #B1B2B3; }
    .a-01500--b:focus .text, .a-01500--b:focus .arrow {
      color: white; }
    .a-01500--b:focus .icon {
      background: white; }
    .a-01500--b.disabled .text, .a-01500--b.disabled .arrow {
      color: #B1B2B3; }
    .a-01500--b.disabled .icon {
      background: #B1B2B3; }

.a-01600 {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center; }
  .a-01600 .elementWrapper {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .a-01600 .icon {
    width: 24px;
    height: 24px; }
    .a-01600 .icon svg {
      width: 100%;
      height: 100%;
      display: block; }
  .a-01600 .text {
    margin-left: 12px;
    color: black;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .a-01600 .text {
        font-size: 14px; } }
  .a-01600 .arrow {
    display: flex;
    align-items: center;
    color: black;
    width: 16px;
    height: 100%; }
    .a-01600 .arrow svg {
      width: 100%;
      height: 9px; }
  .a-01600[aria-expanded="true"] .arrow svg {
    transform: rotate(180deg); }
  .a-01600:hover .text, .a-01600:hover .arrow {
    color: #575656; }
  .a-01600:focus .text, .a-01600:focus .arrow {
    color: black; }
  .a-01600.disabled .text, .a-01600.disabled .arrow {
    color: #B1B2B3; }

px-a01600 {
  border-bottom: 1px solid #B1B2B3;
  display: block; }

.a-01700 {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 85px; }
  .a-01700 .icon {
    width: 40px;
    height: 32px;
    background-color: #ED0000;
    display: block; }
  .a-01700 .text {
    color: black;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 15px;
    text-align: center; }
  .a-01700:hover, .a-01700:focus {
    outline: 0; }
  .a-01700:hover .icon {
    background-color: #575656; }
  .a-01700:hover .text {
    color: #575656; }
  .a-01700:focus .icon {
    background-color: #ED0000; }
  .a-01700:focus .text {
    color: black; }
  .a-01700.disabled {
    cursor: default; }
    .a-01700.disabled .icon {
      background-color: #B1B2B3; }
    .a-01700.disabled .text {
      color: #B1B2B3; }

.a-01800 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 55px;
  padding-left: 25px;
  padding-right: 25px; }
  .a-01800 .text {
    color: #575656;
    font-size: 14px;
    line-height: 18px;
    position: relative;
    font-weight: 400;
    height: 100%;
    width: 100%;
    border-bottom: 1px solid #B1B2B3;
    display: flex;
    align-items: center; }
  .a-01800:focus {
    background-color: #575656; }
    .a-01800:focus .text {
      color: white;
      border-bottom: 1px solid #575656; }

.a-01900, px-a01900 {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 85px;
  border: 0;
  background-color: transparent;
  cursor: pointer; }
  .a-01900 .icon, px-a01900 .icon {
    width: 40px;
    height: 32px;
    display: block;
    margin: 0 auto; }
    .a-01900 .icon svg, px-a01900 .icon svg {
      width: 100%;
      height: 100%; }
      .a-01900 .icon svg g, px-a01900 .icon svg g, .a-01900 .icon svg path, px-a01900 .icon svg path, .a-01900 .icon svg polygon, px-a01900 .icon svg polygon, .a-01900 .icon svg rect, px-a01900 .icon svg rect {
        fill: black !important; }
      .a-01900 .icon svg.svg-icon-gebrauchtwagen, px-a01900 .icon svg.svg-icon-gebrauchtwagen {
        width: 28px; }
  .a-01900 .textIcon, px-a01900 .textIcon {
    width: 10px;
    display: none; }
    .a-01900 .textIcon svg, px-a01900 .textIcon svg {
      width: 100%;
      height: 100%; }
  .a-01900 .text, px-a01900 .text {
    color: black;
    margin-top: 7px;
    text-align: center;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .a-01900 .text, px-a01900 .text {
        font-size: 14px; } }
  .a-01900:hover, px-a01900:hover, .a-01900:focus, px-a01900:focus {
    outline: 0; }
  .a-01900:hover .icon svg, px-a01900:hover .icon svg {
    fill: #575656; }
    @media (min-width: 992px) {
      .a-01900:hover .icon svg g, px-a01900:hover .icon svg g, .a-01900:hover .icon svg path, px-a01900:hover .icon svg path, .a-01900:hover .icon svg polygon, px-a01900:hover .icon svg polygon, .a-01900:hover .icon svg rect, px-a01900:hover .icon svg rect {
        fill: #575656 !important; } }
  @media (min-width: 992px) {
    .a-01900:hover .text, px-a01900:hover .text {
      color: #575656; } }
  .a-01900.active .icon svg g, px-a01900.active .icon svg g, .a-01900.active .icon svg path, px-a01900.active .icon svg path, .a-01900.active .icon svg polygon, px-a01900.active .icon svg polygon, .a-01900.active .icon svg rect, px-a01900.active .icon svg rect {
    fill: #ED0000 !important; }
  .a-01900.active .text, px-a01900.active .text {
    color: #ED0000; }
    .a-01900.active .text .textIcon, px-a01900.active .text .textIcon {
      display: inline-block !important; }
  .a-01900.disabled, px-a01900.disabled {
    cursor: default; }
    .a-01900.disabled .icon g, px-a01900.disabled .icon g, .a-01900.disabled .icon path, px-a01900.disabled .icon path, .a-01900.disabled .icon polygon, px-a01900.disabled .icon polygon, .a-01900.disabled .icon rect, px-a01900.disabled .icon rect {
      fill: #B1B2B3 !important; }
    .a-01900.disabled .text, px-a01900.disabled .text {
      color: #B1B2B3; }

px-a01900 .a-01900, px-a01900 px-a01900 {
  display: block;
  max-width: 100%;
  border: 0;
  background-color: transparent; }

.a-02000 {
  width: 100%; }
  @media (min-width: 992px) {
    .a-02000 {
      max-width: 360px; } }
  .a-02000 .label {
    color: black;
    font-size: 10px;
    font-weight: bold; }
  .a-02000 .linkWrapper {
    margin-top: 5px;
    border: 1px solid rgba(87, 86, 86, 0.3);
    padding: 4px;
    display: flex;
    flex-direction: row; }
    .a-02000 .linkWrapper a, .a-02000 .linkWrapper .input-label {
      height: 36px;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black; }
      .a-02000 .linkWrapper a:active, .a-02000 .linkWrapper a.active, .a-02000 .linkWrapper .input-label:active, .a-02000 .linkWrapper .input-label.active {
        background-color: #575656;
        color: white; }
    .a-02000 .linkWrapper .link {
      height: 36px;
      width: 50%; }
    .a-02000 .linkWrapper .input-label {
      width: 100%;
      height: 100%; }
    .a-02000 .linkWrapper input[type="radio"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 36px;
      width: 100%;
      max-width: 145px; }
      @media screen and (min-width: 350px) {
        .a-02000 .linkWrapper input[type="radio"] {
          max-width: 175px; } }
      .a-02000 .linkWrapper input[type="radio"]:checked + .input-label {
        background-color: black;
        color: white; }

.a-02100 {
  position: relative; }
  .a-02100 label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 14px 15px;
    margin-bottom: 0;
    height: 46px;
    font-weight: 400;
    color: #575656;
    font-size: 14px;
    line-height: 20px;
    transition: font-size 0.2s, height 0.2s, top 0.2s, bottom 0.2s, right 0.2s, left 0.2s; }
  .a-02100 input {
    width: 100%;
    height: 46px;
    background: transparent;
    position: relative;
    z-index: 1;
    padding: 5px 15px;
    outline: 0;
    border: 1px solid rgba(87, 86, 86, 0.3);
    border-radius: 0;
    box-shadow: 0;
    color: #ED0000;
    text-shadow: 0px 0px 0px #575656;
    -webkit-text-fill-color: transparent; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .a-02100 input {
        color: black; } }
    .a-02100 input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px white inset;
      /* Change the color to your own background color */
      -webkit-text-fill-color: #575656; }
      .a-02100 input:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 50px white inset;
        /*your box-shadow*/
        -webkit-text-fill-color: #575656; }
        .a-02100 input:-webkit-autofill:focus ~ label {
          z-index: 1; }
    .a-02100 input.isValid {
      border: 1px solid rgba(87, 86, 86, 0.3);
      padding-top: 19px; }
      .a-02100 input.isValid ~ label {
        color: black;
        z-index: 1; }
    .a-02100 input:focus {
      border: 1px solid black;
      padding-top: 19px;
      background-position: 0 0; }
      .a-02100 input:focus ~ label {
        color: black; }
    .a-02100 input.hasError {
      border: 1px solid #ED0000;
      padding-top: 19px; }
      .a-02100 input.hasError ~ label {
        color: #ED0000; }
  .a-02100--filter {
    width: 100%; }
    .a-02100--filter input:focus {
      padding-top: 5px; }
    .a-02100--filter input::-webkit-inner-spin-button, .a-02100--filter input ::-webkit-outer-spin-button {
      -webkit-appearance: none; }
    .a-02100--filter .measure {
      font-size: 14px;
      position: absolute;
      right: 13px;
      top: 13px;
      color: #B1B2B3;
      z-index: 1; }
  .a-02100--indicator input {
    padding: 5px 40px; }
  .a-02100--indicator .indicator {
    position: absolute;
    top: 13px;
    left: 15px;
    font-size: 14px; }
  .a-02100 ngb-datepicker .ngb-dp-month .bg-light {
    background-color: #FFFFFF; }
  .a-02100 ngb-datepicker .ngb-dp-month .ngb-dp-weekday {
    color: #333;
    font-style: normal;
    font-weight: 100 !important; }
  .a-02100 ngb-datepicker .ngb-dp-month .ngb-dp-week {
    border-bottom: none; }
    .a-02100 ngb-datepicker .ngb-dp-month .ngb-dp-week .ngb-dp-today {
      border-color: #ed0000 !important; }
    .a-02100 ngb-datepicker .ngb-dp-month .ngb-dp-week .ngb-dp-day {
      outline: none !important; }
      .a-02100 ngb-datepicker .ngb-dp-month .ngb-dp-week .ngb-dp-day .bg-primary {
        background-color: #ed0000 !important;
        border-color: #ed0000 !important;
        font-weight: 600; }
      .a-02100 ngb-datepicker .ngb-dp-month .ngb-dp-week .ngb-dp-day .btn-light .ng-star-inserted:focus {
        outline: #000000; }
      .a-02100 ngb-datepicker .ngb-dp-month .ngb-dp-week .ngb-dp-day [ngbDatepickerDayView] {
        border-radius: 0rem;
        color: #575656; }
        .a-02100 ngb-datepicker .ngb-dp-month .ngb-dp-week .ngb-dp-day [ngbDatepickerDayView]:hover {
          color: #FFFFFF;
          background-color: #ed0000;
          border-color: #ed0000; }
  .a-02100 .ngb-dp-header .ngb-dp-arrow .ngb-dp-arrow-btn {
    color: #b1b2b3; }
    .a-02100 .ngb-dp-header .ngb-dp-arrow .ngb-dp-arrow-btn span {
      border-width: 2px 2px 0 0; }
  .a-02100 .input-group-calendar {
    display: flex;
    flex-wrap: nowrap; }
    .a-02100 .input-group-calendar input.ng-valid {
      border: 1px solid rgba(87, 86, 86, 0.3);
      padding-top: 19px; }
      .a-02100 .input-group-calendar input.ng-valid ~ label {
        color: black; }
    .a-02100 .input-group-calendar .input-field {
      flex-basis: 85%; }
    .a-02100 .input-group-calendar .input-icon {
      flex-basis: 15%; }
    .a-02100 .input-group-calendar .input-group-append {
      cursor: pointer;
      border: 1px solid rgba(87, 86, 86, 0.3);
      border-left: 0;
      height: 46px; }
      .a-02100 .input-group-calendar .input-group-append button {
        background-color: white;
        display: block;
        border-radius: 0;
        border: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .a-02100 .input-group-calendar .input-group-append button span {
          background-image: url("../Icons/relaunch/ic_calendar_datepicker_red.svg");
          background-size: contain;
          background-repeat: no-repeat;
          display: inline-block;
          height: 28px;
          width: 28px;
          position: relative;
          vertical-align: middle; }

.mat-form-field {
  width: 100%; }

.mat-option .mat-active {
  color: #ed0000 !important; }

.mat-focused .mat-form-field-ripple {
  background-color: #575656 !important; }

.labelActive, .a-02100 input.isValid ~ label, .a-02100 input:focus ~ label, .a-02100 input.hasError ~ label, .a-02100 .input-group-calendar input.ng-valid ~ label, .a-02200 textarea.isValid + label, .a-02200 textarea:focus + label, .a-02200 textarea.hasError + label, .a-02200--opv-rating textarea.ng-dirty + label {
  height: auto;
  padding: 5px 15px 0;
  font-size: 10px;
  line-height: 16px;
  font-weight: bold; }

.a-02200 {
  position: relative; }
  .a-02200 label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 14px 15px;
    height: 46px;
    font-weight: 300;
    color: #575656;
    font-size: 14px;
    line-height: 20px;
    transition: font-size 0.2s, height 0.2s, top 0.2s, bottom 0.2s, right 0.2s, left 0.2s; }
  .a-02200 textarea {
    width: 100%;
    max-height: 450px;
    background: transparent;
    position: relative;
    z-index: 1;
    padding: 8px 15px;
    outline: 0;
    border: 1px solid rgba(87, 86, 86, 0.3);
    box-shadow: 0;
    color: #ED0000;
    text-shadow: 0px 0px 0px #575656;
    -webkit-text-fill-color: transparent;
    min-height: 185px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .a-02200 textarea {
        color: black; } }
    .a-02200 textarea.isValid {
      border: 1px solid rgba(87, 86, 86, 0.3);
      padding-top: 19px; }
      .a-02200 textarea.isValid + label {
        color: black; }
    .a-02200 textarea:focus {
      border: 1px solid black;
      padding-top: 19px; }
      .a-02200 textarea:focus + label {
        color: black; }
    .a-02200 textarea.hasError {
      border: 1px solid #ED0000;
      padding-top: 19px; }
      .a-02200 textarea.hasError + label {
        color: #ED0000; }
  .a-02200--opv-rating textarea.ng-dirty {
    border: 1px solid black;
    padding-top: 19px; }
    .a-02200--opv-rating textarea.ng-dirty + label {
      color: black; }

.labelActive, .a-02100 input.isValid ~ label, .a-02100 input:focus ~ label, .a-02100 input.hasError ~ label, .a-02100 .input-group-calendar input.ng-valid ~ label, .a-02200 textarea.isValid + label, .a-02200 textarea:focus + label, .a-02200 textarea.hasError + label, .a-02200--opv-rating textarea.ng-dirty + label {
  height: auto;
  padding: 5px 15px 0;
  font-size: 10px;
  line-height: 16px;
  font-weight: bold; }

.prettydropdown {
  position: relative;
  min-width: 72px;
  /* 70px + borders */
  display: inline-block;
  width: 100% !important; }
  .prettydropdown .checked {
    display: none; }

.prettydropdown.loading {
  min-width: 0; }

.prettydropdown > ul {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  border: 1px solid #B1B2B3;
  box-sizing: content-box;
  color: #575656;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Likely future */
  z-index: 1;
  width: 100%;
  max-width: 100%; }
  .prettydropdown > ul:focus {
    outline: 0 !important; }

.prettydropdown.loading > ul {
  visibility: hidden;
  white-space: nowrap; }

.prettydropdown > ul:focus,
.prettydropdown:not(.disabled) > ul:hover {
  border-color: #575656; }

.prettydropdown:not(.disabled) > ul.active {
  width: auto;
  max-height: none !important;
  border-color: #575656;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 99; }

.prettydropdown > ul.active:focus {
  outline: none; }

.prettydropdown > ul.active.reverse {
  top: auto;
  bottom: 0; }

.prettydropdown > ul > li {
  position: relative;
  min-width: 70px;
  height: 44px;
  /* 46px - borders */
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  box-sizing: border-box;
  display: none;
  line-height: 46px;
  /* 48px - borders */
  margin: 0;
  padding-left: 15px;
  width: 100vw !important;
  max-width: 100% !important; }
  .prettydropdown > ul > li:focus {
    outline: 0 !important; }

.prettydropdown.loading > ul > li {
  min-width: 0;
  display: block;
  padding-right: 15px; }

.prettydropdown > ul:not(.active) > li:not(.selected):first-child {
  visibility: hidden;
  /* Prevent FOUC */ }

.prettydropdown > ul > li:first-child,
.prettydropdown > ul.active > li {
  display: block; }

.prettydropdown > ul.active > li:not(.label):hover,
.prettydropdown > ul.active > li.hover:not(.label),
.prettydropdown > ul.active > li:first-child:hover:after {
  background: #575656;
  color: #fff; }

.prettydropdown > ul.active > li.nohover {
  background: inherit !important;
  color: inherit !important; }

.prettydropdown > ul.active > li.hover:before,
.prettydropdown > ul.active > li.nohover:after {
  border-top-color: #fff !important; }

.prettydropdown > ul.active > li.hover:after,
.prettydropdown > ul.active > li.nohover:before {
  border-top-color: #575656 !important; }

.prettydropdown.arrow > ul > li.selected:before,
.prettydropdown.arrow > ul > li.selected:after {
  position: absolute;
  top: 8px;
  bottom: 0;
  right: 8px;
  height: 16px;
  box-sizing: border-box;
  content: "";
  display: block;
  margin: auto; }

.prettydropdown.arrow > ul.active > li.selected:before {
  transform: rotate(180deg); }

.prettydropdown.arrow > ul > li.selected.hover:before {
  border-top-color: #575656 !important;
  z-index: 1; }

.prettydropdown.arrow.small > ul > li.selected:before,
.prettydropdown.arrow.small > ul > li.selected:after {
  top: 4px;
  height: 8px;
  border-width: 4px; }

.prettydropdown.arrow > ul > li.selected:before {
  border-top-color: #B1B2B3;
  /* Arrow color */ }

.prettydropdown.arrow > ul > li.selected:after {
  top: 4px;
  /* Chevron thickness */
  border-top-color: #fff;
  /* Match background colour */ }

.prettydropdown.arrow.small > ul > li.selected:after {
  top: 2px;
  /* Chevron thickness */ }

.prettydropdown.arrow.triangle > ul > li.selected:after {
  content: none; }

.prettydropdown > ul:hover > li.selected:before {
  border-top-color: #575656; }

.prettydropdown > ul:not(.active) > li > span.checked {
  display: none; }

/* Multi-Select */
.prettydropdown.multiple > ul > li.selected {
  overflow: hidden;
  padding-right: 2rem;
  text-overflow: ellipsis;
  white-space: nowrap; }

.prettydropdown > ul > li > span.checked {
  clear: both;
  float: right;
  font-weight: bold;
  margin-right: 0.8rem; }

/* Option Groups */
.prettydropdown > ul > li.label {
  cursor: default;
  font-weight: bold; }

.prettydropdown > ul > li.label:first-child,
.prettydropdown.classic > ul > li.label ~ li.selected {
  border-top: none; }

.prettydropdown > ul > li.label ~ li:not(.label):not(.selected),
.prettydropdown.classic > ul.active > li.label ~ li:not(.label) {
  padding-left: 1.6rem; }

/* Classic Behavior */
.prettydropdown.classic > ul:not(.active) > li.selected:not(:first-child) {
  position: absolute;
  top: 0;
  display: block; }

/* Disabled */
.prettydropdown.disabled,
.prettydropdown > ul > li.disabled {
  opacity: 0.3; }

.prettydropdown.disabled > ul > li,
.prettydropdown > ul > li.disabled {
  cursor: not-allowed; }

/* Divider Lines */
.a-02400 {
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 26px;
  margin-bottom: 0; }
  .a-02400 .labelText {
    font-size: 12px;
    padding-left: 44px;
    font-weight: bold;
    text-transform: uppercase; }
  .a-02400 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid #B1B2B3; }

.checkmark--red {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid #B1B2B3; }

/* When the checkbox is checked, add a grey background */
.a-02400 input:checked ~ .checkmark {
  background-color: #575656;
  border: 1px solid #575656; }

.a-02400 input:checked ~ .checkmark--red {
  background-color: #ED0000;
  border: 1px solid #ED0000; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

.checkmark--red:after {
  content: "";
  position: absolute;
  display: none; }

.a-02400 input:checked ~ .checkmark:after {
  display: block; }

.a-02400 input:checked ~ .checkmark--red:after {
  display: block; }

.a-02400 .checkmark:after {
  left: 9px;
  top: 4px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.a-02400 .checkmark--red:after {
  left: 9px;
  top: 4px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.a-02400.hasError .labelText {
  color: #ED0000; }

.a-02400.hasError .checkmark {
  border: 1px solid #ED0000 !important; }

.a-02500 {
  display: flex;
  justify-content: center;
  height: 42px;
  border: 1px solid #cccccc;
  background: white; }
  .a-02500 .budgetModifier {
    width: 43px;
    color: #ED0000;
    font-size: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    box-shadow: none;
    border: none;
    cursor: pointer; }
    .a-02500 .budgetModifier--minus {
      border-right: 1px solid #cccccc; }
    .a-02500 .budgetModifier--plus {
      border-left: 1px solid #cccccc; }
    .a-02500 .budgetModifier--deactivated {
      color: #cccccc; }
    .a-02500 .budgetModifier:focus, .a-02500 .budgetModifier:hover {
      outline: 0; }
    .a-02500 .budgetModifier.triggerAnimation {
      animation: triggerAnimation .2s ease-in-out; }
  .a-02500 .textWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 86px);
    padding-top: 8px; }
    .a-02500 .textWrapper .price {
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: black;
      margin-top: 2px; }
      @media (min-width: 992px) {
        .a-02500 .textWrapper .price {
          font-size: 28px;
          line-height: 38px; } }
      @media (min-width: 992px) {
        .a-02500 .textWrapper .price {
          font-size: 18px;
          line-height: 22px; } }
  .a-02500 .description {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    color: #575656;
    line-height: 7px;
    color: #575656; }
    @media (min-width: 992px) {
      .a-02500 .description {
        font-size: 12px; } }

@keyframes triggerAnimation {
  0% {
    background-color: white; }
  25% {
    background-color: #cccccc; }
  100% {
    background-color: white; } }

.a-02600 {
  display: flex;
  flex-direction: row; }
  .a-02600 .star ~ .star {
    margin-left: 6px; }
  .a-02600 .star svg.active stop {
    stop-color: #ED0000 !important; }
  .a-02600 .star svg.active--0 stop {
    stop-color: #ED0000 !important; }
  .a-02600 .star svg.active--10 stop:nth-child(1), .a-02600 .star svg.active--10 stop:nth-child(2) {
    stop-color: #ED0000 !important; }
  .a-02600 .star svg.active--20 stop:nth-child(1), .a-02600 .star svg.active--20 stop:nth-child(2), .a-02600 .star svg.active--20 stop:nth-child(3) {
    stop-color: #ED0000 !important; }
  .a-02600 .star svg.active--30 stop:nth-child(1), .a-02600 .star svg.active--30 stop:nth-child(2), .a-02600 .star svg.active--30 stop:nth-child(3), .a-02600 .star svg.active--30 stop:nth-child(4) {
    stop-color: #ED0000 !important; }
  .a-02600 .star svg.active--40 stop:nth-child(1), .a-02600 .star svg.active--40 stop:nth-child(2), .a-02600 .star svg.active--40 stop:nth-child(3), .a-02600 .star svg.active--40 stop:nth-child(4), .a-02600 .star svg.active--40 stop:nth-child(5) {
    stop-color: #ED0000 !important; }
  .a-02600 .star svg.active--50 stop:nth-child(1), .a-02600 .star svg.active--50 stop:nth-child(2), .a-02600 .star svg.active--50 stop:nth-child(3), .a-02600 .star svg.active--50 stop:nth-child(4), .a-02600 .star svg.active--50 stop:nth-child(5), .a-02600 .star svg.active--50 stop:nth-child(6) {
    stop-color: #ED0000 !important; }
  .a-02600 .star svg.active--60 stop:nth-child(1), .a-02600 .star svg.active--60 stop:nth-child(2), .a-02600 .star svg.active--60 stop:nth-child(3), .a-02600 .star svg.active--60 stop:nth-child(4), .a-02600 .star svg.active--60 stop:nth-child(5), .a-02600 .star svg.active--60 stop:nth-child(6), .a-02600 .star svg.active--60 stop:nth-child(7) {
    stop-color: #ED0000 !important; }
  .a-02600 .star svg.active--70 stop:nth-child(1), .a-02600 .star svg.active--70 stop:nth-child(2), .a-02600 .star svg.active--70 stop:nth-child(3), .a-02600 .star svg.active--70 stop:nth-child(4), .a-02600 .star svg.active--70 stop:nth-child(5), .a-02600 .star svg.active--70 stop:nth-child(6), .a-02600 .star svg.active--70 stop:nth-child(7), .a-02600 .star svg.active--70 stop:nth-child(8) {
    stop-color: #ED0000 !important; }
  .a-02600 .star svg.active--80 stop:nth-child(1), .a-02600 .star svg.active--80 stop:nth-child(2), .a-02600 .star svg.active--80 stop:nth-child(3), .a-02600 .star svg.active--80 stop:nth-child(4), .a-02600 .star svg.active--80 stop:nth-child(5), .a-02600 .star svg.active--80 stop:nth-child(6), .a-02600 .star svg.active--80 stop:nth-child(7), .a-02600 .star svg.active--80 stop:nth-child(8), .a-02600 .star svg.active--80 stop:nth-child(9) {
    stop-color: #ED0000 !important; }
  .a-02600 .star svg.active--90 stop:nth-child(1), .a-02600 .star svg.active--90 stop:nth-child(2), .a-02600 .star svg.active--90 stop:nth-child(3), .a-02600 .star svg.active--90 stop:nth-child(4), .a-02600 .star svg.active--90 stop:nth-child(5), .a-02600 .star svg.active--90 stop:nth-child(6), .a-02600 .star svg.active--90 stop:nth-child(7), .a-02600 .star svg.active--90 stop:nth-child(8), .a-02600 .star svg.active--90 stop:nth-child(9), .a-02600 .star svg.active--90 stop:nth-child(10) {
    stop-color: #ED0000 !important; }
  .a-02600--b svg {
    width: 18px; }
  .a-02600--c svg {
    width: 15px; }

.a-02700 {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }
  .a-02700__icon svg {
    width: 13px;
    height: 13px;
    position: relative;
    top: -1px; }
  .a-02700__text {
    margin-left: 5px;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto;
    color: #575656;
    text-align: left; }
    @media (min-width: 992px) {
      .a-02700__text {
        font-size: 16px; } }

px-a02700 {
  display: inline-block; }

.a-02800 {
  position: relative;
  margin-top: 2px;
  margin-left: 2px;
  left: -2px; }
  @media (min-width: 992px) {
    .a-02800 {
      margin-top: 11px; } }
  .a-02800 span {
    padding: 3px 7px;
    background-color: #E4E4E5;
    white-space: none;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #575656; }
    @media (min-width: 992px) {
      .a-02800 span {
        font-size: 14px; } }
    @media (min-width: 992px) {
      .a-02800 span {
        padding: 5px 11px; } }

.a-02900 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .a-02900 span {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid transparent;
    display: block;
    margin-left: 7px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    transition: all .3s ease-in-out; }
    .a-02900 span.active, .a-02900 span.slick-active {
      background-color: #ED0000;
      border: 2px solid #ED0000; }

ul.slick-dots li {
  width: 6px;
  height: 6px;
  margin-left: 7px;
  transition: all .3s ease-in-out; }
  ul.slick-dots li button {
    width: 6px;
    height: 6px;
    padding: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    background-color: white;
    border: 2px solid transparent; }
    ul.slick-dots li button:before {
      content: ''; }
  ul.slick-dots li.slick-active button {
    background-color: #ED0000;
    border: 2px solid #ED0000; }

.a-03000 {
  border-top: 2px solid #B1B2B3;
  margin: auto; }

.a-03100 {
  display: flex;
  align-items: center;
  background-color: #E3E5E6;
  color: black;
  height: 30px;
  width: 100%; }
  .a-03100 span {
    font-size: 10px;
    font-weight: bold;
    margin-left: 12px; }

.a-03200 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  width: 32px;
  height: 32px;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
  /* only for preview */ }
  .a-03200 span {
    color: white;
    top: -2px;
    position: relative; }

.a-03300 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center; }
  .a-03300 .icon {
    width: 28px;
    height: 18px;
    display: block;
    text-align: center;
    flex-shrink: 0; }
    .a-03300 .icon svg {
      vertical-align: top;
      max-width: 100%;
      max-height: 100%; }
    .a-03300 .icon__icon_art_gebrauchtwagen {
      height: 15px; }
    .a-03300 .icon__icon_art_neuwagen {
      height: 19px; }
    .a-03300 .icon__icon_zapfsaeule {
      height: 17px; }
    .a-03300 .icon__icon_getriebe {
      height: 15px; }
    .a-03300 .icon__icon_dashboard_kilometer {
      height: 16px; }
    .a-03300 .icon__icon_calendar_datepicker_erstz {
      height: 17px; }
    .a-03300 .icon__icon_engine_leistung {
      height: 15px; }
    .a-03300 .icon__icon-mesh-seat {
      height: 23px; }
    .a-03300 .icon__icon-dashboard-speed {
      height: 13px; }
    .a-03300 .icon__pw_icn_hubraum {
      height: 22px; }
    .a-03300 .icon__ic_marke {
      height: 15px; }
  .a-03300__wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 7px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .a-03300__wrapper {
        width: 100%; } }
    .a-03300__wrapper .type {
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #575656;
      color: black; }
      @media (min-width: 992px) {
        .a-03300__wrapper .type {
          font-size: 14px; } }
    .a-03300__wrapper .description {
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: black;
      word-wrap: break-word;
      hyphens: auto;
      color: #575656; }
      @media (min-width: 992px) {
        .a-03300__wrapper .description {
          font-size: 16px; } }

.a-03350 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center; }
  @media (max-width: 992px) {
    .a-03350 {
      margin-bottom: 10px; } }
  .a-03350__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .a-03350__wrapper .headline {
      font-family: "mmc-bold", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px; }
      @media (min-width: 992px) {
        .a-03350__wrapper .headline {
          font-size: 16px; } }
    .a-03350__wrapper .description {
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: black;
      word-wrap: break-word;
      hyphens: auto;
      color: #575656; }
      @media (min-width: 992px) {
        .a-03350__wrapper .description {
          font-size: 16px; } }

.a-03400 {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  border-bottom: 1px solid #B1B2B3; }
  .a-03400.empty {
    pointer-events: none;
    height: 25px; }
  .a-03400__collapse {
    border-bottom: 1px solid #B1B2B3;
    padding-bottom: 15px; }
    .a-03400__collapse .subheadline {
      line-height: 18px;
      font-size: 14px;
      font-weight: 700;
      margin-top: 15px;
      margin-bottom: 5px; }
    .a-03400__collapse .openingTime {
      margin: 0; }
    .a-03400__collapse .timeCol .openingTime {
      font-size: 12px;
      line-height: 22px; }
  .a-03400 .elementWrapper {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .a-03400 .text {
    font-size: 10px;
    color: #575656;
    font-weight: 400; }
    .a-03400 .text br {
      display: none; }
    .a-03400 .text strong {
      font-size: 14px;
      line-height: 16px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: left;
      margin-right: 7px; }
      .a-03400 .text strong.open {
        color: #88B139; }
      .a-03400 .text strong.closing {
        color: #F7A800; }
      .a-03400 .text strong.closed {
        color: #ED0000; }
  .a-03400 .arrow {
    display: flex;
    align-items: center;
    color: black;
    transform: rotate(90deg);
    transition: transform .2s ease-in-out; }
  .a-03400:hover .text {
    color: #575656; }
  .a-03400[aria-expanded='true'] .arrow {
    transform: rotate(270deg); }

.a-03500 {
  display: inline-flex;
  background-color: #ED0000;
  color: white;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold; }

.a-03600 {
  flex-grow: 1;
  flex-basis: 100%;
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  position: relative;
  padding-top: 9px;
  padding-bottom: 4px; }
  @media (min-width: 768px) {
    .a-03600 {
      height: 59px;
      padding-top: 13px;
      padding-bottom: 9px; } }
  @media (min-width: 992px) {
    .a-03600 {
      flex: 0 0 auto;
      background: transparent;
      flex-direction: row;
      height: 60px;
      padding: 0 40px;
      justify-content: center;
      align-items: center; } }
  .a-03600::after {
    content: '';
    width: 1px;
    height: 34px;
    display: block;
    background-color: #575656;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -17px;
    transition: background-color .3s ease-in; }
    @media (min-width: 992px) {
      .a-03600::after {
        background-color: black;
        width: 2px;
        height: 20px;
        margin-top: -10px; } }
  .a-03600:last-child::after {
    display: none; }
  .a-03600:focus {
    outline: none; }
  .a-03600.hideAfter::after {
    display: none; }
  .a-03600 button {
    border: 0;
    padding: 0;
    background: 0;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer; }
    @media (min-width: 992px) {
      .a-03600 button {
        flex-direction: row;
        justify-content: center;
        align-items: center; } }
    .a-03600 button:focus {
      outline: none; }
  .a-03600 .text {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    color: #575656;
    color: black;
    text-transform: uppercase;
    text-align: center; }
    @media (min-width: 992px) {
      .a-03600 .text {
        font-size: 12px; } }
    @media (min-width: 992px) {
      .a-03600 .text {
        margin-left: 10px;
        white-space: nowrap;
        color: white; } }
    .a-03600 .text--active {
      display: none;
      color: white; }
  .a-03600 .icon {
    width: 24px;
    height: 21px;
    margin: 0 auto; }
    @media (min-width: 992px) {
      .a-03600 .icon {
        width: 20px;
        height: 18px;
        line-height: 18px;
        margin-right: 10px; } }
    .a-03600 .icon svg {
      width: 100%;
      height: 100%; }
      @media (min-width: 992px) {
        .a-03600 .icon svg g, .a-03600 .icon svg path, .a-03600 .icon svg polygon, .a-03600 .icon svg rect {
          fill: white !important; } }
    .a-03600 .icon--active {
      display: none; }
      .a-03600 .icon--active svg g, .a-03600 .icon--active svg path, .a-03600 .icon--active svg polygon, .a-03600 .icon--active svg rect {
        fill: white !important; }
      .a-03600 .icon--active svg polyline {
        fill: white !important; }
  .a-03600.active {
    background-color: #575656; }
    .a-03600.active .text {
      display: none; }
      .a-03600.active .text--active {
        display: inline; }
    .a-03600.active .icon {
      display: none; }
      .a-03600.active .icon--active {
        display: block; }
    .a-03600.active .placeholderIcon {
      background-color: white; }
  .a-03600.isDisabled {
    pointer-events: none; }

.a-03800 {
  background: black;
  color: white;
  padding: 10px;
  display: block;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 20px; }
  .a-03800__text {
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-size: 12px;
    color: white;
    font-weight: normal;
    line-height: 16px; }
  .a-03800 div {
    min-width: 15px; }
    .a-03800 div.prev {
      border-right: 1px solid #575656;
      padding-right: 10px;
      height: 20px;
      align-items: center; }
      .a-03800 div.prev a {
        display: flex;
        height: 20px;
        align-items: center; }
    .a-03800 div.wrap {
      display: flex;
      align-items: center; }
      .a-03800 div.wrap .text-right {
        margin-right: 15px; }
  .a-03800 a {
    cursor: pointer; }
  .a-03800 .row {
    align-items: center;
    justify-content: space-between;
    padding: 0 15px; }
  .a-03800 .disabled {
    color: #B1B2B3;
    cursor: default; }
    .a-03800 .disabled svg g, .a-03800 .disabled svg path, .a-03800 .disabled svg polygon, .a-03800 .disabled svg rect {
      fill: #B1B2B3 !important; }
    .a-03800 .disabled a {
      display: block;
      cursor: pointer; }
    .a-03800 .disabled div {
      min-width: 15px; }
      .a-03800 .disabled div.prev {
        border-right: 1px solid #575656;
        padding-right: 10px;
        height: 20px;
        align-items: center; }
        .a-03800 .disabled div.prev a {
          display: flex;
          height: 20px;
          align-items: center; }
      .a-03800 .disabled div.wrap {
        display: flex;
        align-items: center; }
        .a-03800 .disabled div.wrap .text-right {
          margin-right: 15px; }
  .a-03800 .arrow,
  .a-03800 .iconClose {
    height: 15px;
    width: 15px;
    position: relative; }
    .a-03800 .arrow svg,
    .a-03800 .iconClose svg {
      width: 100%;
      height: 100%;
      position: absolute; }
  .a-03800 .disabled {
    color: #B1B2B3;
    cursor: default; }
    .a-03800 .disabled a {
      cursor: default; }
    .a-03800 .disabled svg g, .a-03800 .disabled svg path, .a-03800 .disabled svg polygon, .a-03800 .disabled svg rect {
      fill: #B1B2B3 !important; }
  .a-03800 .iconClose svg g, .a-03800 .iconClose svg path, .a-03800 .iconClose svg polygon, .a-03800 .iconClose svg rect {
    fill: white !important; }

.new-flag {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 50px;
  padding-top: 6px;
  padding-left: 4px;
  overflow: hidden; }
  @media (min-width: 576px) {
    .new-flag {
      width: 128px;
      height: 62px;
      padding-top: 10px;
      padding-left: 6px; } }
  @media (min-width: 992px) {
    .new-flag {
      width: 100px;
      height: 50px;
      padding-top: 6px;
      padding-left: 4px; } }
  @media (min-width: 1244px) {
    .new-flag {
      width: 128px;
      height: 62px;
      padding-top: 10px;
      padding-left: 6px; } }
  .new-flag__slope {
    background-color: #ed0000;
    transform: skew(25deg);
    transform-origin: top;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -28px;
    top: 0; }
  .new-flag p {
    color: white;
    z-index: 2;
    position: relative; }
  .new-flag__headline {
    font-size: 28px;
    margin-bottom: 4px; }
    @media (min-width: 576px) {
      .new-flag__headline {
        font-size: 41px;
        margin-bottom: 8px; } }
    @media (min-width: 992px) {
      .new-flag__headline {
        font-size: 28px;
        margin-bottom: 4px; } }
    @media (min-width: 1244px) {
      .new-flag__headline {
        font-size: 41px;
        margin-bottom: 8px; } }
  .new-flag__subline {
    font-size: 10px; }
    @media (min-width: 576px) {
      .new-flag__subline {
        font-size: 11px; } }
    @media (min-width: 992px) {
      .new-flag__subline {
        font-size: 10px; } }
    @media (min-width: 1244px) {
      .new-flag__subline {
        font-size: 11px; } }
  @media (min-width: 576px) {
    .new-flag--large {
      width: 200px;
      height: 106px;
      padding-top: 24px; } }
  @media (min-width: 992px) {
    .new-flag--large {
      width: 157px;
      height: 84px;
      padding-top: 17px;
      padding-left: 8px; } }
  @media (min-width: 1244px) {
    .new-flag--large {
      width: 200px;
      height: 106px;
      padding-top: 24px; } }
  @media (min-width: 576px) {
    .new-flag--large .new-flag__slope {
      left: -50px; } }
  @media (min-width: 992px) {
    .new-flag--large .new-flag__slope {
      left: -40px; } }
  @media (min-width: 1244px) {
    .new-flag--large .new-flag__slope {
      left: -50px; } }
  @media (min-width: 576px) {
    .new-flag--large .new-flag__headline {
      font-size: 68px;
      margin-bottom: 27px; } }
  @media (min-width: 992px) {
    .new-flag--large .new-flag__headline {
      font-size: 52px;
      margin-bottom: 16px; } }
  @media (min-width: 1244px) {
    .new-flag--large .new-flag__headline {
      font-size: 68px;
      margin-bottom: 27px; } }
  @media (min-width: 576px) {
    .new-flag--large .new-flag__subline {
      font-size: 20px; } }
  @media (min-width: 992px) {
    .new-flag--large .new-flag__subline {
      font-size: 15px; } }
  @media (min-width: 1244px) {
    .new-flag--large .new-flag__subline {
      font-size: 20px; } }

.m-0010 {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 72px;
  justify-content: center;
  z-index: 100; }
  @media (min-width: 992px) {
    .m-0010 {
      padding: 10px 27px; } }
  @media (min-width: 992px) {
    .m-0010 {
      height: 100px;
      padding: 20px 0; } }
  .m-0010 .a-00100 {
    height: 100%; }
  .m-0010 .seperator {
    display: block;
    height: 100%;
    width: 2px;
    background-color: #cccccc;
    margin-left: 27px;
    margin-right: 25px; }

.m-0020 {
  position: relative;
  width: 100%; }
  @media (max-width: 992px) {
    .m-0020 {
      overflow: hidden;
      max-width: 100%; } }
  .m-0020 .a-00300 {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-left: 15px;
    padding-right: 27px;
    padding-bottom: 27px; }
    @media (min-width: 992px) {
      .m-0020 .a-00300 {
        padding-left: 0; } }
  .m-0020 .wrapper {
    display: block;
    position: relative; }
    @media (min-width: 768px) {
      .m-0020 .wrapper {
        width: 100%;
        max-width: 720px;
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 992px) {
      .m-0020 .wrapper {
        max-width: 960px; } }
    @media (min-width: 1244px) {
      .m-0020 .wrapper {
        max-width: 1220px; } }
  .m-0020--b .wrapper {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 2; }
  .m-0020--b .arrow {
    color: white;
    float: right;
    padding-right: 27px;
    padding-bottom: 23px;
    font-weight: bold; }
  .m-0020--a .a-00500, .m-0020--b .a-00500 {
    text-align: center;
    /*
            @include toDesktop {
                position: relative;
                width: 100%;
                left: 50%;
                transform: translateX(-50%);
            }
            @include toTablet {
                left: 25%;
                height: 310px;
            }
            img.img-fluid {
                display: inline-block;
                @include toDesktop {
                    width: auto;
                }
                @include toTablet {
                    max-width: unset;
                    height: 310px;
                }
            }
            */ }
  .m-0020--c .a-00500 {
    text-align: center;
    overflow: hidden; }
    @media (max-width: 767px) {
      .m-0020--c .a-00500 {
        height: 180px; } }
    @media (max-width: 992px) {
      .m-0020--c .a-00500 {
        position: relative;
        width: 100%;
        left: 50%;
        transform: translateX(-50%); } }
    .m-0020--c .a-00500 img.img-fluid {
      display: inline-block; }
      @media (max-width: 767px) {
        .m-0020--c .a-00500 img.img-fluid {
          display: inline-block;
          position: relative;
          width: 100%;
          transform: translateX(-50%);
          margin-left: calc(50%);
          max-width: none;
          height: 180px;
          object-fit: cover; } }
      @media (min-width: 768px) {
        .m-0020--c .a-00500 img.img-fluid {
          height: auto;
          max-width: 100%; } }
      @media (min-width: 1920px) {
        .m-0020--c .a-00500 img.img-fluid {
          object-fit: cover;
          width: 100%;
          max-height: 600px; } }
  .m-0020--d .a-00500 {
    text-align: center; }
    @media (max-width: 992px) {
      .m-0020--d .a-00500 {
        position: relative;
        width: 100%;
        left: 50%;
        transform: translateX(-50%); } }
    @media (max-width: 767px) {
      .m-0020--d .a-00500 {
        left: 25%;
        height: 104px; } }
    .m-0020--d .a-00500 img.img-fluid {
      display: inline-block; }
      @media (max-width: 767px) {
        .m-0020--d .a-00500 img.img-fluid {
          max-width: unset;
          height: 104px; } }
      @media (min-width: 1920px) {
        .m-0020--d .a-00500 img.img-fluid {
          object-fit: cover;
          width: 100%;
          max-height: 300px; } }

@media (min-width: 768px) {
  .m-0030 {
    display: flex;
    flex-direction: row; } }

@media (min-width: 992px) {
  .m-0030 {
    display: block; } }

.m-0030 .a-00700__wrapper + .a-00700__wrapper:before {
  content: '';
  height: 1px;
  display: block;
  width: 100%;
  background-color: #B1B2B3;
  left: 0px;
  position: relative;
  z-index: 1; }
  @media (min-width: 768px) {
    .m-0030 .a-00700__wrapper + .a-00700__wrapper:before {
      display: none; } }
  @media (min-width: 992px) {
    .m-0030 .a-00700__wrapper + .a-00700__wrapper:before {
      display: block; } }

@media (min-width: 992px) {
  .m-0040 {
    margin-bottom: 40px; } }

.m-0040__carousel-wrapper {
  position: relative;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw; }
  @media (min-width: 992px) {
    .m-0040__carousel-wrapper {
      width: auto;
      left: 0;
      right: 0;
      margin-left: 0;
      margin-right: 0; } }

.m-0040__carousel-outer {
  width: 100%;
  overflow: hidden; }

.m-0040__carousel-inner {
  width: auto;
  display: flex;
  flex-wrap: nowrap;
  touch-action: pan-y !important;
  transition: transform .4s ease-in-out; }
  @media (min-width: 992px) {
    .m-0040__carousel-inner {
      touch-action: auto !important; } }

.m-0040__carousel-slide {
  min-width: 100%;
  display: inline-block;
  position: relative;
  background-color: #B1B2B3; }
  .m-0040__carousel-slide img {
    max-width: none;
    width: 100%; }

.m-0040__image-description-wrapper {
  position: absolute;
  left: 100%;
  margin-left: -20px;
  bottom: 2px;
  width: 50%; }
  @media (min-width: 768px) {
    .m-0040__image-description-wrapper {
      margin-left: -37px;
      bottom: 10px; } }

.m-0040__image-description {
  display: block;
  transform: rotate(270deg);
  transform-origin: 0 0;
  font-size: 8px;
  color: #575656;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 11px; }
  @media (min-width: 768px) {
    .m-0040__image-description {
      font-size: 10px;
      color: #575656;
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-weight: 400;
      line-height: 14px; } }

.m-0040__gallery-line {
  background-color: white;
  position: absolute;
  z-index: 3; }
  .m-0040__gallery-line--vertical {
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%; }
  .m-0040__gallery-line--horizontal {
    width: 100%;
    height: 1px;
    top: 50%;
    left: 0; }

.m-0040 .a-00400 {
  position: absolute;
  right: 12px;
  top: 0;
  z-index: 1; }
  @media (min-width: 992px) {
    .m-0040 .a-00400 {
      width: 40px;
      height: 40px;
      right: 27px; }
      .m-0040 .a-00400 svg {
        max-width: 19px;
        max-height: 19px; } }

.m-0040 .gallery-icon {
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 16px;
  cursor: pointer; }
  .m-0040 .gallery-icon p {
    color: white; }
  .m-0040 .gallery-icon .icon-row {
    display: flex;
    justify-content: center; }
    .m-0040 .gallery-icon .icon-row span {
      width: 8px;
      height: 8px;
      background-color: white;
      margin-bottom: 3px; }
      .m-0040 .gallery-icon .icon-row span:last-child {
        margin-left: 3px; }

.m-0040 .carousel-indicators {
  z-index: 3; }
  @media (min-width: 992px) {
    .m-0040 .carousel-indicators {
      bottom: -40px; } }
  .m-0040 .carousel-indicators li {
    border-radius: 50%;
    height: 6px;
    width: 6px;
    opacity: 1;
    border: 0;
    box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.41); }
    @media (min-width: 992px) {
      .m-0040 .carousel-indicators li {
        height: 7px;
        width: 7px;
        background-color: #B1B2B3;
        margin-right: 5px;
        margin-left: 5px; } }
    .m-0040 .carousel-indicators li.active {
      background-color: #ED0000;
      height: 8px;
      width: 8px; }
      @media (min-width: 992px) {
        .m-0040 .carousel-indicators li.active {
          height: 9px;
          width: 9px; } }

.m-0040 .carousel-control {
  display: none; }
  @media (min-width: 992px) {
    .m-0040 .carousel-control {
      display: flex;
      width: 20px;
      justify-content: center;
      align-items: center;
      cursor: pointer; } }
  .m-0040 .carousel-control-prev {
    left: -30px; }
  .m-0040 .carousel-control-next {
    right: -30px; }
  .m-0040 .carousel-control svg {
    width: 20px; }

.m-0040 .galleryItem {
  display: block;
  float: left;
  max-width: 50%;
  overflow: hidden; }
  .m-0040 .galleryItem .galleryPlaceholder img {
    opacity: 0; }

.m-0040 .swiper-button {
  display: none;
  background-size: 20px; }
  @media (min-width: 992px) {
    .m-0040 .swiper-button {
      display: block; } }
  .m-0040 .swiper-button-prev {
    left: -30px;
    background-image: url(../Icons/relaunch/icon_arrow_schwarz_left.svg); }
  .m-0040 .swiper-button-next {
    right: -30px;
    background-image: url(../Icons/relaunch/icon_arrow_schwarz_right.svg); }
  .m-0040 .swiper-button svg {
    width: 20px; }

.m-0040 .swiper-pagination {
  left: 90px;
  right: 90px;
  bottom: 20px; }
  @media (min-width: 992px) {
    .m-0040 .swiper-pagination {
      bottom: 10px; } }
  .m-0040 .swiper-pagination span {
    border-radius: 50%;
    height: 6px;
    width: 6px;
    background-color: white;
    opacity: 1;
    border: 0;
    box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.41);
    margin-right: 5px;
    margin-left: 5px; }
    @media (min-width: 992px) {
      .m-0040 .swiper-pagination span {
        height: 7px;
        width: 7px;
        background-color: #B1B2B3;
        margin-right: 5px;
        margin-left: 5px; } }
    .m-0040 .swiper-pagination span.swiper-pagination-bullet-active {
      background-color: #ED0000;
      height: 8px;
      width: 8px; }
      @media (min-width: 992px) {
        .m-0040 .swiper-pagination span.swiper-pagination-bullet-active {
          height: 9px;
          width: 9px; } }

.m-0040--centeredImages .m-0040__carousel-slide {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-height: 387px; }
  .m-0040--centeredImages .m-0040__carousel-slide img {
    max-height: 100%;
    object-fit: scale-down; }

@media (max-width: 992px) {
  .m-0040--centeredImages .m-0040__carousel-wrapper {
    padding-top: 56.25%; } }

@media (max-width: 992px) {
  .m-0040--centeredImages .m-0040__carousel-outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }

@media (max-width: 992px) {
  .m-0040--centeredImages .m-0040__carousel-inner {
    max-width: 100%;
    max-height: 100%; } }

@media (max-width: 992px) {
  .m-0040--centeredImages .m-0040__carousel-inner .a-00600, .m-0040--centeredImages .m-0040__carousel-inner px-a00600, .m-0040--centeredImages .m-0040__carousel-inner px-lazy-image {
    display: block;
    max-width: 100%;
    height: 100%; } }

.m-0040--centeredImages .m-0040__carousel-inner .a-00600 {
  position: initial; }
  .m-0040--centeredImages .m-0040__carousel-inner .a-00600 .gradient {
    left: 0; }

@media (max-width: 992px) {
  .m-0040--centeredImages .m-0040__carousel-inner .a-00600 px-lazy-image {
    display: flex;
    justify-content: center;
    align-items: center; } }

.m-0041 .gallery-icon {
  bottom: 15px;
  left: auto;
  right: 15px; }
  .m-0041 .gallery-icon svg {
    width: 24px;
    height: 24px;
    fill: #BFBFBF !important; }
    .m-0041 .gallery-icon svg polygon, .m-0041 .gallery-icon svg path {
      fill: #BFBFBF !important; }

@media (max-width: 992px) {
  .px-m-0041-modal-overlay .modal-dialog {
    margin: 75px 0 0; } }

@media (min-width: 576px) {
  .px-m-0041-modal-overlay .modal-dialog {
    max-width: 991px; } }

@media (min-width: 992px) {
  .px-m-0041-modal-overlay .modal-dialog {
    margin-top: 220px;
    max-width: 914px; } }

.px-m-0041-modal-overlay .modal-header {
  border-bottom: 0 solid transparent; }
  .px-m-0041-modal-overlay .modal-header .close {
    padding: 15px;
    line-height: 24px;
    font-size: 48px;
    opacity: 1; }
    .px-m-0041-modal-overlay .modal-header .close span {
      position: relative;
      top: -5px; }

.px-m-0041-modal-overlay .modal-body {
  padding: 0 54px; }

.px-m-0041-modal-overlay .carousel-control {
  opacity: 1; }
  @media (max-width: 992px) {
    .px-m-0041-modal-overlay .carousel-control {
      display: flex;
      width: 20px;
      justify-content: center;
      align-items: center;
      cursor: pointer; } }
  .px-m-0041-modal-overlay .carousel-control-prev {
    left: -39px; }
    @media (max-width: 992px) {
      .px-m-0041-modal-overlay .carousel-control-prev {
        left: 15px; } }
  .px-m-0041-modal-overlay .carousel-control-next {
    right: -39px; }
    @media (max-width: 992px) {
      .px-m-0041-modal-overlay .carousel-control-next {
        right: 15px; } }
  .px-m-0041-modal-overlay .carousel-control svg {
    width: 24px;
    height: auto; }

.px-m-0041-modal-overlay .m-0040__carousel-slide {
  background-color: transparent; }

.px-m-0041-modal-overlay .m-0040__carousel-inner .a-00600 .gradient {
  display: none; }

.px-m-0041-modal-overlay .m-0040--centeredImages .m-0040__carousel-wrapper {
  padding-top: 0; }

.px-m-0041-modal-overlay .m-0040--centeredImages .m-0040__carousel-outer {
  position: relative; }

.px-m-0041-modal-overlay .m-0040 .carousel-indicators {
  position: relative;
  margin-top: 15px; }
  @media (min-width: 992px) {
    .px-m-0041-modal-overlay .m-0040 .carousel-indicators {
      bottom: 0; } }

.m-0050__container-wrapper > .row:nth-child(odd) {
  background-color: #E4E4E5; }

@media (min-width: 768px) {
  .m-0050__container-wrapper > .row:nth-child(even) .desktopContent {
    flex-direction: row-reverse;
    margin-left: -25px;
    margin-right: -15px; } }

@media (min-width: 768px) {
  .m-0050__container-wrapper > .row:nth-child(even) .innerConent {
    padding-left: 45px;
    padding-right: 15px; } }

@media (min-width: 992px) {
  .m-0050__container-wrapper > .row:nth-child(even) .innerConent {
    padding-left: 115px; } }

@media (min-width: 768px) {
  .m-0050__container-wrapper > .row:nth-child(even) .innerImg {
    padding-left: 0 !important;
    padding-right: 15px; } }

@media (min-width: 992px) {
  .m-0050__container-wrapper > .row:nth-child(even) .innerImg {
    padding-right: 12px; } }

.m-0050__container-wrapper--invert > .row:nth-child(odd) {
  background-color: transparent; }

.m-0050__container-wrapper--invert > .row:nth-child(even) {
  background-color: #E4E4E5; }

@media (min-width: 768px) {
  .m-0050__container-wrapper--firstVideoLeft > .row:nth-child(odd) .desktopContent {
    flex-direction: row-reverse;
    margin-left: -25px;
    margin-right: -15px; } }

@media (min-width: 768px) {
  .m-0050__container-wrapper--firstVideoLeft > .row:nth-child(odd) .innerConent {
    padding-left: 45px;
    padding-right: 15px; } }

@media (min-width: 992px) {
  .m-0050__container-wrapper--firstVideoLeft > .row:nth-child(odd) .innerConent {
    padding-left: 115px; } }

@media (min-width: 768px) {
  .m-0050__container-wrapper--firstVideoLeft > .row:nth-child(odd) .innerImg {
    padding-left: 0 !important;
    padding-right: 15px !important; } }

@media (min-width: 992px) {
  .m-0050__container-wrapper--firstVideoLeft > .row:nth-child(odd) .innerImg {
    padding-left: 0 !important;
    padding-right: 12px; } }

@media (min-width: 768px) {
  .m-0050__container-wrapper--firstVideoLeft > .row:nth-child(even) .desktopContent {
    flex-direction: row;
    margin-left: -15px;
    margin-right: -25px; } }

@media (min-width: 768px) {
  .m-0050__container-wrapper--firstVideoLeft > .row:nth-child(even) .innerConent {
    padding-left: 15px;
    padding-right: 45px; } }

@media (min-width: 992px) {
  .m-0050__container-wrapper--firstVideoLeft > .row:nth-child(even) .innerConent {
    padding-right: 115px;
    padding-left: 15px; } }

@media (min-width: 768px) {
  .m-0050__container-wrapper--firstVideoLeft > .row:nth-child(even) .innerImg {
    padding-left: 15px !important;
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .m-0050__container-wrapper--firstVideoLeft > .row:nth-child(even) .innerImg {
    padding-right: 0 !important;
    padding-left: 12px; } }

.m-0050 .mobileContent {
  width: 100%;
  margin: 0 0; }
  @media (max-width: 767px) {
    .m-0050 .mobileContent .innerImg {
      width: 100%; } }
  @media (max-width: 767px) {
    .m-0050 .mobileContent .innerImg img {
      width: 100%; } }

.m-0050__seperator {
  border-top: 1px solid #E4E4E5; }

@media (max-width: 767px) {
  .m-0050__container {
    width: 100%; } }

@media (min-width: 768px) {
  .m-0050__container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 960px; } }

@media (min-width: 992px) {
  .m-0050__container {
    padding: 0 12px; } }

@media (min-width: 1244px) {
  .m-0050__container {
    max-width: 1220px; } }

.m-0050__wrapper {
  padding: 0; }
  .m-0050__wrapper--campaigns .desktopContent {
    display: flex;
    flex-direction: row-reverse; }
    .m-0050__wrapper--campaigns .desktopContent .innerConent {
      padding-left: 115px;
      padding-right: 38px; }

.m-0050 .content {
  padding: 15px 25px 25px;
  width: 100%; }
  @media (min-width: 768px) {
    .m-0050 .content {
      padding-top: 70px;
      padding-bottom: 70px; } }
  @media (min-width: 992px) {
    .m-0050 .content {
      padding-left: 0;
      padding-right: 0; } }

.m-0050 .headline {
  margin-bottom: 7px;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: black; }
  @media (min-width: 992px) {
    .m-0050 .headline {
      font-size: 28px;
      line-height: 38px; } }
  @media (min-width: 768px) {
    .m-0050 .headline {
      margin-bottom: 25px; } }

.m-0050 .copy {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto;
  margin-bottom: 15px; }
  @media (min-width: 992px) {
    .m-0050 .copy {
      font-size: 16px; } }
  @media (min-width: 768px) {
    .m-0050 .copy {
      margin-bottom: 35px; } }
  .m-0050 .copy strong {
    font-family: mmc-bold,Helvetica,Arial,sans-serif;
    font-weight: normal; }

@media (min-width: 768px) {
  .m-0050 .desktopContent {
    margin-right: -25px; } }

@media (min-width: 992px) {
  .m-0050 .desktopContent {
    margin-right: -15px; } }

@media (min-width: 768px) {
  .m-0050 .innerConent {
    padding-right: 45px; } }

@media (min-width: 992px) {
  .m-0050 .innerConent {
    padding-right: 115px; } }

.m-0050 .innerConent ol, .m-0050 .innerConent ul {
  padding-left: 20px; }

@media (min-width: 768px) {
  .m-0050 .innerImg {
    padding-right: 0 !important; } }

.m-0050 .innerImg .embed-responsive {
  width: 100%;
  max-width: 100%; }
  @media (min-width: 768px) {
    .m-0050 .innerImg .embed-responsive {
      width: 600px; } }

@media (max-width: 767px) {
  .m-0050 .innerVideo {
    padding: 0 11px; } }

@media (max-width: 767px) {
  .m-0050 .innerVideo + .contentwrapper {
    padding: 0 11px; } }

.m-0050 .a-00800 {
  max-width: 100%; }
  @media (min-width: 768px) {
    .m-0050 .a-00800 {
      max-width: 267px; } }

.m-0050 .envkv {
  margin-bottom: 0; }

.m-0050 .a-00800 + .envkv {
  margin-top: 15px; }

@media (max-width: 992px) {
  .m-0050 .a-00500 img {
    width: 100%; } }

.m-0050 px-a01100 {
  display: block;
  margin-bottom: 15px; }
  @media (min-width: 768px) {
    .m-0050 px-a01100 {
      margin-bottom: 20px; }
      .m-0050 px-a01100 .a-01500 .text {
        font-size: 20px;
        line-height: 20px; } }

.m-0050 px-a01500 .a-01500 {
  border-top: 1px solid #cccccc;
  border-bottom: 0 solid transparent; }

.m-0050 px-a01500 .collapse, .m-0050 px-a01500 .collapsing {
  font-size: 14px;
  line-height: 20px; }

.m-0050 px-a01500 .show, .m-0050 px-a01500 .collapsing {
  border-top: 1px solid #cccccc; }

.m-0050 px-a01500 .collapse > div, .m-0050 px-a01500 .collapsing > div {
  padding: 15px 0px 25px; }

.m-0050 .appStoreBadgeBtn {
  width: 100%;
  padding: 0; }

.m-0050 .appStoreBadge {
  width: 100%;
  height: auto; }

.m-0050--b .copy {
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .m-0050--b .copy {
      margin-bottom: 35px; } }

.m-0050--e .innerConent b, .m-0050--e .innerConent strong {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-weight: 400; }

@media (min-width: 768px) {
  .m-0050__wrapper:nth-child(even) .desktopContent {
    flex-direction: row-reverse;
    margin-left: -25px;
    margin-right: -15px; } }

@media (min-width: 768px) {
  .m-0050__wrapper:nth-child(even) .innerConent {
    padding-left: 45px;
    padding-right: 15px; } }

@media (min-width: 992px) {
  .m-0050__wrapper:nth-child(even) .innerConent {
    padding-left: 115px; } }

@media (min-width: 768px) {
  .m-0050__wrapper:nth-child(even) .innerImg {
    padding-left: 0 !important;
    padding-right: 15px; } }

@media (min-width: 992px) {
  .m-0050__wrapper:nth-child(even) .innerImg {
    padding-right: 12px; } }

.m-0050 .embed-responsive .cmplazypreviewiframe {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.fullWidthTeaserEdit__header {
  position: relative;
  height: 20px; }

.fullWidthTeaserEdit__close {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 16px;
  height: 16px;
  font-size: 30px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  cursor: pointer; }

.fullWidthTeaserEdit__editText p {
  margin-bottom: 0; }

.fullWidthTeaserEdit__teaserRow {
  margin-left: 0px; }

.fullWidthTeaserEdit__teaser {
  padding: 0;
  margin-top: 15px; }

.fullWidthTeaserEdit__content {
  min-width: 950px; }

.fullWidthTeaserEdit__teaserInner {
  position: relative;
  display: flex;
  height: 100%;
  padding: 50px 15px 15px;
  margin-right: 15px;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #dee2e6; }
  .fullWidthTeaserEdit__teaserInner.disabled {
    border-color: rgba(222, 226, 230, 0.5); }
    .fullWidthTeaserEdit__teaserInner.disabled .fullWidthTeaserEdit__teaserImg, .fullWidthTeaserEdit__teaserInner.disabled .fullWidthTeaserEdit__teaserHeadline {
      opacity: 0.5; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .fullWidthTeaserEdit__teaserInner {
      display: block; } }

.fullWidthTeaserEdit__teaserCheckbox {
  position: absolute;
  width: 25px;
  top: 15px;
  right: 15px; }

.fullWidthTeaserEdit__teaserHeadline {
  display: flex;
  min-height: 48px;
  margin-top: 5px;
  flex-direction: column;
  justify-content: flex-end; }
  .fullWidthTeaserEdit__teaserHeadline h4 {
    font-weight: 700; }

.fullWidthTeaserEdit__saveBtn {
  margin-top: 10px;
  float: right; }

@media (max-width: 767px) {
  .m-0051 .mobileContent .innerImg {
    width: 100%; } }

@media (max-width: 767px) {
  .m-0051 .mobileContent .innerImg img {
    width: 100%; } }

.m-0051__seperator {
  border-top: 1px solid #E4E4E5; }

.m-0051__container {
  width: 100%; }
  @media (min-width: 768px) {
    .m-0051__container {
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      max-width: 960px; } }
  @media (min-width: 992px) {
    .m-0051__container {
      padding: 0 12px; } }
  @media (min-width: 1244px) {
    .m-0051__container {
      max-width: 1220px; } }

.m-0051__wrapper {
  padding: 0; }

.m-0051 .content {
  padding: 15px 25px 25px; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .m-0051 .content {
      width: 100%; } }
  @media (min-width: 768px) {
    .m-0051 .content {
      padding: 49px 0px; } }
  @media (max-width: 767px) {
    .m-0051 .content {
      padding: 15px 25px 9px; } }

.m-0051 .headline {
  margin-bottom: 7px;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: black; }
  @media (min-width: 992px) {
    .m-0051 .headline {
      font-size: 28px;
      line-height: 38px; } }
  @media (min-width: 768px) {
    .m-0051 .headline {
      margin-bottom: 25px; } }

.m-0051 .copy {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto;
  margin-bottom: 15px; }
  @media (min-width: 992px) {
    .m-0051 .copy {
      font-size: 16px; } }
  @media (min-width: 768px) {
    .m-0051 .copy {
      margin-bottom: 35px; } }

@media (min-width: 768px) {
  .m-0051 .innerConent {
    padding-right: 45px; } }

@media (min-width: 992px) {
  .m-0051 .innerConent {
    padding-right: 115px; } }

.m-0051 .envkv {
  color: #575656;
  font-size: 14px; }

.m-0051 .a-00800 {
  max-width: 100%; }
  @media (min-width: 768px) {
    .m-0051 .a-00800 {
      max-width: 267px; } }

.m-0051 .a-00500 img {
  width: 100%; }

@media (min-width: 768px) {
  .m-0051--a__container-wrapper > .row:nth-child(even) .desktopContent {
    flex-direction: row-reverse;
    margin-left: -25px;
    margin-right: -15px; } }

@media (min-width: 768px) {
  .m-0051--a__container-wrapper > .row:nth-child(even) .innerConent {
    padding-left: 45px;
    padding-right: 15px; } }

@media (min-width: 992px) {
  .m-0051--a__container-wrapper > .row:nth-child(even) .innerConent {
    padding-left: 115px; } }

@media (min-width: 768px) {
  .m-0051--a__container-wrapper > .row:nth-child(even) .innerImg {
    padding-left: 0 !important;
    padding-right: 15px; } }

@media (min-width: 992px) {
  .m-0051--a__container-wrapper > .row:nth-child(even) .innerImg {
    padding-right: 12px; } }

.m-0051--b .copy {
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .m-0051--b .copy {
      margin-bottom: 35px; } }
  @media (max-width: 992px) {
    .m-0051--b .copy {
      font-weight: bold;
      color: #575656;
      margin-bottom: 10px;
      font-size: 14px; } }

.m-0051--b .price {
  font-size: 24px;
  margin-bottom: 20px; }
  @media (max-width: 992px) {
    .m-0051--b .price {
      text-align: right;
      font-size: 18px; } }

@media (min-width: 992px) {
  .m-0051--b .headline {
    margin-bottom: 10px; } }

@media (max-width: 992px) {
  .m-0051--b .headline {
    font-size: 18px;
    margin-bottom: 10px; } }

.m-0051--b hr {
  margin-top: 30px;
  margin-bottom: 10px; }
  @media (max-width: 992px) {
    .m-0051--b hr {
      margin-top: 12px; } }

.m-0051--b .content {
  padding-left: 0;
  padding-right: 0; }
  @media (max-width: 767px) {
    .m-0051--b .content {
      padding-left: 11px;
      padding-right: 11px; } }

.m-0051--c .innerConent {
  justify-content: center;
  display: flex;
  flex-direction: column; }

.m-0051--c .headline {
  margin-bottom: 10px; }

.m-0051--c .copy {
  margin-bottom: 28px; }

.m-0051--c .phoneContainer {
  display: flex;
  margin-bottom: 15px; }
  .m-0051--c .phoneContainer .phone {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto; }
    @media (min-width: 992px) {
      .m-0051--c .phoneContainer .phone {
        font-size: 16px; } }
  .m-0051--c .phoneContainer .phoneIcon {
    margin-right: 20px; }
    .m-0051--c .phoneContainer .phoneIcon svg {
      height: 22px; }

.m-0051--c .mailContainer {
  display: flex; }
  .m-0051--c .mailContainer .mail {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .m-0051--c .mailContainer .mail {
        font-size: 16px; } }
  .m-0051--c .mailContainer .mailIcon {
    margin-right: 20px; }
    .m-0051--c .mailContainer .mailIcon svg {
      height: 22px; }

@media (min-width: 768px) {
  .m-0051__wrapper:nth-child(even) .desktopContent {
    flex-direction: row-reverse;
    margin-left: -25px;
    margin-right: -15px; } }

@media (min-width: 768px) {
  .m-0051__wrapper:nth-child(even) .innerConent {
    padding-left: 45px;
    padding-right: 15px; } }

@media (min-width: 992px) {
  .m-0051__wrapper:nth-child(even) .innerConent {
    padding-left: 115px; } }

@media (min-width: 768px) {
  .m-0051__wrapper:nth-child(even) .innerImg {
    padding-left: 0 !important;
    padding-right: 15px; } }

@media (min-width: 992px) {
  .m-0051__wrapper:nth-child(even) .innerImg {
    padding-right: 12px; } }

.m-0060 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  transition: opacity 0.4s, transform 0.4s;
  height: 100%; }
  @media (min-width: 992px) {
    .m-0060 {
      max-width: 380px; } }
  .m-0060 > a {
    height: 100%;
    display: flex;
    flex-direction: column; }
  .m-0060 a:focus {
    box-shadow: 0 0 0 0 transparent;
    border-color: transparent;
    outline-color: transparent; }
  @media (min-width: 992px) {
    .m-0060:hover {
      animation-name: m-0060scale;
      animation-duration: 0.4s;
      transform: scale(1.04, 1.04); } }
  .m-0060.active {
    transition: opacity 0.7s, transform 0.8s;
    opacity: 0;
    transform: scale(1.4, 1.4); }
  .m-0060__tirelabel {
    font-size: 12px;
    color: #000 !important;
    display: block;
    margin-top: 10px; }
    .m-0060__tirelabel svg {
      width: 11px;
      height: 10px;
      margin-left: 4px;
      top: -1px;
      position: relative; }
  .m-0060__image-wrapper {
    position: relative; }
    .m-0060__image-wrapper .a-00600 {
      z-index: 0; }
  .m-0060__image-description-wrapper {
    position: absolute;
    left: 100%;
    margin-left: -20px;
    bottom: 2px;
    width: 50%; }
  .m-0060__image-description {
    display: block;
    transform: rotate(270deg);
    transform-origin: 0 0;
    font-size: 8px;
    color: #575656;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 11px; }
  .m-0060__arrow {
    position: absolute;
    bottom: 18px;
    right: 12px;
    width: 16px;
    height: 10px;
    z-index: 5; }
    .m-0060__arrow svg {
      bottom: 16px;
      position: relative; }
    @media (max-width: 992px) {
      .m-0060__arrow {
        z-index: 0; } }
  .m-0060__content {
    justify-self: flex-end;
    align-self: flex-end;
    padding: 10px 15px 12px;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .m-0060__content ul {
      list-style-position: inside; }
  .m-0060__model {
    margin: 0;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: black; }
    @media (min-width: 992px) {
      .m-0060__model {
        font-size: 28px;
        line-height: 38px; } }
  .m-0060__title {
    margin: 2px 0 0;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto;
    color: black; }
    @media (min-width: 992px) {
      .m-0060__title {
        font-size: 16px; } }
  .m-0060__tags .a-02800 {
    display: inline-block; }
  .m-0060__envkv {
    margin-top: 11px; }
    @media (min-width: 992px) {
      .m-0060__envkv {
        margin-top: 17px; } }
    .m-0060__envkv span {
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: black;
      word-wrap: break-word;
      hyphens: auto;
      color: #575656; }
      @media (min-width: 992px) {
        .m-0060__envkv span {
          font-size: 16px; } }
      .m-0060__envkv span strong, .m-0060__envkv span b {
        font-family: 'mmc-bold', Helvetica, Arial, sans-serif;
        font-weight: normal; }
  .m-0060__envkvItem {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto;
    color: #575656; }
    @media (min-width: 992px) {
      .m-0060__envkvItem {
        font-size: 16px; } }
    .m-0060__envkvItem:first-child {
      margin-right: 10px; }
  .m-0060 .a-03000 {
    margin-top: 15px;
    border-width: 1px; }
  .m-0060__price {
    text-align: right;
    margin-top: 5px; }
    .m-0060__price .h1alternative {
      font-family: "mmc-bold", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 14px; }
      @media (min-width: 992px) {
        .m-0060__price .h1alternative {
          font-size: 24px; } }
  .m-0060 .a-00400 {
    position: absolute;
    top: 0;
    right: 20px; }
  .m-0060--c .a-00400, .m-0060--d .a-00400 {
    position: absolute;
    right: 15px;
    top: 0; }
  .m-0060--c .m-0060__price .h1alternative, .m-0060--d .m-0060__price .h1alternative {
    color: #ED0000 !important; }
  .m-0060--f .gradient {
    height: 50%; }
  .m-0060--f .editButtonWrapper {
    position: absolute;
    z-index: 1; }
  .m-0060--f .m-0060__model {
    margin-bottom: 5px; }
    @media (min-width: 992px) {
      .m-0060--f .m-0060__model {
        margin-bottom: 10px; } }
  .m-0060--f .m-0060__TextContainer {
    max-height: 150px;
    overflow: hidden; }
  .m-0060--f .CropActive {
    position: relative; }
    .m-0060--f .CropActive .overlay {
      position: absolute;
      height: 45px;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.55), #ffffff);
      width: 100%;
      bottom: 0;
      opacity: 0.4; }
  .m-0060--no-car-fallback > a {
    height: auto;
    display: block; }
  .m-0060--no-car-fallback .m-0060__content {
    flex-grow: 0; }

@keyframes m-0060scale {
  0% {
    transform: scale(1, 1); }
  50% {
    transform: scale(1.06, 1.06); }
  100% {
    transform: scale(1.04, 1.04); } }

.m-0065 {
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column; }
  @media (min-width: 992px) {
    .m-0065:hover {
      animation-name: m-0060scale;
      animation-duration: 0.4s;
      transform: scale(1.04, 1.04); } }
  .m-0065__image-wrapper {
    position: relative; }
    .m-0065__image-wrapper .a-00600 {
      z-index: 0; }
      .m-0065__image-wrapper .a-00600 .gradient {
        background: transparent !important; }
  .m-0065__badge {
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 89px;
    width: 89px;
    top: 0;
    right: 0;
    background-color: black;
    color: white;
    border-radius: 50%;
    font-size: 15px;
    line-height: 18px;
    text-align: center; }
  .m-0065__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    background-color: #E4E4E5;
    padding: 5px 15px 15px; }
    @media (min-width: 992px) {
      .m-0065__content {
        padding: 15px;
        margin-top: -1px; } }
  .m-0065__produktNr {
    font-size: 10px;
    line-height: 21px;
    margin-bottom: -2px; }
    @media (min-width: 992px) {
      .m-0065__produktNr {
        line-height: 12px; } }
  .m-0065__headline {
    font-weight: normal;
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 21px; }
    @media (min-width: 992px) {
      .m-0065__headline {
        font-size: 20px;
        line-height: 25px; } }
  .m-0065__list ul {
    padding-left: 20px;
    font-size: 10px;
    line-height: 20px;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .m-0065__list ul {
        margin-bottom: 25px; } }
    .m-0065__list ul li {
      margin-bottom: 10px; }
      .m-0065__list ul li span {
        font-size: 13px;
        line-height: 20px;
        vertical-align: top; }
        @media (min-width: 992px) {
          .m-0065__list ul li span {
            font-size: 16px;
            line-height: 20px; } }
  .m-0065__price {
    font-size: 20px;
    line-height: 21px; }
  .m-0065 .a-00700--c {
    margin-top: 15px; }
    @media (min-width: 992px) {
      .m-0065 .a-00700--c {
        margin-top: 25px; } }

.m-0070 {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #E4E4E5;
  min-height: 100%;
  transition: opacity 0.7s, transform 0.8s;
  transition-delay: 0.5s; }
  .m-0070__container {
    padding-top: 12px; }
    @media (min-width: 768px) {
      .m-0070__container {
        padding-top: 40px; } }
    @media (min-width: 992px) {
      .m-0070__container {
        padding-top: 80px; } }
    .m-0070__container .btn-editMode {
      display: none; }
      @media (min-width: 992px) {
        .m-0070__container .btn-editMode {
          display: block; } }
  .m-0070__wrapper {
    margin-bottom: 12px;
    padding-left: 11px;
    padding-right: 11px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .m-0070__wrapper {
        flex-basis: 100%; } }
    @media (min-width: 768px) {
      .m-0070__wrapper {
        padding-left: 15px;
        padding-right: 15px; } }
    @media (min-width: 992px) {
      .m-0070__wrapper {
        padding-left: 12px;
        padding-right: 12px; } }
    @media (min-width: 768px) {
      .m-0070__wrapper {
        margin-bottom: 24px;
        padding: 0; } }
  @media all and (min-width: 768px) and (-ms-high-contrast: none), (min-width: 768px) and (-ms-high-contrast: active) {
    .m-0070__wrapper {
      flex-basis: 49.2%; } }

@media all and (min-width: 992px) and (-ms-high-contrast: none), (min-width: 992px) and (-ms-high-contrast: active) {
  .m-0070__wrapper {
    flex-basis: 32.2%; } }
  .m-0070__teaser-wrapper {
    display: -ms-grid;
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr; }
    @media (min-width: 768px) {
      .m-0070__teaser-wrapper {
        grid-column-gap: 24px;
        grid-auto-rows: 1fr;
        -ms-grid-columns: 1fr 24px 1fr;
        grid-template-columns: 1fr 1fr; } }
    @media (min-width: 992px) {
      .m-0070__teaser-wrapper {
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr; } }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .m-0070__teaser-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }
    .m-0070__teaser-wrapper--auto-rows {
      grid-template-rows: auto;
      grid-auto-rows: max-content; }
  .m-0070 .img {
    flex: 0 0 0; }
    .m-0070 .img__arrow {
      position: absolute;
      right: 20px;
      bottom: 19px;
      color: white;
      width: 12px;
      transition: right 0.6s;
      z-index: 5; }
      .m-0070 .img__arrow svg g, .m-0070 .img__arrow svg path, .m-0070 .img__arrow svg polygon, .m-0070 .img__arrow svg rect {
        fill: white !important; }
    .m-0070 .img__link {
      position: relative;
      display: block;
      width: 100%;
      min-height: 152px;
      background-color: black; }
  .m-0070:hover .img__arrow {
    right: 13px;
    animation-name: m-0070Arrow;
    animation-duration: 0.6s; }
  .m-0070 .headline-top {
    margin-bottom: 5px; }
  .m-0070 .headline {
    margin-bottom: 10px;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: black; }
    @media (min-width: 992px) {
      .m-0070 .headline {
        font-size: 28px;
        line-height: 38px; } }
  .m-0070 .copy {
    margin-bottom: 10px;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto; }
    @media (min-width: 992px) {
      .m-0070 .copy {
        font-size: 16px; } }
  .m-0070 .contentWrapper {
    flex: 1 0 auto;
    max-height: 100%;
    display: flex; }
  .m-0070 .content {
    width: 100%;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media (min-width: 992px) {
      .m-0070 .content {
        padding: 20px 25px 15px; } }
  .m-0070 hr {
    margin: 10px 0 5px; }
  .m-0070 .title {
    margin-bottom: 5px;
    color: black;
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    line-height: 18px; }
    @media (min-width: 992px) {
      .m-0070 .title {
        font-size: 16px; } }
  .m-0070 .price {
    text-align: right;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto; }
    @media (min-width: 992px) {
      .m-0070 .price {
        font-size: 16px; } }
  .m-0070 .price-amount {
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px; }
    @media (min-width: 992px) {
      .m-0070 .price-amount {
        font-size: 24px; } }
  .m-0070 .envkv {
    color: #575656;
    font-size: 14px;
    line-height: 18px; }
    @media (min-width: 992px) {
      .m-0070 .envkv {
        font-size: 16px;
        line-height: 26px; } }
  .m-0070 .employee strong {
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 29px;
    color: black;
    font-weight: 400 !important; }
    @media (min-width: 992px) {
      .m-0070 .employee strong {
        font-size: 20px; } }
  .m-0070 px-a00900 {
    display: block;
    width: 100%;
    margin-bottom: 5px; }
    .m-0070 px-a00900 .a-00900 {
      min-width: 100%; }
  .m-0070 .a-00600--d {
    max-height: 224px;
    overflow: hidden;
    display: flex;
    align-items: center; }
  .m-0070--d .content {
    justify-content: flex-start; }
  .m-0070 > px-edit-button {
    position: absolute;
    top: 5px;
    right: 5px; }

.cdk-overlay-pane.edit-mask-panel {
  overflow: auto;
  max-height: 100%; }

.teaserEdit__btn {
  margin-bottom: 10px; }

.teaserEdit__header {
  position: relative;
  height: 25px; }

.teaserEdit__close {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 16px;
  height: 16px;
  font-size: 30px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  cursor: pointer; }

.teaserEdit__content ngb-tabset {
  position: relative;
  display: block; }

.teaserEdit__content .nav-tabs .nav-item {
  position: relative;
  border-radius: 0; }
  .teaserEdit__content .nav-tabs .nav-item:after {
    content: '';
    width: 1px;
    position: absolute;
    right: 0;
    top: 20px;
    bottom: 20px;
    background-color: #dee2e6; }
  .teaserEdit__content .nav-tabs .nav-item:last-child:after {
    background-color: white; }
  .teaserEdit__content .nav-tabs .nav-item .nav-link {
    position: relative;
    padding-top: 20px;
    padding-bottom: 24px;
    border-color: white;
    border-bottom-color: #dee2e6;
    color: black; }
    .teaserEdit__content .nav-tabs .nav-item .nav-link.active {
      border-bottom-color: white; }
      .teaserEdit__content .nav-tabs .nav-item .nav-link.active:after {
        content: '';
        height: 4px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ED0000; }
    .teaserEdit__content .nav-tabs .nav-item .nav-link:hover {
      color: black; }

.teaserEdit__tabContent {
  border: 1px solid #dee2e6;
  border-top-color: transparent; }
  .teaserEdit__tabContent .grid-wrapper {
    position: relative;
    width: 1199px;
    max-width: 1199px;
    height: 900px !important;
    overflow-y: auto; }
  .teaserEdit__tabContent .grid-wrapper-hero {
    position: relative;
    width: 1199px;
    max-width: 1199px;
    height: 600px !important;
    overflow: auto; }
  .teaserEdit__tabContent--newTeaser, .teaserEdit__tabContent--oldTeaser {
    padding: 10px;
    min-height: 615px; }
  .teaserEdit__tabContent--oldTeaser {
    border-color: transparent; }

.teaserEdit__gridScrollWrapper {
  max-height: 700px;
  overflow-y: auto; }

.teaserEdit__gridScrollWrapperMin {
  max-height: 700px;
  min-height: 700px;
  overflow-y: auto; }

.teaserEdit__activateBtn {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px; }

.teaserEdit__grid {
  background-color: transparent; }

.teaserEdit__item {
  background-color: #E4E4E5;
  overflow-y: auto; }

.teaserEdit__itemContent {
  display: flex;
  height: 100%;
  padding-top: 35px;
  flex-direction: column;
  justify-content: space-between; }

.teaserEdit__itemImg {
  max-height: 197px;
  overflow: hidden;
  display: flex;
  align-items: center; }
  .teaserEdit__itemImg img {
    width: 100%; }

.teaserEdit__itemForm {
  padding: 10px; }

.teaserEdit__itemChecboxLabel {
  vertical-align: middle;
  margin-bottom: 10px; }

.teaserEdit__itemChecbox {
  position: relative;
  display: inline-block;
  top: 2px;
  width: 16px;
  height: 16px; }

.teaserEdit__itemInput + .teaserEdit__itemInput {
  margin-top: 10px; }

.teaserEdit__editTeaser {
  position: absolute;
  width: 16px;
  top: 8px;
  right: 35px;
  line-height: 19px;
  cursor: pointer; }

.teaserEdit__removeTeaser {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  font-size: 30px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  cursor: pointer; }

.teaserEdit__headline {
  padding: 10px 16px 10px 10px; }

.teaserEdit__saveBtn {
  margin-top: 10px;
  float: right; }

.teaserEdit__stepper {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.teaserEdit__stepperLink {
  display: none;
  color: #29aab3; }
  .teaserEdit__stepperLink.active {
    display: block; }

.teaserEdit__stepperItem {
  padding: 10px 10px 10px 0; }

.teaserEdit__newTeaserWrapper {
  padding-bottom: 47px; }

.teaserEdit__newTeaserRow {
  margin-left: 0;
  max-height: 693px;
  overflow-y: auto; }

.teaserEdit__newTeaser {
  padding: 20px 15px 0 0; }

.teaserEdit__newTeaserContent {
  display: flex;
  height: 100%;
  padding: 15px;
  border: 1px solid #dee2e6;
  flex-direction: column;
  justify-content: space-between; }

.teaserEdit__chooseBtn {
  display: block;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto; }

.teaserEdit__createForm {
  min-height: 530px;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.teaserEdit__create {
  margin-top: 20px;
  min-height: 600px; }

.teaserEdit__createImgUp {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column; }
  .teaserEdit__createImgUp .custom-file-input {
    cursor: pointer; }
  .teaserEdit__createImgUp .custom-file-label::after {
    content: 'Durchsuchen...' !important; }
  .teaserEdit__createImgUp .btn-editMode {
    display: block; }
  .teaserEdit__createImgUp.error .custom-file-label {
    border-color: #ED0000;
    border-width: 2px; }
    .teaserEdit__createImgUp.error .custom-file-label::after {
      max-height: 100%; }

.teaserEdit__createPreview {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .teaserEdit__createPreview .a-00600 img {
    width: 100%; }
  .teaserEdit__createPreview .m-0070 {
    min-height: 0; }

.teaserEdit__createFormRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 15px; }
  .teaserEdit__createFormRow .form-group textarea.error, .teaserEdit__createFormRow .form-group select.error, .teaserEdit__createFormRow .form-group input[type=text].error {
    border-color: #ED0000;
    border-width: 2px; }
  .teaserEdit__createFormRow .btn-editMode.error {
    border-color: #ED0000;
    border-width: 2px; }
  .teaserEdit__createFormRow .custom-file-input.error + label {
    border-color: #ED0000;
    border-width: 2px; }
    .teaserEdit__createFormRow .custom-file-input.error + label:after {
      border-bottom: 2px solid #ed0000; }

.teaserEdit__createBtn.disabled {
  opacity: .65; }

.teaserEdit__createVehicleWrapper {
  padding: 15px 15px 0;
  border: 1px solid #dee2e6;
  border-top-color: transparent; }
  .teaserEdit__createVehicleWrapper input[type=radio] {
    margin-right: 5px; }
    .teaserEdit__createVehicleWrapper input[type=radio] + label {
      display: flex; }

.teaserEdit__createVehicleListItem {
  display: flex; }

.teaserEdit__createVehicleImg {
  max-width: 20%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-self: center; }
  .teaserEdit__createVehicleImg img {
    margin-top: auto;
    margin-bottom: auto; }

.teaserEdit__createVehicleInfo {
  max-width: 80%;
  margin: 0; }

.teaserEdit__croppedImg {
  max-height: 410px; }
  .teaserEdit__croppedImg + .btn {
    position: absolute;
    width: 41px;
    top: 0;
    right: 0;
    font-size: 28px;
    line-height: 21px;
    font-weight: 700;
    text-align: center; }

.teaserEdit__imgCropper {
  max-height: 420px; }
  .teaserEdit__imgCropper .cropper img {
    max-width: 474px;
    max-height: 410px; }
  .teaserEdit__imgCropper + .btn {
    margin-left: auto;
    width: 115px;
    width: max-content; }

.teaserEdit__saved {
  width: 100%;
  margin-top: 150px; }

.teaserEdit__savedIcon {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px; }

.teaserEdit__savedText {
  text-align: center; }

@keyframes m-0070Arrow {
  0% {
    right: 20px; }
  50% {
    right: 10px; }
  75% {
    right: 15px; }
  100% {
    right: 13px; } }

px-m0071 {
  height: 100%;
  display: block; }

.m-0071 {
  height: 100%; }
  @media (max-width: 767px) {
    .m-0071 {
      display: flex;
      flex-direction: column; } }
  .m-0071 .content {
    padding: 15px 25px 25px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .m-0071 .content {
        width: 100%; } }
    @media (max-width: 767px) {
      .m-0071 .content {
        padding: 35px 25px 45px;
        background-color: #E4E4E5;
        flex-grow: 1; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .m-0071 .content {
        padding: 15px 0 25px; } }
    @media (min-width: 992px) {
      .m-0071 .content {
        padding-left: 0;
        padding-right: 0; } }
  .m-0071 .headline {
    margin-bottom: 20px;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: black; }
    @media (min-width: 992px) {
      .m-0071 .headline {
        font-size: 28px;
        line-height: 38px; } }
    @media (min-width: 992px) {
      .m-0071 .headline {
        margin-bottom: 25px; } }
  .m-0071 .copy {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto;
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .m-0071 .copy {
        font-size: 16px; } }
    @media (min-width: 768px) {
      .m-0071 .copy {
        margin-bottom: 35px; } }
  @media (min-width: 768px) {
    .m-0071 .innerConent {
      padding-left: 45px; } }
  @media (min-width: 992px) {
    .m-0071 .innerConent {
      padding-left: 115px; } }
  .m-0071 .innerConent b, .m-0071 .innerConent strong {
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-weight: 400; }
  @media (min-width: 768px) {
    .m-0071 .innerImg {
      padding-right: 0 !important; } }
  .m-0071 .a-00800 {
    max-width: 100%; }
    @media (max-width: 992px) {
      .m-0071 .a-00800 {
        margin-bottom: 15px; } }
    @media (min-width: 768px) {
      .m-0071 .a-00800 {
        max-width: 267px; } }
  @media (max-width: 992px) {
    .m-0071 .a-00500 img {
      width: 100%; } }

.m-0080 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  z-index: 1051; }
  @media (min-width: 992px) {
    .m-0080 {
      width: auto;
      z-index: 1; } }
  @media (min-width: 992px) {
    .m-0080 > a:last-child {
      padding-right: 0; } }

.m-0090__wrapper {
  display: flex;
  padding-top: 25px;
  background-color: white;
  justify-content: center; }
  @media (min-width: 992px) {
    .m-0090__wrapper {
      padding-top: 30px; } }

.m-0090__col {
  max-width: 50%; }
  @media (min-width: 992px) {
    .m-0090__col {
      flex-basis: 225px;
      max-width: 255px;
      max-height: 50px; } }
  .m-0090__col--big {
    flex-basis: 375px;
    max-width: 375px; }
  @media (min-width: 992px) {
    .m-0090__col:nth-child(2) {
      padding-left: 25px; } }

.m-0090__wrapperButton {
  padding-bottom: 25px;
  background-color: white; }
  @media (min-width: 992px) {
    .m-0090__wrapperButton {
      padding-bottom: 30px; } }

.m-0090__separator {
  position: absolute;
  top: 12.5%;
  right: 0;
  width: 2px;
  height: 75%;
  border: 1px solid #E3E5E6; }
  @media (min-width: 992px) {
    .m-0090__separator {
      top: 0;
      height: 100%; } }

.m-0090__ratingSum {
  margin-right: 10px; }

.m-0090 .rating {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 60px;
  line-height: 45px;
  color: #ED0000; }

.m-0090__rating {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between; }
  .m-0090__rating .a-02600 {
    width: 126px; }
    @media (min-width: 992px) {
      .m-0090__rating .a-02600 {
        width: 130px; }
        .m-0090__rating .a-02600 .star ~ .star {
          margin-left: 8px; }
        .m-0090__rating .a-02600 .star svg {
          width: 18px;
          height: 18px; } }
    .m-0090__rating .a-02600 a {
      position: relative;
      top: 10px; }

.m-0090__google {
  display: block;
  width: 126px;
  margin-top: 10px;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto;
  color: #575656; }
  @media (min-width: 992px) {
    .m-0090__google {
      font-size: 16px; } }
  @media (min-width: 992px) {
    .m-0090__google {
      width: 130px; } }

.m-0090__hours, .m-0090__google {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto;
  color: #575656;
  line-height: 19px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .m-0090__hours, .m-0090__google {
      font-size: 16px; } }
  .m-0090__hours br, .m-0090__google br {
    display: none; }
  @media (min-width: 992px) {
    .m-0090__hours, .m-0090__google {
      height: 50px; } }

@media (min-width: 992px) {
  .m-0090__hours {
    padding-left: 35px; } }

.m-0090__hours strong {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 29px;
  color: black; }
  @media (min-width: 992px) {
    .m-0090__hours strong {
      font-size: 20px; } }

.m-0090__hours.noGoogleReviews {
  text-align: center; }
  @media (min-width: 992px) {
    .m-0090__hours.noGoogleReviews {
      padding-left: 0; } }

@media (min-width: 992px) {
  .m-0090__hours--toMonday {
    padding-left: 0; } }

.m-0090__buttonWrapper {
  display: flex;
  margin-top: 25px;
  justify-content: center;
  align-items: center;
  padding: 0 25px; }
  .m-0090__buttonWrapper .a-00800 {
    max-width: 100%; }
  @media (min-width: 992px) {
    .m-0090__buttonWrapper {
      margin-top: 30px; } }
  .m-0090__buttonWrapper .a-00800 {
    max-width: 286px; }
  @media (min-width: 992px) {
    .m-0090__buttonWrapper--big .a-00800 {
      max-width: 480px; } }

.m-0090 .rating-wrapper {
  padding: 15px;
  background-color: white;
  margin-top: 10px; }

.m-0090 .rating-separator {
  position: absolute;
  top: 12.5%;
  right: 0;
  width: 2px;
  height: 75%;
  border: 1px solid #E3E5E6; }

.m-0090 .rating-a-02600 {
  position: relative;
  height: 24px; }

.m-0090 .rating-hours, .m-0090 .rating-google {
  color: #3c3c3c;
  font-size: 14px; }

.m-0090 .rating-hours {
  font-weight: bold; }

.m-0100 {
  padding-left: 25px; }
  @media (min-width: 992px) {
    .m-0100 {
      padding-left: 0; } }
  .m-0100 .wrapper {
    display: flex;
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .m-0100 .wrapper {
        margin-top: 22px;
        margin-bottom: 0; } }
  .m-0100 .headline {
    margin-bottom: 0;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: black; }
    @media (min-width: 992px) {
      .m-0100 .headline {
        font-size: 28px;
        line-height: 38px; } }
  .m-0100 .ratingSum {
    height: 66px;
    margin-right: 15px;
    margin-top: 15px; }
    @media (min-width: 992px) {
      .m-0100 .ratingSum {
        height: 74px; } }
  .m-0100 .ratingWrapper {
    display: inline-block;
    margin-top: 15px; }
    .m-0100 .ratingWrapper .externalLink {
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: black;
      word-wrap: break-word;
      hyphens: auto;
      color: #575656; }
      @media (min-width: 992px) {
        .m-0100 .ratingWrapper .externalLink {
          font-size: 16px; } }
      @media (min-width: 992px) {
        .m-0100 .ratingWrapper .externalLink {
          display: block;
          margin-top: 7px;
          font-size: 15px; } }
  .m-0100 .starList {
    list-style: none;
    margin-bottom: 0; }
    .m-0100 .starList li {
      float: left; }

.m-0105 {
  padding-bottom: 22px;
  width: 100%; }
  @media (min-width: 992px) {
    .m-0105 {
      padding-bottom: 60px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; } }
  .m-0105 .a-00600 img {
    width: 100%; }
  .m-0105 .image-wrapper {
    position: relative; }
  .m-0105 .text {
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 2; }
    @media (min-width: 992px) {
      .m-0105 .text {
        position: relative;
        left: 0;
        bottom: 0; } }
    .m-0105 .text p {
      color: white;
      margin: 0; }
      @media (min-width: 992px) {
        .m-0105 .text p {
          color: black; } }
    .m-0105 .text__name {
      font-family: "mmc-bold", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 29px;
      color: black;
      color: white;
      margin-bottom: 5px !important; }
      @media (min-width: 992px) {
        .m-0105 .text__name {
          font-size: 20px; } }
      @media (min-width: 992px) {
        .m-0105 .text__name {
          margin-top: 22px !important;
          margin-bottom: 0 !important; } }
    .m-0105 .text__function {
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: black;
      word-wrap: break-word;
      hyphens: auto;
      color: #575656; }
      @media (min-width: 992px) {
        .m-0105 .text__function {
          height: 42px; } }
      @media (min-width: 992px) {
        .m-0105 .text__function {
          font-size: 16px; } }
      @media (max-width: 992px) {
        .m-0105 .text__function {
          font-size: 12px; } }
      .m-0105 .text__function span {
        margin-right: 5px; }
        .m-0105 .text__function span + span:before {
          content: '';
          display: inline-block;
          height: 10px;
          width: 1px;
          background-color: #fff;
          top: 1px;
          position: relative;
          margin-right: 5px; }
  .m-0105 .separator {
    height: 20px;
    width: 1px;
    background-color: #B1B2B3; }
    @media (min-width: 992px) {
      .m-0105 .separator {
        width: 100%;
        height: 1px; } }
  .m-0105 .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 22px;
    margin-bottom: auto; }
    @media (min-width: 992px) {
      .m-0105 .buttons {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; } }
    .m-0105 .buttons a {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: black;
      font-size: 12px;
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: black; }
      @media (min-width: 992px) {
        .m-0105 .buttons a {
          font-size: 14px; } }
      @media (min-width: 992px) {
        .m-0105 .buttons a {
          padding-top: 20px;
          padding-bottom: 20px; } }
      .m-0105 .buttons a svg {
        height: 24px;
        width: 24px;
        margin-right: 7px; }
        @media (min-width: 992px) {
          .m-0105 .buttons a svg {
            margin-right: 14px;
            height: 20px;
            width: 20px; } }
        .m-0105 .buttons a svg path {
          fill: black !important; }
      .m-0105 .buttons a:hover {
        color: #ED0000; }
        .m-0105 .buttons a:hover svg path {
          fill: #ED0000 !important; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .m-0105 .image-wrapper {
    flex: 0 0 auto; } }

.m-0110 {
  position: relative;
  height: auto;
  background-color: white;
  padding: 15px;
  transition: height 1s;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  margin: 0 6px 10px; }
  @media (min-width: 992px) {
    .m-0110 {
      padding: 20px 25px 20px;
      font-size: 16px; } }
  .m-0110:focus, .m-0110:active {
    border: 0 solid transparent !important; }
  .m-0110 .author, .m-0110 .relativTime {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto; }
    @media (min-width: 992px) {
      .m-0110 .author, .m-0110 .relativTime {
        font-size: 16px; } }
  .m-0110 .relativTime {
    color: #575656;
    margin-bottom: 2px; }
    @media (min-width: 992px) {
      .m-0110 .relativTime {
        margin-bottom: 10px;
        line-height: 22px; } }
  .m-0110 .a-02600 {
    margin-top: 4px;
    margin-bottom: 8px; }
    @media (min-width: 992px) {
      .m-0110 .a-02600 {
        position: absolute;
        right: 25px;
        top: 20px;
        margin-top: 0; } }
  .m-0110 p {
    margin: 0;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto; }
    @media (min-width: 992px) {
      .m-0110 p {
        font-size: 16px; } }
  .m-0110 .moreBtn {
    cursor: pointer;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: black; }
    @media (min-width: 992px) {
      .m-0110 .moreBtn {
        font-size: 14px; } }

.m-0120 {
  background-color: white;
  display: block;
  width: 100%; }
  .m-0120 + .m-0120 {
    margin-top: 12px; }
  .m-0120__content-wrapper {
    display: flex;
    height: 105px; }
  .m-0120__picture {
    border-right: 1px solid #E4E4E5; }
  .m-0120__text {
    display: flex;
    padding: 0 10px 0 19px;
    justify-content: center;
    flex-direction: column; }
    .m-0120__text h3 {
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: black; }
      @media (min-width: 992px) {
        .m-0120__text h3 {
          font-size: 28px;
          line-height: 38px; } }
  .m-0120__price {
    margin-bottom: 0;
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #575656; }
    @media (min-width: 992px) {
      .m-0120__price {
        font-size: 24px; } }
  .m-0120__offers {
    margin-bottom: 0;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto;
    color: #575656; }
    @media (min-width: 992px) {
      .m-0120__offers {
        font-size: 16px; } }
  .m-0120__arrow {
    display: flex;
    width: 16px;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: auto; }
    .m-0120__arrow svg {
      opacity: .5; }
    .m-0120__arrow div {
      width: 16px;
      height: 10px;
      position: relative;
      top: -5px; }
  .m-0120__collapse-container {
    padding: 12px 12px 0 12px;
    background-color: #575656; }
    @media (min-width: 768px) {
      .m-0120__collapse-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between; } }
    @media (min-width: 992px) {
      .m-0120__collapse-container {
        display: block; } }
  .m-0120 px-m0060 {
    display: block; }
    @media screen and (min-width: 635px) {
      .m-0120 px-m0060 {
        max-width: 49%; } }
    @media (min-width: 768px) {
      .m-0120 px-m0060 {
        margin-top: 12px; } }
    @media (min-width: 992px) {
      .m-0120 px-m0060 {
        max-width: none;
        margin-top: 0; } }
    .m-0120 px-m0060 + px-m0060 {
      margin-top: 12px; }
    .m-0120 px-m0060:nth-child(3n) {
      margin-top: 12px; }
  .m-0120 .m-0060 + .m-0060 {
    margin-top: 12px; }

.m-0130-seperator {
  border-right: 1px solid #ccc; }

.m-0130-segment {
  padding: 10px 0px; }
  .m-0130-segment p {
    font-family: 'Open Sans',sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase; }
  .m-0130-segment-wrapper {
    position: relative; }

.m-0130-icon-wrapper {
  width: 40px;
  height: 40px;
  background-color: red;
  margin-bottom: 10px; }

.m-0130-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer; }

.m-0140 {
  display: flex; }
  .m-0140 .a-01900, .m-0140 px-a01900 {
    max-width: 100%;
    flex: 1 0 50%;
    border-right: 1px solid #cccccc; }
    .m-0140 .a-01900:last-child, .m-0140 px-a01900:last-child {
      border-right: 1px solid transparent; }

.m-0160 {
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .m-0160 {
      padding-bottom: 34px; } }
  .m-0160__bulletlist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 12px; }
    @media (min-width: 992px) {
      .m-0160__bulletlist {
        margin-top: 5px; } }
    .m-0160__bulletlist px-a02700 {
      flex-basis: 50%; }
      .m-0160__bulletlist px-a02700:nth-child(even) {
        text-align: right; }
      @media (min-width: 768px) {
        .m-0160__bulletlist px-a02700 {
          flex-basis: 33%; }
          .m-0160__bulletlist px-a02700:nth-child(even) {
            text-align: inherit; }
          .m-0160__bulletlist px-a02700:nth-child(3n+2) {
            text-align: center; }
          .m-0160__bulletlist px-a02700:nth-child(3n+3) {
            text-align: right; } }
      @media (min-width: 992px) {
        .m-0160__bulletlist px-a02700 {
          margin-top: 12px; } }
    .m-0160__bulletlist .a-02700 {
      flex-basis: 50%; }
      @media (min-width: 768px) {
        .m-0160__bulletlist .a-02700 {
          flex-basis: 33%; } }
    .m-0160__bulletlist--campaign px-a02700 {
      flex-basis: 100%; }
      @media (min-width: 768px) {
        .m-0160__bulletlist--campaign px-a02700 {
          flex-basis: 100%; } }
      @media (min-width: 992px) {
        .m-0160__bulletlist--campaign px-a02700 {
          flex-basis: 33%; } }

.m-0165 {
  padding-bottom: 20px; }
  @media (min-width: 992px) {
    .m-0165 {
      padding-bottom: 34px; } }
  .m-0165__technical-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 19px; }
    @media (min-width: 992px) {
      .m-0165__technical-details {
        margin-top: 5px; } }
    .m-0165__technical-details px-a03350 {
      flex-basis: 100%; }
      @media (min-width: 768px) {
        .m-0165__technical-details px-a03350 {
          flex-basis: 25%; } }
      @media (min-width: 992px) {
        .m-0165__technical-details px-a03350 {
          margin-top: 12px; } }
    .m-0165__technical-details .a-03350 {
      flex-basis: 50%; }
      @media (min-width: 768px) {
        .m-0165__technical-details .a-03350 {
          flex-basis: 25%; } }
  .m-0165__break {
    flex-basis: 100%;
    height: 0; }

.m-0170__detaillist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -6px; }
  @media (min-width: 992px) {
    .m-0170__detaillist {
      margin-top: -3px; } }
  .m-0170__detaillist px-a03300 {
    flex-basis: 50%;
    margin-top: 21px; }
    @media (min-width: 768px) {
      .m-0170__detaillist px-a03300 {
        margin-top: 29px;
        flex-basis: 33%;
        max-width: 33%; }
        .m-0170__detaillist px-a03300 .a-03300__wrapper {
          word-break: break-word; } }
  .m-0170__detaillist .a-03300 {
    flex-basis: 50%; }
    @media (min-width: 768px) {
      .m-0170__detaillist .a-03300 {
        flex-basis: 33%; } }

.m-0200__wrapper {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cccccc; }

.m-0200__label {
  font-size: 12px;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  color: black;
  margin-bottom: 0; }

.m-0200__collapse {
  border-bottom: 1px solid #cccccc;
  padding-top: 17px; }

.m-0200__exception {
  margin-top: 12px; }

.m-0200 px-a02700 {
  display: block;
  margin-bottom: 17px; }

.m-0205 {
  padding: 11px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .m-0205 px-a03350 {
    flex-basis: 100%; }
    @media (min-width: 576px) {
      .m-0205 px-a03350 {
        flex-basis: 50%; } }
    @media (min-width: 768px) {
      .m-0205 px-a03350 {
        flex-basis: 25%; } }

.m-0210 {
  margin: 0 auto 40px auto;
  padding: 0 17px; }
  @media (min-width: 768px) {
    .m-0210 {
      padding: 0; } }
  @media (min-width: 992px) {
    .m-0210 {
      margin-bottom: 80px; } }
  .m-0210__headline {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: black; }
    @media (min-width: 992px) {
      .m-0210__headline {
        font-size: 28px;
        line-height: 38px; } }
    @media (min-width: 992px) {
      .m-0210__headline {
        margin-top: 60px;
        margin-bottom: 30px;
        max-width: 500px; } }
  @media (min-width: 992px) {
    .m-0210 .a-01600 {
      padding: 30px 300px 30px 0;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center; } }
  .m-0210 .a-01600 .text-wrapper {
    align-items: start;
    flex-direction: column;
    justify-content: center; }
    .m-0210 .a-01600 .text-wrapper p {
      margin: 0; }
  @media (min-width: 992px) {
    .m-0210 .a-01600 .text {
      margin-left: 0;
      padding-bottom: 20px; } }
  @media (min-width: 992px) {
    .m-0210 .a-01600 .icon {
      flex-basis: 200px;
      flex-shrink: 0;
      flex-grow: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex-direction: column; } }
  @media (min-width: 992px) {
    .m-0210 .a-01600 .icon svg {
      max-width: 110px; } }
  @media (min-width: 992px) {
    .m-0210 .a-01600 .icon svg.icon_calculator {
      max-width: 85px; } }

.m-0220 .contentBox {
  padding: 0 25px;
  background-color: white; }
  @media (min-width: 992px) {
    .m-0220 .contentBox {
      padding: 0 15px; } }
  .m-0220 .contentBox.gmap_wrapper {
    font-size: 10px;
    padding: 10px;
    margin: 0; }
  @media (max-width: 992px) {
    .m-0220 .contentBox.approach {
      margin-bottom: 25px; } }
  .m-0220 .contentBox .headline {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: black;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .m-0220 .contentBox .headline {
        font-size: 28px;
        line-height: 38px; } }
    @media (min-width: 992px) {
      .m-0220 .contentBox .headline {
        margin-bottom: 40px;
        margin-top: 40px; } }
  .m-0220 .contentBox .subheadline {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto;
    color: black;
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-weight: 400; }
    @media (min-width: 992px) {
      .m-0220 .contentBox .subheadline {
        font-size: 16px; } }
    @media (min-width: 992px) {
      .m-0220 .contentBox .subheadline {
        margin-bottom: 5px;
        margin-top: 15px; } }
    .m-0220 .contentBox .subheadline--margin-bottom {
      margin-bottom: -10px;
      display: block; }
    .m-0220 .contentBox .subheadline--noMargin {
      margin: 0; }
    .m-0220 .contentBox .subheadline--big {
      font-family: "mmc-bold", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 29px;
      color: black; }
      @media (min-width: 992px) {
        .m-0220 .contentBox .subheadline--big {
          font-size: 20px; } }
  .m-0220 .contentBox .copy.verkauf, .m-0220 .contentBox .copy.service {
    margin: 0;
    padding-top: 0; }
  .m-0220 .contentBox .copy.openingTime {
    line-height: 24px;
    margin: 0;
    padding-top: 0;
    font-variant-numeric: tabular-nums;
    font-feature-settings: "tnum"; }
    @media (min-width: 992px) {
      .m-0220 .contentBox .copy.openingTime {
        line-height: 26px; } }
  .m-0220 .contentBox .hours {
    margin-bottom: 5px;
    display: block; }
    .m-0220 .contentBox .hours + .hours {
      margin-top: -5px; }
  .m-0220 .contentBox .link {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto;
    color: black; }
    @media (min-width: 992px) {
      .m-0220 .contentBox .link {
        font-size: 16px; } }
  .m-0220 .contentBox .title {
    font-size: 16px; }
    @media (min-width: 768px) {
      .m-0220 .contentBox .title {
        font-size: 14px; } }
  .m-0220 .contentBox .break_word {
    word-wrap: break-word; }
  .m-0220 .contentBox .openingHours {
    margin-bottom: 10px; }
    .m-0220 .contentBox .openingHours .dayCol {
      padding-right: 0; }
    .m-0220 .contentBox .openingHours .timeCol {
      padding-left: 0; }

.m-0220 .someIcons {
  display: flex; }
  @media (max-width: 992px) {
    .m-0220 .someIcons {
      margin-top: -5px;
      margin-bottom: 10px; } }
  .m-0220 .someIcons a {
    display: block;
    margin-right: 25px; }

.m-0220 .standort {
  font-size: 12px; }
  @media (min-width: 992px) {
    .m-0220 .standort {
      font-size: 16px; } }

.m-0220 .standort_wrapper {
  margin-top: 20px;
  margin-bottom: 15px; }
  .m-0220 .standort_wrapper--filiale {
    margin-top: 15px; }
    .m-0220 .standort_wrapper--filiale .someIcons, .m-0220 .standort_wrapper--filiale .subheadline--soMe {
      display: none; }
  @media (min-width: 992px) {
    .m-0220 .standort_wrapper {
      margin-bottom: 35px; } }

.m-0220 .gmap_container {
  margin-bottom: 25px;
  background-color: white; }

.m-0220 .separator {
  border-top: 1px solid #9E9FA0;
  margin-top: #9E9FA0; }

.m-0220 .a-01500 {
  border-bottom: 0px solid transparent; }
  @media (min-width: 992px) {
    .m-0220 .a-01500 {
      justify-content: center;
      align-items: center; }
      .m-0220 .a-01500 .arrow {
        margin-left: 10px; } }

.m-0220 .a-00800 {
  max-width: 100%; }

@media (min-width: 992px) {
  .m-0250 {
    height: 100px;
    padding: 20px 0 0;
    overflow: visible; } }

.m-0250__icon-link-list a:last-child {
  border: 0; }

.m-0250__icon-link-list .col-12 a {
  border-bottom: 1px solid #B1B2B3; }

@media (min-width: 992px) {
  .m-0250__ul {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    flex: 1;
    list-style: none;
    margin: 0; } }

.m-0250__ul .changeMargin {
  margin-left: 15px; }

@media (min-width: 992px) {
  .m-0250__ul a {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto;
    color: black;
    letter-spacing: 0.35px;
    white-space: nowrap;
    margin-left: 35px; } }
  @media (min-width: 992px) and (min-width: 992px) {
    .m-0250__ul a {
      font-size: 16px; } }

.m-0250__ul li {
  line-height: normal;
  overflow-y: visible;
  position: relative; }
  @media (min-width: 992px) {
    .m-0250__ul li.item-neuwagen {
      min-width: 84px; }
    .m-0250__ul li.item-fahrzeuge {
      min-width: 84px; }
    .m-0250__ul li.item-gebrauchtwagen {
      min-width: 151px; }
    .m-0250__ul li.item-aktionen {
      min-width: 109px; }
    .m-0250__ul li.item-greenmobility {
      min-width: 152px; }
    .m-0250__ul li.item-service {
      min-width: 93px; }
    .m-0250__ul li.item-ueberuns {
      min-width: 106px; }
    .m-0250__ul li.item-kooperationen {
      min-width: 154px; } }
  @media (min-width: 992px) {
    .m-0250__ul li {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      padding-bottom: 20px; } }
  @media (min-width: 992px) {
    .m-0250__ul li:first-child a {
      margin-left: 0; } }
  .m-0250__ul li a:hover {
    font-family: mmc-bold, Helvetica, Arial, sans-serif;
    font-weight: bold; }
  .m-0250__ul li a.active {
    font-family: mmc-bold, Helvetica, Arial, sans-serif;
    position: relative; }
    .m-0250__ul li a.active:after {
      position: absolute;
      content: '';
      border-color: #ed0000;
      border-bottom-width: 3px;
      border-style: solid;
      bottom: -20px;
      left: -5px;
      right: -5px;
      height: 3px; }

.m-0250 ul.m-0250__dropdownBody {
  display: none;
  position: absolute;
  top: 100%;
  right: -5px;
  padding: 20px 20px 0;
  background-color: white;
  z-index: 100; }
  .m-0250 ul.m-0250__dropdownBody li {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto;
    color: black;
    letter-spacing: 0.35px;
    white-space: nowrap;
    margin-left: 0; }
    @media (min-width: 992px) {
      .m-0250 ul.m-0250__dropdownBody li {
        font-size: 16px; } }

.m-0250__dropdown:hover .m-0250__dropdownBody {
  display: block; }

.m-0250__dropdownMobileBody.hidden {
  display: none; }

.m-0250 .a-01200.open .arrow {
  transform: rotate(-90deg); }

.m-0251 {
  padding: 15px 25px; }
  .m-0251 h4 {
    font-size: 18px;
    font-weight: 700; }
  .m-0251 .a-00800 {
    margin-top: 20px; }

.m-0252 {
  margin: 22px 25px; }
  .m-0252 h4 {
    font-size: 14px;
    font-weight: 700; }
  .m-0252--d {
    margin: 22px 0px; }
    .m-0252--d .imageContentContainer {
      position: relative;
      margin-bottom: 13px; }
    .m-0252--d .contentOverlay {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.785218) 0%, rgba(0, 0, 0, 0.258608) 100%);
      padding-left: 19px;
      padding-top: 10px; }
    .m-0252--d .headline {
      margin-bottom: 1px;
      color: white; }
    .m-0252--d .copy {
      margin-bottom: 15px;
      color: white; }
    .m-0252--d .phoneContainer {
      display: flex; }
      .m-0252--d .phoneContainer a {
        font-weight: bold;
        text-transform: uppercase;
        color: black; }
      .m-0252--d .phoneContainer .phoneIcon {
        margin-right: 20px; }
        .m-0252--d .phoneContainer .phoneIcon svg {
          height: 22px; }
    .m-0252--d .mailContainer {
      display: flex; }
      .m-0252--d .mailContainer a {
        font-weight: bold;
        text-transform: uppercase;
        color: black; }
      .m-0252--d .mailContainer .mailIcon {
        margin-right: 20px; }
        .m-0252--d .mailContainer .mailIcon svg {
          height: 22px; }
    .m-0252--d .content {
      display: flex;
      justify-content: space-between; }
    .m-0252--d .seperator {
      color: #CCCCCC;
      font-size: 26px; }

.m-0280 {
  background-color: black;
  padding: 0 25px; }
  .m-0280 .a-01200:last-child {
    border-bottom: 0 solid transparent; }

.m-0290 {
  background-color: black;
  padding: 0 25px; }
  .m-0290 .a-01300 {
    max-width: 100%; }
    .m-0290 .a-01300:last-child {
      border-bottom: 0 solid transparent; }
    .m-0290 .a-01300 .cta {
      color: white;
      padding: 0 15px; }

.m-0090__wrapper {
  display: flex;
  padding-top: 25px;
  background-color: white;
  justify-content: center; }
  @media (min-width: 992px) {
    .m-0090__wrapper {
      padding-top: 30px; } }

.m-0090__col {
  max-width: 50%; }
  @media (min-width: 992px) {
    .m-0090__col {
      flex-basis: 225px;
      max-width: 255px;
      max-height: 50px; } }
  .m-0090__col--big {
    flex-basis: 375px;
    max-width: 375px; }
  @media (min-width: 992px) {
    .m-0090__col:nth-child(2) {
      padding-left: 25px; } }

.m-0090__wrapperButton {
  padding-bottom: 25px;
  background-color: white; }
  @media (min-width: 992px) {
    .m-0090__wrapperButton {
      padding-bottom: 30px; } }

.m-0090__separator {
  position: absolute;
  top: 12.5%;
  right: 0;
  width: 2px;
  height: 75%;
  border: 1px solid #E3E5E6; }
  @media (min-width: 992px) {
    .m-0090__separator {
      top: 0;
      height: 100%; } }

.m-0090__ratingSum {
  margin-right: 10px; }

.m-0090 .rating {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 60px;
  line-height: 45px;
  color: #ED0000; }

.m-0090__rating {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between; }
  .m-0090__rating .a-02600 {
    width: 126px; }
    @media (min-width: 992px) {
      .m-0090__rating .a-02600 {
        width: 130px; }
        .m-0090__rating .a-02600 .star ~ .star {
          margin-left: 8px; }
        .m-0090__rating .a-02600 .star svg {
          width: 18px;
          height: 18px; } }
    .m-0090__rating .a-02600 a {
      position: relative;
      top: 10px; }

.m-0090__google {
  display: block;
  width: 126px;
  margin-top: 10px;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto;
  color: #575656; }
  @media (min-width: 992px) {
    .m-0090__google {
      font-size: 16px; } }
  @media (min-width: 992px) {
    .m-0090__google {
      width: 130px; } }

.m-0090__hours, .m-0090__google {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto;
  color: #575656;
  line-height: 19px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .m-0090__hours, .m-0090__google {
      font-size: 16px; } }
  .m-0090__hours br, .m-0090__google br {
    display: none; }
  @media (min-width: 992px) {
    .m-0090__hours, .m-0090__google {
      height: 50px; } }

@media (min-width: 992px) {
  .m-0090__hours {
    padding-left: 35px; } }

.m-0090__hours strong {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 29px;
  color: black; }
  @media (min-width: 992px) {
    .m-0090__hours strong {
      font-size: 20px; } }

.m-0090__hours.noGoogleReviews {
  text-align: center; }
  @media (min-width: 992px) {
    .m-0090__hours.noGoogleReviews {
      padding-left: 0; } }

@media (min-width: 992px) {
  .m-0090__hours--toMonday {
    padding-left: 0; } }

.m-0090__buttonWrapper {
  display: flex;
  margin-top: 25px;
  justify-content: center;
  align-items: center;
  padding: 0 25px; }
  .m-0090__buttonWrapper .a-00800 {
    max-width: 100%; }
  @media (min-width: 992px) {
    .m-0090__buttonWrapper {
      margin-top: 30px; } }
  .m-0090__buttonWrapper .a-00800 {
    max-width: 286px; }
  @media (min-width: 992px) {
    .m-0090__buttonWrapper--big .a-00800 {
      max-width: 480px; } }

.m-0090 .rating-wrapper {
  padding: 15px;
  background-color: white;
  margin-top: 10px; }

.m-0090 .rating-separator {
  position: absolute;
  top: 12.5%;
  right: 0;
  width: 2px;
  height: 75%;
  border: 1px solid #E3E5E6; }

.m-0090 .rating-a-02600 {
  position: relative;
  height: 24px; }

.m-0090 .rating-hours, .m-0090 .rating-google {
  color: #3c3c3c;
  font-size: 14px; }

.m-0090 .rating-hours {
  font-weight: bold; }

.m-0220 .contentBox {
  padding: 0 25px;
  background-color: white; }
  @media (min-width: 992px) {
    .m-0220 .contentBox {
      padding: 0 15px; } }
  .m-0220 .contentBox.gmap_wrapper {
    font-size: 10px;
    padding: 10px;
    margin: 0; }
  @media (max-width: 992px) {
    .m-0220 .contentBox.approach {
      margin-bottom: 25px; } }
  .m-0220 .contentBox .headline {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: black;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .m-0220 .contentBox .headline {
        font-size: 28px;
        line-height: 38px; } }
    @media (min-width: 992px) {
      .m-0220 .contentBox .headline {
        margin-bottom: 40px;
        margin-top: 40px; } }
  .m-0220 .contentBox .subheadline {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto;
    color: black;
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-weight: 400; }
    @media (min-width: 992px) {
      .m-0220 .contentBox .subheadline {
        font-size: 16px; } }
    @media (min-width: 992px) {
      .m-0220 .contentBox .subheadline {
        margin-bottom: 5px;
        margin-top: 15px; } }
    .m-0220 .contentBox .subheadline--margin-bottom {
      margin-bottom: -10px;
      display: block; }
    .m-0220 .contentBox .subheadline--noMargin {
      margin: 0; }
    .m-0220 .contentBox .subheadline--big {
      font-family: "mmc-bold", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 29px;
      color: black; }
      @media (min-width: 992px) {
        .m-0220 .contentBox .subheadline--big {
          font-size: 20px; } }
  .m-0220 .contentBox .copy.verkauf, .m-0220 .contentBox .copy.service {
    margin: 0;
    padding-top: 0; }
  .m-0220 .contentBox .copy.openingTime {
    line-height: 24px;
    margin: 0;
    padding-top: 0;
    font-variant-numeric: tabular-nums;
    font-feature-settings: "tnum"; }
    @media (min-width: 992px) {
      .m-0220 .contentBox .copy.openingTime {
        line-height: 26px; } }
  .m-0220 .contentBox .hours {
    margin-bottom: 5px;
    display: block; }
    .m-0220 .contentBox .hours + .hours {
      margin-top: -5px; }
  .m-0220 .contentBox .link {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto;
    color: black; }
    @media (min-width: 992px) {
      .m-0220 .contentBox .link {
        font-size: 16px; } }
  .m-0220 .contentBox .title {
    font-size: 16px; }
    @media (min-width: 768px) {
      .m-0220 .contentBox .title {
        font-size: 14px; } }
  .m-0220 .contentBox .break_word {
    word-wrap: break-word; }
  .m-0220 .contentBox .openingHours {
    margin-bottom: 10px; }
    .m-0220 .contentBox .openingHours .dayCol {
      padding-right: 0; }
    .m-0220 .contentBox .openingHours .timeCol {
      padding-left: 0; }

.m-0220 .someIcons {
  display: flex; }
  @media (max-width: 992px) {
    .m-0220 .someIcons {
      margin-top: -5px;
      margin-bottom: 10px; } }
  .m-0220 .someIcons a {
    display: block;
    margin-right: 25px; }

.m-0220 .standort {
  font-size: 12px; }
  @media (min-width: 992px) {
    .m-0220 .standort {
      font-size: 16px; } }

.m-0220 .standort_wrapper {
  margin-top: 20px;
  margin-bottom: 15px; }
  .m-0220 .standort_wrapper--filiale {
    margin-top: 15px; }
    .m-0220 .standort_wrapper--filiale .someIcons, .m-0220 .standort_wrapper--filiale .subheadline--soMe {
      display: none; }
  @media (min-width: 992px) {
    .m-0220 .standort_wrapper {
      margin-bottom: 35px; } }

.m-0220 .gmap_container {
  margin-bottom: 25px;
  background-color: white; }

.m-0220 .separator {
  border-top: 1px solid #9E9FA0;
  margin-top: #9E9FA0; }

.m-0220 .a-01500 {
  border-bottom: 0px solid transparent; }
  @media (min-width: 992px) {
    .m-0220 .a-01500 {
      justify-content: center;
      align-items: center; }
      .m-0220 .a-01500 .arrow {
        margin-left: 10px; } }

.m-0220 .a-00800 {
  max-width: 100%; }

.m-0190 .bold {
  font-weight: bold; }

.m-0190 .value {
  word-wrap: break-word; }

.m-0190 .value_col,
.m-0190 .title-col {
  font-size: 14px;
  line-height: 20px; }
  @media (min-width: 992px) {
    .m-0190 .value_col,
    .m-0190 .title-col {
      font-size: 16px;
      line-height: 28px; } }

@media (min-width: 992px) {
  .m-0190 .title-col {
    margin-bottom: 0 !important; } }

.m-0190 .value_col {
  text-align: right; }
  @media (min-width: 992px) {
    .m-0190 .value_col {
      text-align: left; } }

.m-0300 {
  position: fixed;
  top: 50%;
  right: 0;
  padding: 10px 0 10px 13px;
  margin-top: -43px;
  background-color: #ED0000;
  z-index: 2; }
  @media (max-width: 992px) {
    .m-0300 {
      position: absolute;
      top: 0;
      padding: 5px 0 5px 13px;
      margin-top: 0; } }
  @media (max-width: 340px) {
    .m-0300 {
      padding: 5px 0 5px 5px; } }
  .m-0300--b {
    margin-top: 49px; }
    @media (max-width: 992px) {
      .m-0300--b {
        margin-top: 92px; } }
  .m-0300 .wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    transition: width 2s; }
  .m-0300 .headerIcon {
    width: 60px;
    height: 60px;
    margin-right: 13px;
    cursor: pointer; }
    @media (max-width: 992px) {
      .m-0300 .headerIcon {
        width: 45px;
        height: 45px;
        margin-right: 13px; } }
    @media (max-width: 340px) {
      .m-0300 .headerIcon {
        width: 30px;
        margin-right: 5px; } }
    .m-0300 .headerIcon svg {
      width: 100%;
      height: 100%; }
      .m-0300 .headerIcon svg g, .m-0300 .headerIcon svg path, .m-0300 .headerIcon svg polygon, .m-0300 .headerIcon svg rect {
        fill: white !important; }
  .m-0300 .linkWrapper {
    display: flex;
    width: 195px;
    height: 67px;
    flex-direction: column;
    margin-right: 10px;
    margin-left: 5px;
    transition: width 2s;
    overflow: hidden; }
    @media (max-width: 992px) {
      .m-0300 .linkWrapper {
        width: 160px;
        height: 57px;
        margin-top: 5px;
        margin-right: 20px;
        margin-left: 0; } }
    @media (max-width: 340px) {
      .m-0300 .linkWrapper {
        margin-left: 10px;
        margin-right: 5px; } }
    .m-0300 .linkWrapper.hidden {
      margin-right: 0;
      margin-left: 0;
      width: 0; }
  .m-0300 .link {
    display: block; }
  .m-0300 .headline {
    display: block;
    width: 195px;
    margin-bottom: 3px;
    background-color: transparent;
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: white; }
    @media (min-width: 992px) {
      .m-0300 .headline {
        font-size: 16px;
        line-height: 21px; } }
    @media (max-width: 992px) {
      .m-0300 .headline {
        margin-bottom: 0; } }
  .m-0300 .a-01100 {
    display: block;
    width: 190px; }
    .m-0300 .a-01100 .text {
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      text-transform: uppercase;
      color: white;
      text-transform: none; }
      @media (min-width: 992px) {
        .m-0300 .a-01100 .text {
          font-size: 12px; } }
    .m-0300 .a-01100 .icon {
      display: none; }
  .m-0300.hide {
    display: none; }

.m-0310 {
  padding: 25px 0 0; }
  @media (min-width: 992px) {
    .m-0310 {
      padding: 40px 0 35px; } }
  @media (min-width: 992px) {
    .m-0310 + .m-0310 {
      border-top: 1px solid #575656; } }
  .m-0310:last-child {
    padding-bottom: 45px; }
    @media (min-width: 992px) {
      .m-0310:last-child {
        padding-bottom: 70px; } }
  .m-0310__container {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (min-width: 768px) {
      .m-0310__container {
        flex-direction: row;
        padding: 0; } }
  @media (min-width: 768px) {
    .m-0310__img {
      max-width: 50%; } }
  @media (min-width: 992px) {
    .m-0310__img {
      max-width: 600px; } }
  .m-0310__headline {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 5px; }
    @media (min-width: 768px) {
      .m-0310__headline {
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 25px; } }
  .m-0310__text {
    padding-top: 5px; }
    @media (min-width: 768px) {
      .m-0310__text {
        max-width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center; } }
    @media (min-width: 992px) {
      .m-0310__text {
        max-width: 100%;
        padding-top: 0;
        padding-left: 110px; } }
    .m-0310__text p {
      margin: 0; }
  .m-0310 .a-00800 {
    margin-top: 10px; }
    @media (min-width: 992px) {
      .m-0310 .a-00800 {
        margin-top: 40px; } }

.m-0320 {
  position: relative; }
  .m-0320 .image-primitive px-lazy-image img {
    width: 100%;
    height: auto; }
    @media (min-width: 577px) {
      .m-0320 .image-primitive px-lazy-image img {
        height: 1024px;
        max-height: 1024px;
        object-fit: cover;
        object-position: bottom center; } }
    @media (min-width: 768px) {
      .m-0320 .image-primitive px-lazy-image img {
        height: 1322px;
        max-height: 1322px; } }
    @media (min-width: 992px) {
      .m-0320 .image-primitive px-lazy-image img {
        height: 811px;
        max-height: 811px; } }
    @media (min-width: 1244px) {
      .m-0320 .image-primitive px-lazy-image img {
        height: 940px;
        max-height: 940px; } }
  .m-0320 .content {
    position: absolute;
    top: 26px;
    left: 55px;
    right: 0;
    max-width: 800px;
    width: calc(100% - 110px);
    height: calc(100% - 152px); }
    .m-0320 .content.right {
      right: 55px;
      left: auto; }
      .m-0320 .content.right .m-0320_text {
        align-items: flex-end; }
      .m-0320 .content.right .m-0320_key_prices .prices .bulletLine {
        border-left: unset;
        border-right: 2px solid white;
        align-items: flex-end;
        padding: 0 40px 0 0; }
        @media (min-width: 577px) {
          .m-0320 .content.right .m-0320_key_prices .prices .bulletLine {
            max-width: unset; } }
      @media (min-width: 577px) {
        .m-0320 .content.right .m-0320_btn {
          left: unset;
          right: 0; } }
      @media (min-width: 768px) {
        .m-0320 .content.right {
          left: auto;
          right: 115px; } }
      @media (min-width: 992px) {
        .m-0320 .content.right {
          left: auto;
          right: 160px; } }
    @media (min-width: 768px) {
      .m-0320 .content {
        left: 115px;
        top: 70px;
        width: calc(100% - 230px);
        height: calc(100% - 140px); } }
    @media (min-width: 992px) {
      .m-0320 .content {
        left: 160px;
        width: calc(100% - 320px);
        height: calc(100% - 140px); } }
  .m-0320 .m-0320__envkv {
    width: 100%;
    position: static; }
    @media (min-width: 577px) {
      .m-0320 .m-0320__envkv {
        position: absolute;
        width: 100%;
        bottom: 0; } }
  .m-0320_key_facts .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0; }
    .m-0320_key_facts .row .bulletLine {
      height: 50px;
      width: 302px;
      padding: 0 0 0 20px;
      border-left: 2px solid white;
      margin: 15px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-left: calc(var(--bs-gutter-x) * 0.5); }
      @media (min-width: 577px) {
        .m-0320_key_facts .row .bulletLine {
          max-width: 47%; } }
      @media (min-width: 768px) {
        .m-0320_key_facts .row .bulletLine {
          padding: 0 0 0 40px; } }
      .m-0320_key_facts .row .bulletLine h4 {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 3px; }
        @media (min-width: 577px) {
          .m-0320_key_facts .row .bulletLine h4 {
            margin-bottom: 4px; } }
        @media (min-width: 768px) {
          .m-0320_key_facts .row .bulletLine h4 {
            font-size: 16px;
            line-height: 19px; } }
      .m-0320_key_facts .row .bulletLine p {
        color: white;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 0; }
  .m-0320_key_prices .prices .bulletLine {
    height: 50px;
    width: 302px;
    padding: 0 0 0 20px;
    border-left: 2px solid white;
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
    @media (min-width: 577px) {
      .m-0320_key_prices .prices .bulletLine {
        max-width: 47%; } }
    @media (min-width: 768px) {
      .m-0320_key_prices .prices .bulletLine {
        padding: 0 0 0 40px; } }
    .m-0320_key_prices .prices .bulletLine h4 {
      line-height: 20px;
      margin-bottom: 3px; }
      .m-0320_key_prices .prices .bulletLine h4 .short_text {
        font-size: 21px; }
      .m-0320_key_prices .prices .bulletLine h4 .price_text {
        font-size: 30px; }
        .m-0320_key_prices .prices .bulletLine h4 .price_text sup {
          font-size: 10px;
          line-height: 13px;
          color: #fff;
          border: 1px solid #fff;
          border-radius: 50%;
          width: 14px;
          height: 14px;
          display: inline-block;
          padding: 0 3px;
          position: relative;
          top: -16px;
          left: 1px; }
      @media (min-width: 577px) {
        .m-0320_key_prices .prices .bulletLine h4 {
          margin-bottom: 4px; } }
    .m-0320_key_prices .prices .bulletLine p {
      color: white;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 0; }
  .m-0320_text {
    display: flex;
    flex-direction: column; }
    .m-0320_text p,
    .m-0320_text h3,
    .m-0320_text h2,
    .m-0320_text .price {
      color: white; }
    
    .m-0320_text h2,
    .m-0320_text .price {
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-size: 23px;
      line-height: 30px; }
      @media (min-width: 577px) {
        
        .m-0320_text h2,
        .m-0320_text .price {
          font-size: 40px;
          line-height: 48px; } }
      @media (min-width: 768px) {
        
        .m-0320_text h2,
        .m-0320_text .price {
          font-size: 50px;
          line-height: 60px; } }
    .m-0320_text h3 {
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-size: 19px;
      line-height: 18px; }
      @media (min-width: 577px) {
        .m-0320_text h3 {
          font-size: 25px;
          line-height: 30px; } }
      @media (min-width: 768px) {
        .m-0320_text h3 {
          font-size: 30px;
          line-height: 36px; } }
    .m-0320_text p.copy {
      font-size: 15px;
      line-height: 21px; }
      @media (min-width: 577px) {
        .m-0320_text p.copy {
          line-height: 24px; } }
      @media (min-width: 768px) {
        .m-0320_text p.copy {
          font-size: 16px;
          line-height: 30px; } }
  @media (min-width: 577px) {
    .m-0320 .envkv_opened {
      bottom: 80px; } }
  .m-0320_btn {
    position: static; }
    @media (min-width: 577px) {
      .m-0320_btn {
        position: absolute;
        bottom: 40px;
        left: 0;
        right: unset; } }
    .m-0320_btn .a-01000 {
      margin-top: 20px;
      height: 35px;
      width: 250px;
      max-width: 100%; }
      @media (min-width: 577px) {
        .m-0320_btn .a-01000 {
          width: 220px;
          height: 42px; } }
      @media (min-width: 768px) {
        .m-0320_btn .a-01000 {
          width: 260px;
          height: 50px; } }
      .m-0320_btn .a-01000 .text,
      .m-0320_btn .a-01000 span {
        font-size: 12px;
        line-height: 14px;
        text-transform: none; }
        @media (min-width: 768px) {
          .m-0320_btn .a-01000 .text,
          .m-0320_btn .a-01000 span {
            font-size: 14px;
            line-height: 17px; } }

.m-0340 .error-message {
  color: #ed0000;
  margin: 10px 0; }

.m-0340 .slick-slide div {
  font-size: 0;
  line-height: 0; }

.m-0340 .slick-next {
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 15px;
  cursor: pointer;
  transform: translateY(-15px); }
  .m-0340 .slick-next::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    width: 30px;
    height: 30px;
    background-image: url(../Icons/arrow-next.svg);
    background-size: contain; }
  @media (min-width: 768px) {
    .m-0340 .slick-next {
      right: 30px;
      width: 45px;
      height: 45px;
      transform: translateY(-22px); }
      .m-0340 .slick-next::before {
        width: 45px;
        height: 45px; } }
  @media (min-width: 992px) {
    .m-0340 .slick-next {
      right: 20px;
      width: 60px;
      height: 60px;
      transform: translateY(-30px); }
      .m-0340 .slick-next::before {
        width: 60px;
        height: 60px; } }

.m-0340 .slick-prev {
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 15px;
  cursor: pointer;
  transform: translateY(-15px) rotate(180deg); }
  .m-0340 .slick-prev::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    width: 30px;
    height: 30px;
    background-image: url(../Icons/arrow-prev.svg);
    background-size: contain; }
  @media (min-width: 768px) {
    .m-0340 .slick-prev {
      width: 45px;
      height: 45px;
      left: 30px;
      transform: translateY(-22px) rotate(180deg); }
      .m-0340 .slick-prev::before {
        width: 45px;
        height: 45px; } }
  @media (min-width: 992px) {
    .m-0340 .slick-prev {
      width: 60px;
      height: 60px;
      left: 20px;
      transform: translateY(-30px) rotate(180deg); }
      .m-0340 .slick-prev::before {
        width: 60px;
        height: 60px; } }

.m-0350__wrapper {
  display: inline-block;
  position: relative;
  max-width: calc(100vw - 60px);
  width: 376px;
  height: 100%;
  background-color: #f9f9f9; }
  @media (min-width: 992px) {
    .m-0350__wrapper:hover {
      animation-name: m-0350scale;
      animation-duration: 0.4s;
      transform: scale(1.04, 1.04); } }
  .m-0350__wrapper a:visited {
    color: unset; }
  .m-0350__wrapper__image-wrapper {
    max-height: 282px; }
  .m-0350__wrapper__content {
    position: relative;
    padding: 12px 24px; }
    .m-0350__wrapper__content h6 {
      line-height: 24px;
      font-size: 21px;
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-weight: 400;
      margin-top: 0;
      height: 49px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 16px; }
      .m-0350__wrapper__content h6:first-child {
        margin: 0 0 5px 0;
        margin: 0;
        height: auto; }
    .m-0350__wrapper__content__vehicle-data-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #494948;
      font-size: 16px; }
      .m-0350__wrapper__content__vehicle-data-top .value {
        display: block;
        font-family: 'mmc-regular'; }
    .m-0350__wrapper__content hr {
      margin: 12px 0; }
    .m-0350__wrapper__content__vehicle-data-bottom {
      height: 325px;
      max-height: 325px;
      display: flex;
      flex-direction: column;
      color: #9e9e9e;
      font-size: 14px;
      font-family: 'mmc-regular'; }
      .m-0350__wrapper__content__vehicle-data-bottom .text {
        font-weight: 400;
        font-family: 'mmc-bold'; }
      .m-0350__wrapper__content__vehicle-data-bottom .text-group {
        display: flex;
        justify-content: space-between; }
      .m-0350__wrapper__content__vehicle-data-bottom .value {
        display: block; }
        .m-0350__wrapper__content__vehicle-data-bottom .value.double-value {
          display: flex;
          flex-direction: column;
          align-items: flex-end; }
    .m-0350__wrapper__content__price .productCardPrice {
      display: flex;
      justify-content: space-between;
      color: #e3000f;
      font-family: "mmc-bold", Helvetica, Arial, sans-serif;
      font-size: 20px;
      width: 100%;
      line-height: 1;
      font-weight: 700;
      vertical-align: baseline; }
      @media (min-width: 576px) {
        .m-0350__wrapper__content__price .productCardPrice {
          font-size: 24px; } }
      .m-0350__wrapper__content__price .productCardPrice .productCardRate {
        float: right;
        font-size: 24px; }

.m-0350__default-wrapper {
  display: inline-block;
  position: relative;
  max-width: calc(100vw - 60px);
  width: 376px;
  height: 100%; }
  .m-0350__default-wrapper__image-wrapper {
    max-height: 282px; }
  .m-0350__default-wrapper__content {
    padding: 12px 24px;
    position: relative; }
    .m-0350__default-wrapper__content h3 {
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-size: 25px;
      font-weight: 400; }
    .m-0350__default-wrapper__content .copy {
      margin: 10px 0; }
      .m-0350__default-wrapper__content .copy p {
        font-family: "mmc-regular", Helvetica, Arial, sans-serif;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400; }
    .m-0350__default-wrapper__content .cta-wrapper {
      width: 250px; }
      .m-0350__default-wrapper__content .cta-wrapper a {
        height: 42px; }
      .m-0350__default-wrapper__content .cta-wrapper .text {
        font-size: 12px;
        text-transform: none; }

.m-0350 .mobile {
  display: none; }

.o-010 {
  position: relative;
  flex: 0 0 100%;
  max-width: 100%; }
  .o-010__container {
    width: 100%;
    max-width: none; }
    @media (min-width: 768px) {
      .o-010__container {
        padding: 0; } }
    @media (min-width: 992px) {
      .o-010__container {
        max-width: 960px;
        padding: 12px; } }
    @media (min-width: 1244px) {
      .o-010__container {
        max-width: 1220px; } }
  .o-010 .content {
    background-color: white;
    width: 100%;
    border-bottom: 1px solid #cccccc; }
    @media (max-width: 1072px) {
      .o-010 .content {
        left: 90px; } }
    @media (min-width: 992px) {
      .o-010 .content {
        position: absolute;
        width: 411px;
        border-bottom-color: transparent;
        top: 215px; } }
    .o-010 .content--a {
      padding: 25px 25px 20px 25px; }
      @media (min-width: 992px) {
        .o-010 .content--a {
          padding: 25px; } }
  .o-010 hr {
    border-top-color: #cccccc;
    margin: 20px -25px; }
  .o-010 .m-0020 .a-00500 {
    text-align: center;
    height: 180px;
    overflow: hidden; }
    @media (min-width: 576px) {
      .o-010 .m-0020 .a-00500 {
        height: 250px; } }
    @media (min-width: 768px) {
      .o-010 .m-0020 .a-00500 {
        height: 320px; } }
    @media (min-width: 992px) {
      .o-010 .m-0020 .a-00500 {
        height: 600px; } }
    .o-010 .m-0020 .a-00500 img.img-fluid {
      display: inline-block;
      position: relative;
      width: auto;
      transform: translateX(-50%);
      margin-left: calc(50%);
      max-width: none;
      height: 100%; }
      @media (min-width: 1920px) {
        .o-010 .m-0020 .a-00500 img.img-fluid {
          width: 100%; } }

@media all and (min-width: 1920px) and (-ms-high-contrast: none) {
  .o-010 .m-0020 .a-00500 {
    /* IE11 */ }
    .o-010 .m-0020 .a-00500 *::-ms-backdrop, .o-010 .m-0020 .a-00500 img.img-fluid {
      max-height: none;
      height: auto; } }
  .o-010 .m-0020 .wrapper {
    position: static; }
    @media (min-width: 992px) {
      .o-010 .m-0020 .wrapper .a-00300 {
        padding-left: 0;
        left: auto;
        top: 34px;
        bottom: unset; } }
  @media (min-width: 992px) and (min-width: 992px) {
    .o-010 .m-0020 .wrapper .a-00300 {
      top: 71px; } }
    @media (max-width: 1072px) {
      .o-010 .m-0020 .wrapper .wrapper-headline {
        left: 90px; } }
  .o-010 .m-0140 {
    margin-bottom: 15px; }
  .o-010 .a-02500 {
    margin-bottom: 15px; }
  .o-010 .a-00900 {
    max-width: 100%; }
  @media (min-width: 992px) {
    .o-010--b .m-0020 .wrapper .a-00300 {
      top: 80px; } }

.o-020 {
  background-color: #E4E4E5; }
  @media (max-width: 992px) {
    .o-020 .container {
      max-width: 100%; } }
  .o-020__headline {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 5px; }
    .o-020__headline h2, .o-020__headline .price {
      margin: 0; }
    @media (min-width: 992px) {
      .o-020__headline {
        margin-top: 52px;
        margin-bottom: 20px; } }
  .o-020__moreWrapper {
    margin-top: 0;
    margin-bottom: 20px; }
  .o-020__sliderWrapper {
    padding: 0; }
    .o-020__sliderWrapper .slick-list {
      padding: 0 27px 0 9px !important; }
      @media (min-width: 992px) {
        .o-020__sliderWrapper .slick-list {
          padding: 2px !important; }
          .o-020__sliderWrapper .slick-list.overflow {
            overflow: visible; } }
    .o-020__sliderWrapper .slick-dots {
      height: 35px;
      bottom: -35px;
      display: flex;
      justify-content: center; }
      .o-020__sliderWrapper .slick-dots li {
        width: 7px;
        height: 7px;
        margin: 5px; }
        .o-020__sliderWrapper .slick-dots li.slick-active {
          width: 9px;
          height: 9px;
          margin: 4px 3px; }
        .o-020__sliderWrapper .slick-dots li button {
          width: 100%;
          height: 100%; }
    .o-020__sliderWrapper .slick-arrow {
      height: 40px;
      width: 40px;
      display: none !important;
      z-index: 10; }
      @media (min-width: 992px) {
        .o-020__sliderWrapper .slick-arrow {
          display: inline-block !important; } }
      .o-020__sliderWrapper .slick-arrow:before {
        color: #B1B2B3;
        content: ''; }
      .o-020__sliderWrapper .slick-arrow:hover {
        background-color: #E4E4E5; }
    .o-020__sliderWrapper .slick-slide.opacity {
      opacity: 0; }
      .o-020__sliderWrapper .slick-slide.opacity.slick-active {
        opacity: 1; }
  .o-020__prevArrow img, .o-020__prevArrow svg, .o-020__nextArrow img, .o-020__nextArrow svg {
    height: 40px;
    width: auto; }
    .o-020__prevArrow img g, .o-020__prevArrow img path, .o-020__prevArrow img polygon, .o-020__prevArrow img rect, .o-020__prevArrow svg g, .o-020__prevArrow svg path, .o-020__prevArrow svg polygon, .o-020__prevArrow svg rect, .o-020__nextArrow img g, .o-020__nextArrow img path, .o-020__nextArrow img polygon, .o-020__nextArrow img rect, .o-020__nextArrow svg g, .o-020__nextArrow svg path, .o-020__nextArrow svg polygon, .o-020__nextArrow svg rect {
      fill: #9E9FA0 !important; }
  .o-020__prevArrow {
    left: -60px !important; }
  .o-020__nextArrow {
    right: -60px !important; }
  .o-020__slider {
    margin-bottom: 45px !important;
    display: flex; }
  .o-020__sliderItem {
    padding: 10px 6px;
    flex: 0 0 90%; }
    @media (min-width: 992px) {
      .o-020__sliderItem {
        flex: 0 0 33%; } }
    .o-020__sliderItem:focus, .o-020__sliderItem:active {
      border: 0 solid transparent !important;
      outline: none; }
  .o-020 .m-0060 {
    min-height: 100%; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .o-020 .m-0060 .a-00600 {
        max-height: 270px; } }
  @media all and (-ms-high-contrast: none) and (min-width: 576px), (-ms-high-contrast: active) and (min-width: 576px) {
    .o-020 .m-0060 .a-00600 {
      max-height: 170px; } }
  @media all and (-ms-high-contrast: none) and (min-width: 768px), (-ms-high-contrast: active) and (min-width: 768px) {
    .o-020 .m-0060 .a-00600 {
      max-height: 275px; } }
  @media all and (-ms-high-contrast: none) and (min-width: 992px), (-ms-high-contrast: active) and (min-width: 992px) {
    .o-020 .m-0060 .a-00600 {
      max-height: 185px; } }
  @media all and (-ms-high-contrast: none) and (min-width: 1244px), (-ms-high-contrast: active) and (min-width: 1244px) {
    .o-020 .m-0060 .a-00600 {
      max-height: 235px; } }
    .o-020 .m-0060__image-wrapper {
      padding-bottom: 59%;
      overflow: hidden; }
      .o-020 .m-0060__image-wrapper .a-00600 {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
        min-height: 100%; }

.slick-dots-container {
  max-width: 200px;
  overflow: hidden;
  bottom: -15px;
  margin: 0 auto; }
  .slick-dots-container .slick-dots {
    display: block;
    white-space: nowrap;
    position: relative;
    bottom: 0; }
    .slick-dots-container .slick-dots li {
      width: 7px;
      transition: all .2s ease-in-out; }
      .slick-dots-container .slick-dots li.small {
        transform: scale(0.5); }

.o-022 {
  background-color: white; }
  @media (min-width: 992px) {
    .o-022__sliderDots--3 {
      display: none !important; } }
  @media (min-width: 768px) {
    .o-022__sliderDots--2 {
      display: none !important; } }
  .o-022__sliderDots--1, .o-022__sliderDots--0 {
    display: none !important; }
  .o-022 .o-020__headline {
    text-align: left; }
    @media (min-width: 992px) {
      .o-022 .o-020__headline {
        margin-top: 105px;
        margin-bottom: 80px; } }
    .o-022 .o-020__headline h2, .o-022 .o-020__headline .price {
      margin-bottom: 8px; }
      @media (min-width: 992px) {
        .o-022 .o-020__headline h2, .o-022 .o-020__headline .price {
          margin-bottom: 8px; } }
  .o-022 .o-020__sliderWrapper {
    padding: 0 10px; }
    @media (min-width: 992px) {
      .o-022 .o-020__sliderWrapper {
        padding: 0 48px; } }
    .o-022 .o-020__sliderWrapper .slick-arrow {
      height: 20px;
      width: 20px;
      display: inline-block !important; }
      @media (min-width: 992px) {
        .o-022 .o-020__sliderWrapper .slick-arrow {
          height: 40px;
          width: 40px;
          display: inline-block !important; } }
  .o-022 .o-020__prevArrow img, .o-022 .o-020__prevArrow svg, .o-022 .o-020__nextArrow img, .o-022 .o-020__nextArrow svg {
    vertical-align: top;
    height: 20px; }
    .o-022 .o-020__prevArrow img g, .o-022 .o-020__prevArrow img path, .o-022 .o-020__prevArrow img polygon, .o-022 .o-020__prevArrow img rect, .o-022 .o-020__prevArrow svg g, .o-022 .o-020__prevArrow svg path, .o-022 .o-020__prevArrow svg polygon, .o-022 .o-020__prevArrow svg rect, .o-022 .o-020__nextArrow img g, .o-022 .o-020__nextArrow img path, .o-022 .o-020__nextArrow img polygon, .o-022 .o-020__nextArrow img rect, .o-022 .o-020__nextArrow svg g, .o-022 .o-020__nextArrow svg path, .o-022 .o-020__nextArrow svg polygon, .o-022 .o-020__nextArrow svg rect {
      fill: black !important; }
    @media (min-width: 992px) {
      .o-022 .o-020__prevArrow img, .o-022 .o-020__prevArrow svg, .o-022 .o-020__nextArrow img, .o-022 .o-020__nextArrow svg {
        height: 40px; } }
  .o-022 .o-020__prevArrow {
    left: -5px !important; }
    @media (min-width: 992px) {
      .o-022 .o-020__prevArrow {
        left: -56px !important; } }
  .o-022 .o-020__nextArrow {
    right: -5px !important; }
    @media (min-width: 992px) {
      .o-022 .o-020__nextArrow {
        right: -56px !important; } }
  .o-022 .o-020__sliderItem {
    padding: 15px 10px; }
    .o-022 .o-020__sliderItem:focus, .o-022 .o-020__sliderItem:active {
      border: 0 solid transparent !important;
      outline: none; }
  .o-022 .m-0065 {
    min-height: 100%; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .o-022 .m-0065 .a-00600 {
        max-height: 270px; } }
  @media all and (-ms-high-contrast: none) and (min-width: 576px), (-ms-high-contrast: active) and (min-width: 576px) {
    .o-022 .m-0065 .a-00600 {
      max-height: 170px; } }
  @media all and (-ms-high-contrast: none) and (min-width: 768px), (-ms-high-contrast: active) and (min-width: 768px) {
    .o-022 .m-0065 .a-00600 {
      max-height: 275px; } }
  @media all and (-ms-high-contrast: none) and (min-width: 992px), (-ms-high-contrast: active) and (min-width: 992px) {
    .o-022 .m-0065 .a-00600 {
      max-height: 185px; } }
  @media all and (-ms-high-contrast: none) and (min-width: 1244px), (-ms-high-contrast: active) and (min-width: 1244px) {
    .o-022 .m-0065 .a-00600 {
      max-height: 235px; } }
    .o-022 .m-0065__image-wrapper {
      padding-bottom: 65.15%; }
      .o-022 .m-0065__image-wrapper .a-00600 {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
        min-height: 100%; }
    @media (min-width: 992px) {
      .o-022 .m-0065 .a-00700--c .a-00700__easing {
        background-color: #575656; } }

@media (min-width: 992px) and (min-width: 992px) {
  .o-022 .m-0065 .a-00700--c.a-00700__wrapper:hover .a-00700 svg g, .o-022 .m-0065 .a-00700--c.a-00700__wrapper:hover .a-00700 svg path, .o-022 .m-0065 .a-00700--c.a-00700__wrapper:hover .a-00700 svg polygon {
    fill: white !important; } }

@media (min-width: 992px) and (min-width: 992px) {
  .o-022 .m-0065 .a-00700--c.a-00700__wrapper:hover .a-00700 .arrow, .o-022 .m-0065 .a-00700--c.a-00700__wrapper:hover .a-00700 .text {
    color: white; } }

.o-021 {
  padding: 0 15px 0 0; }
  @media (min-width: 768px) {
    .o-021 {
      padding: 70px 0;
      margin-bottom: 0 !important; } }
  .o-021__wrapper {
    padding: 0; }
    @media (max-width: 767px) {
      .o-021__wrapper .button-prev {
        display: none; } }
    @media (max-width: 767px) {
      .o-021__wrapper .button-next {
        display: none; } }
    .o-021__wrapper .swiper-container .swiper-wrapper {
      padding: 40px 0; }
      .o-021__wrapper .swiper-container .swiper-wrapper .swiper-slide {
        overflow: hidden !important; }
    .o-021__wrapper .swiper-container .swiper-pagination {
      height: 0;
      bottom: 35px;
      display: flex;
      justify-content: center; }
      @media (min-width: 768px) {
        .o-021__wrapper .swiper-container .swiper-pagination {
          bottom: 35px; } }
      .o-021__wrapper .swiper-container .swiper-pagination span {
        width: 8px;
        height: 8px;
        margin: 5px !important; }
        .o-021__wrapper .swiper-container .swiper-pagination span.swiper-pagination-bullet {
          background-color: white;
          box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.65); }
        .o-021__wrapper .swiper-container .swiper-pagination span.swiper-pagination-bullet-active {
          width: 9px;
          height: 9px;
          margin: 4px 3px;
          background-color: #ed0000 !important;
          border: 2px solid #ed0000 !important; }
  .o-021__slide {
    padding: 10px 6px;
    height: inherit !important; }
    .o-021__slide:focus, .o-021__slide:active {
      border: 0 solid transparent !important;
      outline: none; }
  .o-021__wrapperSingle {
    padding: 10px 6px; }
    .o-021__wrapperSingle px-m0071 {
      height: auto; }
    .o-021__wrapperSingle .m-0071 {
      height: auto; }
  @media (max-width: 767px) {
    .o-021 .slick-list {
      overflow: visible; } }
  .o-021 .slick-track {
    display: flex !important; }
  .o-021 .slick-dots {
    height: 35px;
    bottom: -35px;
    display: flex;
    justify-content: center; }
    @media (min-width: 768px) {
      .o-021 .slick-dots {
        bottom: 35px; } }
    .o-021 .slick-dots li {
      width: 7px;
      height: 7px;
      margin: 5px; }
      .o-021 .slick-dots li.slick-active {
        width: 9px;
        height: 9px;
        margin: 4px 3px; }
      .o-021 .slick-dots li button {
        width: 100%;
        height: 100%;
        box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.35); }
  .o-021 .slick-arrow {
    height: 40px;
    width: 40px;
    display: none !important;
    z-index: 10; }
    @media (min-width: 992px) {
      .o-021 .slick-arrow {
        display: inline-block !important; } }
    .o-021 .slick-arrow:before {
      color: #B1B2B3;
      content: ""; }
  .o-021 .slick-slide.opacity {
    opacity: 0; }
    .o-021 .slick-slide.opacity.slick-active {
      opacity: 1; }
  .o-021__prevArrow img,
  .o-021__prevArrow svg, .o-021__nextArrow img,
  .o-021__nextArrow svg {
    height: 40px;
    width: auto; }
    .o-021__prevArrow img g, .o-021__prevArrow img path, .o-021__prevArrow img polygon, .o-021__prevArrow img rect,
    .o-021__prevArrow svg g,
    .o-021__prevArrow svg path,
    .o-021__prevArrow svg polygon,
    .o-021__prevArrow svg rect, .o-021__nextArrow img g, .o-021__nextArrow img path, .o-021__nextArrow img polygon, .o-021__nextArrow img rect,
    .o-021__nextArrow svg g,
    .o-021__nextArrow svg path,
    .o-021__nextArrow svg polygon,
    .o-021__nextArrow svg rect {
      fill: #9E9FA0 !important; }
  .o-021__prevArrow {
    left: -60px !important; }
  .o-021__nextArrow {
    right: -60px !important; }

.o-030 {
  position: relative; }
  .o-030-white {
    color: white; }
  .o-030-black {
    color: black; }
  .o-030.full-width {
    width: 100vw;
    left: 50%;
    transform: translateX(-50%); }
  .o-030-images {
    position: relative;
    margin: 0 auto; }
    @media screen and (min-width: 992px) and (max-width: 1279px) {
      .o-030-images {
        min-height: 430px;
        width: 100%;
        overflow-x: hidden; }
        .o-030-images .desktop {
          min-height: 430px; }
          .o-030-images .desktop img {
            min-height: 430px;
            object-fit: cover; } }
    .o-030-images img {
      display: block;
      margin: 0 auto; }
  .o-030-container {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto; }
    @media (max-width: 992px) {
      .o-030-container {
        justify-content: center; } }
    @media screen and (min-width: 992px) and (max-width: 1279px) {
      .o-030-container {
        justify-content: center; } }
  .o-030-countdown {
    display: flex;
    flex-direction: column;
    margin-top: -10px;
    margin-right: 150px; }
    @media (max-width: 992px) {
      .o-030-countdown {
        margin: 0 18px; } }
    @media (max-width: 767px) {
      .o-030-countdown {
        margin-top: -5px; } }
    @media screen and (min-width: 992px) and (max-width: 1279px) {
      .o-030-countdown {
        margin-right: auto;
        margin-left: auto; } }
    .o-030-countdown-headline {
      display: flex;
      justify-content: center;
      align-items: center; }
      .o-030-countdown-headline-wrapper {
        display: flex;
        flex-direction: column; }
    .o-030-countdown-timer {
      display: grid;
      grid-template-columns: 1fr 72px 1fr 72px 1fr 72px 1fr 72px 1fr;
      padding: 24px 0 40px 0; }
      @media (max-width: 767px) {
        .o-030-countdown-timer {
          padding: 16px 0 25px 0;
          grid-template-columns: 1fr 17px 1fr 17px 1fr 17px 1fr 17px 1fr; } }
      .o-030-countdown-timer-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .o-030-countdown-timer-item-label {
          font-family: "mmc-regular", Helvetica, Arial, sans-serif;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          margin-top: 7px; }
          @media (max-width: 767px) {
            .o-030-countdown-timer-item-label {
              margin-top: 4px;
              font-size: 9px;
              line-height: 11px; } }
      .o-030-countdown-timer-item-value, .o-030-countdown-timer-divider {
        font-family: "mmc-regular", Helvetica, Arial, sans-serif;
        font-size: 60px;
        line-height: 72px;
        text-align: center; }
        @media (max-width: 767px) {
          .o-030-countdown-timer-item-value, .o-030-countdown-timer-divider {
            font-size: 21px;
            line-height: 25px; } }
  .o-030-goal {
    display: none;
    padding: 30px 0;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    max-width: 654px;
    margin: 0 auto; }
    @media (max-width: 992px) {
      .o-030-goal {
        font-size: 12px;
        line-height: 20px; } }
    @media (max-width: 767px) {
      .o-030-goal {
        padding: 15px 0; } }
  .o-030-cta {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 250px;
    padding: 15px;
    margin: 0 auto;
    position: relative;
    cursor: pointer; }
    .o-030-cta-white {
      border: 2px solid white; }
      .o-030-cta-white span {
        color: white; }
    .o-030-cta-black {
      border: 2px solid black; }
      .o-030-cta-black span {
        color: black; }
    @media (max-width: 992px) {
      .o-030-cta {
        min-width: 136px;
        padding: 9px; } }
    .o-030-cta span {
      font-size: 16px;
      line-height: 19px;
      text-align: center; }
      @media (max-width: 992px) {
        .o-030-cta span {
          font-size: 12px;
          line-height: 14px; } }
    .o-030-cta-icon {
      display: flex;
      align-items: center;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%); }
      @media (max-width: 992px) {
        .o-030-cta-icon {
          right: 10px; } }
      @media (max-width: 992px) {
        .o-030-cta-icon svg {
          width: 14px;
          height: 14px; } }
  .o-030-font {
    margin: 0;
    padding: 0;
    line-height: normal;
    font-weight: normal; }
    @media (max-width: 767px) {
      .o-030-font-mobile-12 {
        font-size: 12px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-14 {
        font-size: 14px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-16 {
        font-size: 16px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-18 {
        font-size: 18px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-20 {
        font-size: 20px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-22 {
        font-size: 22px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-24 {
        font-size: 24px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-26 {
        font-size: 26px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-28 {
        font-size: 28px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-30 {
        font-size: 30px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-32 {
        font-size: 32px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-34 {
        font-size: 34px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-36 {
        font-size: 36px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-38 {
        font-size: 38px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-40 {
        font-size: 40px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-42 {
        font-size: 42px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-44 {
        font-size: 44px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-46 {
        font-size: 46px; } }
    @media (max-width: 767px) {
      .o-030-font-mobile-48 {
        font-size: 48px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-12 {
        font-size: 12px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-14 {
        font-size: 14px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-16 {
        font-size: 16px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-18 {
        font-size: 18px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-20 {
        font-size: 20px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-22 {
        font-size: 22px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-24 {
        font-size: 24px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-26 {
        font-size: 26px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-28 {
        font-size: 28px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-30 {
        font-size: 30px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-32 {
        font-size: 32px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-34 {
        font-size: 34px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-36 {
        font-size: 36px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-38 {
        font-size: 38px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-40 {
        font-size: 40px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-42 {
        font-size: 42px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-44 {
        font-size: 44px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-46 {
        font-size: 46px; } }
    @media (min-width: 768px) {
      .o-030-font-tablet-48 {
        font-size: 48px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-12 {
        font-size: 12px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-14 {
        font-size: 14px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-16 {
        font-size: 16px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-18 {
        font-size: 18px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-20 {
        font-size: 20px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-22 {
        font-size: 22px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-24 {
        font-size: 24px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-26 {
        font-size: 26px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-28 {
        font-size: 28px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-30 {
        font-size: 30px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-32 {
        font-size: 32px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-34 {
        font-size: 34px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-36 {
        font-size: 36px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-38 {
        font-size: 38px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-40 {
        font-size: 40px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-42 {
        font-size: 42px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-44 {
        font-size: 44px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-46 {
        font-size: 46px; } }
    @media (min-width: 992px) {
      .o-030-font-desktop-48 {
        font-size: 48px; } }

.o-040__background {
  background-color: #575656;
  padding-bottom: 12px; }

.o-040__cars-count {
  margin-top: 90px;
  font-size: 16px;
  padding: 0 15px; }

.o-040__desktop-wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 22px 1fr 22px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 22px;
  padding: 22px 5px 0 5px;
  /* IE11 Hack at the bottom */ }
  .o-040__desktop-wrapper--top {
    padding-top: 0; }

.o-040__more-filter-wrapper {
  padding: 0 5px; }
  .o-040__more-filter-wrapper button {
    padding: 25px 0;
    background: none;
    border: 0;
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: black;
    font-weight: normal;
    line-height: 16px; }
    .o-040__more-filter-wrapper button svg {
      width: 16px;
      position: relative;
      top: -2px;
      left: 2px; }
  .o-040__more-filter-wrapper .more-cars .isExpanded {
    display: inline-block; }
  .o-040__more-filter-wrapper .more-cars .isNotExpanded {
    display: none; }
  .o-040__more-filter-wrapper .more-cars[aria-expanded="true"] .isExpanded {
    display: none; }
  .o-040__more-filter-wrapper .more-cars[aria-expanded="true"] .isNotExpanded {
    display: inline-block; }
  .o-040__more-filter-wrapper .more-cars[aria-expanded="true"] svg {
    transform: rotate(180deg); }

.o-040 .m-0120__arrow {
  margin-right: 30px; }

.o-040--placeholder .o-040__desktop-wrapper {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  /* IE11 Hack at the bottom */ }
  @media (min-width: 576px) {
    .o-040--placeholder .o-040__desktop-wrapper {
      -ms-grid-columns: 1fr 22px 1fr;
      grid-template-columns: 1fr 1fr;
      /* IE11 Hack at the bottom */ } }
  @media (min-width: 992px) {
    .o-040--placeholder .o-040__desktop-wrapper {
      -ms-grid-columns: 1fr 22px 1fr 22px 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      /* IE11 Hack at the bottom */ } }

.o-040--placeholder .m-0060 {
  animation: none !important;
  transform: scale(1) !important; }
  .o-040--placeholder .m-0060:hover {
    animation: none !important;
    transform: scale(1) !important; }
  .o-040--placeholder .m-0060 .a-00800 {
    max-width: 100%; }

/* IE11 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .o-040__desktop-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -11px;
    margin-right: -22px; }
    .o-040__desktop-wrapper px-m0060 {
      max-width: 100%;
      flex: 1 0 100%;
      padding: 0 11px 22px; }
      .o-040__desktop-wrapper px-m0060 .m-0060 {
        min-height: 100%;
        height: 100% !important; }
      .o-040__desktop-wrapper px-m0060 .m-0060__image-wrapper {
        flex: 0 0 auto; } }
  @media screen and (-ms-high-contrast: active) and (min-width: 576px), (-ms-high-contrast: none) and (min-width: 576px) {
    .o-040__desktop-wrapper px-m0060 {
      max-width: 50%;
      flex: 1 0 50%; } }
  @media screen and (-ms-high-contrast: active) and (min-width: 992px), (-ms-high-contrast: none) and (min-width: 992px) {
    .o-040__desktop-wrapper px-m0060 {
      max-width: 33%;
      flex: 1 0 33%; } }

.o-050.is-inactive {
  opacity: 0.4; }

.o-050 + .o-050 {
  margin-top: 25px; }

.o-050__tirelabel {
  font-size: 12px;
  color: #000 !important;
  display: block;
  margin-top: 10px;
  margin-left: 12px; }
  .o-050__tirelabel svg {
    width: 11px;
    height: 10px;
    margin-left: 4px;
    top: -1px;
    position: relative; }

.o-050__car-model {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: black; }
  @media (min-width: 992px) {
    .o-050__car-model {
      font-size: 28px;
      line-height: 38px; } }

.o-050__car-model-description {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto; }
  @media (min-width: 992px) {
    .o-050__car-model-description {
      font-size: 16px; } }

.o-050__emission-wrapper {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .o-050__emission-wrapper > .col-lg-6 {
      min-height: 1px; }
      .o-050__emission-wrapper > .col-lg-6 > img {
        width: 100%;
        max-height: 150px;
        min-height: 1px; } }
  .o-050__emission-wrapper strong, .o-050__emission-wrapper b {
    font-family: 'mmc-bold', Helvetica, Arial, sans-serif;
    font-weight: normal; }

.o-050__emission-button {
  width: 42px;
  min-width: 42px;
  height: 42px;
  background-color: #ED0000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .o-050__emission-button svg {
    height: 19px;
    width: 15px; }
    .o-050__emission-button svg path {
      fill: white !important; }

.o-050__header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%; }

.o-050__modal-headline {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: black; }

.o-050__modal-close {
  background-color: #ED0000 !important;
  border-radius: 50%;
  opacity: 1 !important;
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: -21px;
  right: -21px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .o-050__modal-close svg {
    width: 13px;
    height: 13px; }
    .o-050__modal-close svg polyline {
      fill: white !important; }

.o-050__rate-info {
  margin-top: 7px;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #575656;
  color: black; }
  @media (min-width: 992px) {
    .o-050__rate-info {
      font-size: 14px; } }
  .o-050__rate-info a {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #575656;
    color: #ED0000; }
    @media (min-width: 992px) {
      .o-050__rate-info a {
        font-size: 14px; } }

.o-050 .text-content-wrapper {
  padding: 20px 12px 25px 12px; }
  @media (min-width: 992px) {
    .o-050 .text-content-wrapper {
      display: flex;
      height: 100%;
      max-width: 360px;
      margin: 0 auto;
      padding: 20px 0 60px;
      flex-direction: column;
      justify-content: center; } }

.o-050 h1 {
  margin-bottom: 3px; }

.o-050 .amount {
  margin-bottom: 0;
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px; }
  @media (min-width: 992px) {
    .o-050 .amount {
      font-size: 24px; } }
  .o-050 .amount--red {
    color: #ED0000; }
  .o-050 .amount sup {
    font-size: 10px;
    top: -8px; }
    @media (min-width: 992px) {
      .o-050 .amount sup {
        top: -15px; } }
  .o-050 .amount .o-050__finance-price, .o-050 .amount .o-050__finance-price a {
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px; }
    @media (min-width: 992px) {
      .o-050 .amount .o-050__finance-price, .o-050 .amount .o-050__finance-price a {
        font-size: 24px; } }

.o-050 .addition {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #575656;
  color: #ED0000; }
  @media (min-width: 992px) {
    .o-050 .addition {
      font-size: 14px; } }
  .o-050 .addition--rate {
    color: #575656; }

.o-050 .a-00800 {
  margin-top: 16px; }
  @media (min-width: 992px) {
    .o-050 .a-00800 {
      margin-top: 36px;
      max-width: 100%; } }

.o-050 .a-00800--c {
  max-width: 100%; }
  @media (min-width: 992px) {
    .o-050 .a-00800--c {
      margin-top: 20px; } }

.o-050 .verticalLine {
  border-left: 1px solid #E4E4E5; }

.o-050 .priceWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-wrap: nowrap; }
  @media (min-width: 992px) {
    .o-050 .priceWrapper {
      margin-top: 9px; } }

.o-050 .price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 46%; }
  .o-050 .price--new-car .amount {
    color: #ED0000; }

.o-050__reservation .a-00800 {
  background-color: #ED0000; }

@media (min-width: 992px) {
  .o-050__testdrive .a-00800 {
    margin-top: 20px; } }

.o-060__emission-wrapper {
  padding: 0 60px; }
  .o-060__emission-wrapper strong, .o-060__emission-wrapper b {
    font-family: 'mmc-bold', Helvetica, Arial, sans-serif;
    font-weight: normal; }

@media (min-width: 992px) {
  .o-060__emission-text {
    margin-top: 21px;
    font-size: 16px; } }

.o-060__emission-image {
  width: 100%;
  max-width: 360px;
  position: relative;
  left: -6px; }
  @media (min-width: 992px) {
    .o-060__emission-image {
      width: auto;
      left: 0;
      max-width: 100%; } }

.o-070 {
  position: relative;
  flex: 0 0 100%;
  max-width: 100%; }
  .o-070__tab-content-wrapper {
    display: flex; }
    @media (min-width: 992px) {
      .o-070__tab-content-wrapper {
        display: block; } }
  .o-070__tab-content {
    display: none;
    width: 100%;
    padding: 15px; }
    @media (min-width: 992px) {
      .o-070__tab-content {
        display: block;
        padding: 0 15px; } }
    .o-070__tab-content.active {
      display: block; }
  .o-070__category-headline {
    margin-top: 30px; }
  .o-070__sections-desktop {
    margin-top: 50px; }
  @media (min-width: 992px) {
    .o-070 .a-01400 {
      display: none; } }
  @media (min-width: 768px) {
    .o-070 px-a02700 {
      padding-right: 5px; } }
  @media (min-width: 992px) {
    .o-070 px-a02700 {
      text-align: left !important; } }
  @media (min-width: 992px) {
    .o-070 .m-0190 {
      margin-bottom: 1rem; } }
  .o-070 .m-0160 .cars-detail__headline {
    margin-top: 25px;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: normal; }
    @media (min-width: 992px) {
      .o-070 .m-0160 .cars-detail__headline {
        font-size: 20px; } }

.o-080 {
  padding: 20px 0 25px;
  background-color: #E4E4E5; }
  @media (min-width: 992px) {
    .o-080 {
      padding: 68px 0 80px; } }
  .o-080 .slick-arrow {
    height: 40px;
    width: 40px; }
    .o-080 .slick-arrow:before {
      height: 40px;
      width: 40px;
      font-size: 40px;
      line-height: 38px;
      color: #9E9FA0; }
  .o-080 .slick-next:before, .o-080 .slick-prev:before {
    content: ''; }
  .o-080 .slick-next img, .o-080 .slick-next svg, .o-080 .slick-prev img, .o-080 .slick-prev svg {
    height: 100%;
    width: auto;
    vertical-align: top; }
    .o-080 .slick-next img g, .o-080 .slick-next img path, .o-080 .slick-next img polygon, .o-080 .slick-next img rect, .o-080 .slick-next svg g, .o-080 .slick-next svg path, .o-080 .slick-next svg polygon, .o-080 .slick-next svg rect, .o-080 .slick-prev img g, .o-080 .slick-prev img path, .o-080 .slick-prev img polygon, .o-080 .slick-prev img rect, .o-080 .slick-prev svg g, .o-080 .slick-prev svg path, .o-080 .slick-prev svg polygon, .o-080 .slick-prev svg rect {
      fill: #9E9FA0 !important; }
  .o-080 .slick-next {
    right: -85px; }
  .o-080 .slick-prev {
    left: -85px; }
  .o-080 .slick-list {
    transition: height 1s;
    padding: 0 27px 0 9px !important; }
    @media (min-width: 992px) {
      .o-080 .slick-list {
        padding: 0 !important; } }
    .o-080 .slick-list .slick-slide:focus, .o-080 .slick-list .slick-slide a {
      outline: none; }
  .o-080 .slick-dots {
    height: 40px;
    bottom: -40px;
    display: flex;
    justify-content: center; }
    .o-080 .slick-dots li {
      width: 7px;
      height: 7px;
      margin: 5px; }
      .o-080 .slick-dots li.slick-active {
        width: 9px;
        height: 9px;
        margin: 4px 3px; }
      .o-080 .slick-dots li button {
        width: 100%;
        height: 100%; }
  .o-080 .sliderCol {
    padding: 0; }
    @media (min-width: 992px) {
      .o-080 .sliderCol {
        padding: 0 15px; } }

.o-090 {
  background-color: black; }
  @media (min-width: 992px) {
    .o-090 {
      padding-top: 75px; } }
  .o-090__headline {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: black;
    color: white; }
    @media (min-width: 992px) {
      .o-090__headline {
        font-size: 28px;
        line-height: 38px; } }
  .o-090 .column {
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0; }
    .o-090 .column__second .a-03000 {
      margin-top: 20px; }
    @media (min-width: 992px) {
      .o-090 .column__third {
        padding-top: 38px; } }
  .o-090__contact-column .a-01300 .cta {
    font-size: 14px; }
  .o-090__spacer {
    margin-top: 20px; }
  .o-090 .headline-col {
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 992px) {
      .o-090 .headline-col {
        margin-top: 0;
        text-align: left;
        padding-left: 25px;
        padding-right: 25px;
        font-size: 20px;
        line-height: 20px; } }
  @media (min-width: 992px) {
    .o-090 .a-03000 {
      display: none; } }
  .o-090 .a-00800, .o-090 .a-01000 {
    width: auto;
    max-width: 100%;
    margin-top: 5px;
    margin-left: 25px;
    margin-right: 25px; }
    @media (min-width: 992px) {
      .o-090 .a-00800, .o-090 .a-01000 {
        margin-top: 10px; } }
  .o-090 .a-01000 {
    margin-top: 15px; }
    @media (min-width: 992px) {
      .o-090 .a-01000 {
        margin-top: 25px; } }
  .o-090 .lastRow {
    padding-top: 20px;
    padding-bottom: 25px;
    margin: 0 10px; }
    @media (min-width: 992px) {
      .o-090 .lastRow {
        padding-bottom: 80px; } }
  .o-090 .button {
    height: 24px;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: white; }
    @media (min-width: 992px) {
      .o-090 .button {
        font-size: 14px; } }
    .o-090 .button + .button {
      border-left: 1px solid #B1B2B3; }
  .o-090 .copyright {
    text-align: center;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    color: #575656;
    color: white; }
    @media (min-width: 992px) {
      .o-090 .copyright {
        font-size: 12px; } }
  .o-090 .someIcons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .o-090 .someIcons {
        margin-top: 10px; } }
    .o-090 .someIcons a {
      display: block;
      margin-left: 12px;
      margin-right: 12px; }
    .o-090 .someIcons__wrapper {
      width: 100%; }

.o-100 .collapse {
  width: 100%; }

.o-100 .button-more {
  cursor: pointer;
  width: 100%;
  text-align: center;
  border: 0;
  border-top: 1px solid #B1B2B3;
  margin-top: 0;
  background: none;
  padding: 25px 0;
  outline: 0 !important;
  text-transform: uppercase;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: black; }
  @media (min-width: 992px) {
    .o-100 .button-more {
      font-size: 14px; } }
  .o-100 .button-more svg {
    width: 18px;
    height: 12px;
    margin-left: 5px;
    position: relative;
    top: -1px; }
    .o-100 .button-more svg polygon {
      fill: black !important; }
  .o-100 .button-more .content-shown svg {
    transform: rotate(180deg); }
  .o-100 .button-more .content-collapsed {
    display: none; }
  .o-100 .button-more.collapsed .content-collapsed {
    display: block; }
  .o-100 .button-more.collapsed .content-shown {
    display: none; }

.o-100__headline {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: black;
  color: white;
  margin-bottom: 16px; }
  @media (min-width: 992px) {
    .o-100__headline {
      font-size: 28px;
      line-height: 38px; } }

.o-100__content-wrapper-desktop {
  max-width: 357px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; }

.o-100__background {
  background-color: black; }
  @media (min-width: 992px) {
    .o-100__background {
      background-color: #575656; } }

.o-100--car-detail {
  padding-top: 21px;
  padding-bottom: 27px;
  padding-left: 14px;
  padding-right: 14px;
  max-width: 400px;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .o-100--car-detail {
      margin-top: 40px;
      padding-top: 70px;
      padding-bottom: 80px;
      padding-left: 0;
      padding-right: 0;
      max-width: none;
      margin: 0; } }
  .o-100--car-detail .text p {
    color: white; }
  .o-100--car-detail .buttons a {
    color: white; }
    .o-100--car-detail .buttons a svg path {
      fill: white !important; }

@media (min-width: 992px) {
  .o-100 .a-00600 .gradient {
    display: none; } }

.o-110__main-navigation > .row {
  margin: 0; }

.o-110__partner-navigation > .row {
  margin: 0; }

.mobileMenuActive {
  -webkit-overflow-scrolling: touch !important;
  overflow: hidden !important;
  height: 100% !important; }

body {
  overflow-x: hidden; }

@media (min-width: 992px) {
  .page-header {
    margin-left: -15px;
    margin-right: -15px;
    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.12);
    position: relative; } }

.o-110 {
  margin: 0;
  z-index: 1051; }
  @media (min-width: 992px) {
    .o-110 {
      z-index: 1; } }
  .o-110__partner-navigation {
    background-color: #575656;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    z-index: 1051;
    width: 100vw;
    max-width: 100%;
    overflow: hidden;
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important; }
    .o-110__partner-navigation > .row > .container {
      padding: 0; }
      @media (max-width: 992px) {
        .o-110__partner-navigation > .row > .container {
          max-width: 100%; } }
      @media (min-width: 992px) {
        .o-110__partner-navigation > .row > .container {
          padding: 0 15px; } }
    @media (min-width: 992px) {
      .o-110__partner-navigation {
        position: relative;
        z-index: 1; } }
  .o-110__navi-container {
    width: 100%;
    z-index: 10; }
    @media (min-width: 992px) {
      .o-110__navi-container {
        background-color: white;
        position: relative;
        bottom: 0;
        width: auto;
        display: inline-block;
        float: right;
        overflow: visible;
        max-height: 100%;
        z-index: 1; } }
    .o-110__navi-container.mobile {
      position: fixed;
      left: 0;
      overflow-y: auto;
      overflow-x: hidden;
      bottom: 50px;
      transition: transform 0.5s cubic-bezier(0, 0.34, 0, 1);
      transform: translateY(100%); }
      .o-110__navi-container.mobile input {
        z-index: 999; }
      @media (min-width: 768px) {
        .o-110__navi-container.mobile {
          bottom: 59px; } }
      .o-110__navi-container.mobile.active {
        max-height: calc(100% - 50px);
        transform: translateY(0);
        height: 100%; }
        @media (min-width: 768px) {
          .o-110__navi-container.mobile.active {
            max-height: calc(100% - 59px); } }
      .o-110__navi-container.mobile.fullheight {
        max-height: 100% !important; }
    .o-110__navi-container .m-0251:last-child {
      padding-bottom: 0; }
      .o-110__navi-container .m-0251:last-child .a-03400 {
        border-bottom: 0; }
  .o-110__navi-container-mobile-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 100%; }
    .o-110__navi-container-mobile-wrapper.active {
      z-index: 10; }
  .o-110__navi-container-mobile {
    z-index: 1;
    overflow: hidden;
    display: none;
    transform: translateY(100%);
    transition: transform 0.4s ease-in-out;
    background-color: white; }
    .o-110__navi-container-mobile.active {
      display: block;
      height: 100%;
      transition: transform 0.8s ease-in-out;
      transform: translateY(0%); }
  .o-110__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
    top: 0px;
    left: 0;
    z-index: 1;
    opacity: 0;
    transition: all .5s ease-in-out;
    visibility: hidden; }
    .o-110__overlay.active {
      opacity: 1;
      visibility: visible; }
    .o-110__overlay.fullheight {
      top: 0 !important; }
  .o-110 .m-0080 .a-03600 .text {
    margin-left: 0; }

.o-190 {
  margin-top: 0; }
  .o-190__card-wrapper {
    display: grid;
    grid-template-columns: 1fr; }
    @media (min-width: 768px) {
      .o-190__card-wrapper {
        grid-column-gap: 15px;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr; } }
    @media (min-width: 992px) {
      .o-190__card-wrapper {
        grid-column-gap: 24px;
        grid-template-columns: 1fr 1fr 1fr; } }
  .o-190 .card-wrapper {
    margin-bottom: 12px;
    background-color: #E4E4E5; }
  .o-190 .collapse {
    width: 100% !important; }
  .o-190 .button-more {
    cursor: pointer;
    width: 100%;
    text-align: center;
    border: 0;
    border-top: 1px solid black;
    margin-top: 16px;
    background: none;
    padding: 25px 0;
    outline: 0 !important;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: white; }
    @media (min-width: 992px) {
      .o-190 .button-more {
        font-size: 14px; } }
    @media (min-width: 992px) {
      .o-190 .button-more {
        margin-top: 42px; } }
    .o-190 .button-more svg {
      width: 18px;
      height: 12px;
      margin-left: 5px;
      position: relative;
      top: -1px; }
    .o-190 .button-more .content-shown svg {
      transform: rotate(180deg); }
    .o-190 .button-more .content-collapsed {
      display: none; }
    .o-190 .button-more.collapsed .content-collapsed {
      display: block; }
    .o-190 .button-more.collapsed .content-shown {
      display: none; }

.o-200 {
  margin-top: 20px; }
  @media (min-width: 992px) {
    .o-200 {
      margin-top: 70px; } }
  .o-200__headline {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: black; }
    @media (min-width: 992px) {
      .o-200__headline {
        font-size: 28px;
        line-height: 38px; } }
    @media (min-width: 992px) {
      .o-200__headline {
        text-align: center;
        margin-bottom: 15px; } }
  @media (min-width: 992px) {
    .o-200__subHeadline {
      text-align: center;
      margin-bottom: 55px; } }
  .o-200__teaserItem {
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .o-200__teaserItem {
        margin-bottom: 80px;
        padding: 12px;
        flex: 0 0 435px;
        max-width: 435px; } }
  @media (min-width: 992px) {
    .o-200__row {
      justify-content: center; } }

.o-210__background {
  background-color: #575656; }
  @media (min-width: 992px) {
    .o-210__background {
      background-color: transparent; } }

.o-210__headline {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: black;
  color: white; }
  @media (min-width: 992px) {
    .o-210__headline {
      font-size: 28px;
      line-height: 38px; } }
  @media (min-width: 992px) {
    .o-210__headline {
      color: black;
      text-align: center; } }
  .o-210__headline span {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: black;
    word-wrap: break-word;
    hyphens: auto;
    color: white; }
    @media (min-width: 992px) {
      .o-210__headline span {
        font-size: 16px; } }
    @media (min-width: 992px) {
      .o-210__headline span {
        color: black; } }

.o-210__filter-headline {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-size: 24px;
  color: black;
  font-weight: normal;
  line-height: 29px;
  text-align: center;
  margin-bottom: 20px; }

@media (min-width: 992px) {
  .o-210__filter-section {
    padding-top: 36px;
    padding-bottom: 16px;
    background-color: white;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw; }
    .o-210__filter-section--filter-options {
      margin-top: 0; } }

.o-210__controls {
  margin: 0 auto;
  position: relative; }
  @media (min-width: 992px) {
    .o-210__controls {
      max-width: 800px; } }
  @media (min-width: 1244px) {
    .o-210__controls {
      max-width: 1200px; } }
  .o-210__controls > button {
    position: absolute;
    top: 40px;
    z-index: 1;
    background: none;
    border: none;
    outline: 0 !important; }
    .o-210__controls > button svg {
      width: 20px; }
  .o-210__controls .prev {
    left: -45px; }
  .o-210__controls .next {
    right: -45px; }

.o-210__car-title {
  position: relative;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto; }
  @media (min-width: 992px) {
    .o-210__car-title {
      font-size: 16px; } }
  @media (min-width: 992px) {
    .o-210__car-title {
      margin-bottom: 0; } }
  .o-210__car-title .check {
    right: -19px;
    top: -5px; }
    .o-210__car-title .check svg {
      width: 14px;
      height: 12px; }

.o-210__content-wrapper {
  padding: 20px 12px; }

@media (min-width: 992px) {
  .o-210__car-filter {
    max-width: 800px;
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto; } }

@media (min-width: 1244px) {
  .o-210__car-filter {
    max-width: 1200px; } }

.o-210__car-filter-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .o-210__car-filter-inner {
      width: auto;
      flex-wrap: nowrap;
      max-width: 1200px;
      justify-content: center;
      transition: transform .4s ease-in-out; } }

.o-210__car-filter-element {
  max-width: 47%;
  margin-top: 18px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  @media (min-width: 992px) {
    .o-210__car-filter-element {
      margin-top: 0;
      max-width: 200px;
      border: 0;
      flex-shrink: 0; } }
  .o-210__car-filter-element img {
    max-width: 100%;
    height: auto; }
  .o-210__car-filter-element.active span {
    color: #ED0000; }

.o-210__modal-window {
  z-index: 0; }

.o-210__modal-close {
  position: absolute;
  top: 20px;
  right: 20px; }
  .o-210__modal-close svg {
    width: 17px;
    height: 16px; }

.o-210__modal-top-wrapper {
  margin-top: 17px;
  padding: 0 20px; }

.o-210__filter-options-row {
  padding: 7px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  .o-210__filter-options-row--slider {
    padding-top: 0;
    padding-bottom: 16px; }
  .o-210__filter-options-row h3 {
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: black;
    font-weight: normal;
    line-height: 18px;
    margin-bottom: 0; }

.o-210__filter-options-value {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 75%;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #575656;
  font-weight: normal;
  line-height: 18px; }
  .o-210__filter-options-value .value {
    margin-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .o-210__filter-options-value .icon svg {
    width: 15px;
    height: 9px;
    position: relative;
    top: -1px; }
    .o-210__filter-options-value .icon svg polygon {
      fill: #B1B2B3 !important; }

.o-210__slider-value {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #575656;
  font-weight: normal;
  line-height: 18px; }

.o-210__filter-options-collapse {
  padding: 0px 20px 9px 20px; }
  .o-210__filter-options-collapse hr {
    position: relative;
    margin-bottom: 25px;
    width: 100vw;
    left: -50vw;
    margin-left: 50%; }
  .o-210__filter-options-collapse--registration {
    padding: 0 11px 9px 11px; }
    .o-210__filter-options-collapse--registration hr {
      margin-bottom: 13px; }

.o-210__registration-button-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

.o-210__slider-wrapper {
  padding: 0 20px; }

.o-210__filter-option-registration-button {
  border: 1px solid #B1B2B3;
  width: 47%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  background-color: white;
  transition: all .2s ease-in-out; }
  @media (min-width: 992px) {
    .o-210__filter-option-registration-button {
      width: 46%;
      height: 44px; } }
  .o-210__filter-option-registration-button span {
    position: relative;
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-size: 12px;
    color: black;
    font-weight: normal;
    line-height: normal;
    transition: color .2s ease-in-out;
    text-transform: uppercase; }
    .o-210__filter-option-registration-button span .icon {
      position: absolute;
      right: -15px;
      top: -1px; }
      .o-210__filter-option-registration-button span .icon svg {
        width: 10px;
        height: 8px; }
        .o-210__filter-option-registration-button span .icon svg polygon {
          fill: white !important; }
  .o-210__filter-option-registration-button.active {
    background-color: #ED0000;
    border-color: #ED0000; }
    .o-210__filter-option-registration-button.active span {
      color: white; }

.o-210__collapse-link[aria-expanded="true"] .icon {
  transform: rotate(180deg); }

.o-210__filter-options-model-wrapper {
  margin-top: -18px; }

.o-210__filter-cta {
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0; }
  @media (min-width: 768px) {
    .o-210__filter-cta {
      bottom: 59px; } }

.o-210__last-hr {
  margin-bottom: 0; }

.o-210__filter-options-desktop {
  margin: 0 auto;
  display: flex; }
  @media (min-width: 992px) {
    .o-210__filter-options-desktop {
      max-width: 960px; }
      .o-210__filter-options-desktop px-a01000 {
        margin-left: auto; } }
  @media (min-width: 1244px) {
    .o-210__filter-options-desktop {
      max-width: 1200px; } }

@media (min-width: 992px) {
  .o-210__filter {
    position: relative;
    max-width: 200px;
    width: 180px;
    margin: 0 10px;
    border-top: 1px solid #E4E4E5; }
    .o-210__filter.active {
      border-color: #ED0000;
      border-width: 3px;
      top: -2px;
      margin-bottom: -2px; }
      .o-210__filter.active .text {
        font-family: mmc-bold,Helvetica,Arial,sans-serif; } }

.o-210__filter-dropdown-button {
  background: none;
  border: none;
  padding: 18px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; }
  @media (min-width: 1244px) {
    .o-210__filter-dropdown-button {
      padding: 18px 15px; } }
  .o-210__filter-dropdown-button .text {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .o-210__filter-dropdown-button .text {
        font-size: 14px; } }
  .o-210__filter-dropdown-button .icon {
    position: relative;
    top: -4px; }
    .o-210__filter-dropdown-button .icon.active svg {
      transform: rotate(180deg); }
  .o-210__filter-dropdown-button svg {
    width: 16px;
    height: 9px; }

.o-210__filter-dropdown {
  position: absolute;
  bottom: 0;
  width: 305px;
  height: auto;
  padding: 25px;
  background-color: white;
  transform: translateY(100%);
  box-shadow: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out; }
  .o-210__filter-dropdown.active {
    z-index: 3;
    opacity: 1;
    visibility: visible;
    box-shadow: 0 5px 7px 1px rgba(0, 0, 0, 0.2); }
  .o-210__filter-dropdown .a-00800 {
    margin-top: 22px; }

.o-210__filter-dropdown-input-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px; }
  .o-210__filter-dropdown-input-wrapper span {
    margin: 0 4px; }

.o-210__reset-button-wrapper {
  padding: 0 20px 10px 20px; }
  .o-210__reset-button-wrapper .a-01000 {
    max-width: 100%; }

.o-210__desktop-sticky-filter {
  position: fixed !important;
  top: 0;
  background-color: white;
  z-index: 3;
  transform: translateY(-100%);
  box-shadow: 0;
  transition: all .3s ease-in-out; }
  .o-210__desktop-sticky-filter.active {
    transform: translateY(0);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2); }

.o-210__sticky-filter-wrapper {
  padding: 20px 16px; }
  .o-210__sticky-filter-wrapper span {
    padding-right: 30px;
    border-right: 1px solid #cccccc; }

.o-210__sticky-filter-button {
  margin-left: 30px;
  background: none;
  border: none;
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px; }
  @media (min-width: 992px) {
    .o-210__sticky-filter-button {
      font-size: 16px; } }

px-filter-options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 50px; }
  @media (min-width: 992px) {
    px-filter-options {
      display: block;
      width: 100%;
      padding-top: 0; } }

px-car-filter {
  width: 100%; }

px-filter-modal {
  height: 100%; }

.ng5-slider {
  margin-top: 16px !important; }

.ng5-slider-pointer {
  outline: 0 !important;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  background-color: white !important;
  width: 29px !important;
  height: 29px !important; }
  .ng5-slider-pointer:after {
    background: #fff !important; }

.ng5-slider-bar {
  height: 2px !important; }

.ng5-slider-bubble {
  display: none !important; }

.ng5-slider-selection {
  background: #ED0000 !important; }

.margin--none {
  margin: 0; }

.margin__top--small {
  margin-top: 20px; }

.margin__top--medium {
  margin-top: 20px; }
  @media (min-width: 992px) {
    .margin__top--medium {
      margin-top: 55px; } }

.margin__top--large {
  margin-top: 30px; }
  @media (min-width: 992px) {
    .margin__top--large {
      margin-top: 70px; } }

.margin__bottom--small {
  margin-bottom: 20px; }

.margin__bottom--medium {
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .margin__bottom--medium {
      margin-bottom: 55px; } }

.margin__bottom--large {
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .margin__bottom--large {
      margin-bottom: 70px; } }

.flex__direcRow {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .flex__direcRow--justStart {
    justify-content: flex-start; }
  .flex__direcRow--justEnd {
    justify-content: flex-end; }
  .flex__direcRow--justBetw {
    justify-content: space-between; }
  .flex__direcRow--alignEnd {
    align-items: flex-end; }

.flex__direcCol {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center; }
  .flex__direcCol--justStart {
    justify-content: flex-start; }
  .flex__direcCol--justEnd {
    justify-content: flex-end; }
  .flex__direcCol--justBetw {
    justify-content: space-between; }
  .flex__direcCol--justCen {
    justify-content: center; }
  .flex__direcCol--alignEnd {
    align-items: flex-end; }

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw; }

.background {
  background-color: transparent; }
  .background--grey {
    background-color: #E4E4E5; }
  .background--dark-grey {
    background-color: #575656; }
  .background--white {
    background-color: white; }
  .background--black {
    background-color: black; }

.white {
  color: white; }

@media (min-width: 992px) {
  .col-narrow {
    padding-left: 45px;
    padding-right: 45px; } }

.no-break {
  white-space: nowrap; }

.uppercase {
  text-transform: uppercase; }

.strong {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif; }

.campaign-buttons {
  display: flex;
  flex-wrap: wrap; }
  .campaign-buttons a {
    margin-bottom: 3px; }
    .campaign-buttons a:nth-child(2) {
      background-color: #ED0000;
      margin-bottom: 40px; }
      @media (min-width: 992px) {
        .campaign-buttons a:nth-child(2) {
          margin-left: 15px; } }

.editableText {
  margin-bottom: 4px; }
  .editableText ul {
    margin-left: 20px; }
    .editableText ul li {
      word-wrap: break-word;
      hyphens: auto;
      color: black;
      line-height: 18px;
      font-size: 15px; }
      @media (min-width: 992px) {
        .editableText ul li {
          font-size: 16px; } }

.textEdit__header {
  position: relative;
  height: 25px; }

.textEdit__close {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 16px;
  height: 16px;
  font-size: 30px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  cursor: pointer; }

.textEdit__content {
  width: 1000px; }

.textEdit__textLength {
  margin-top: 10px; }

.textEdit__saveBtn {
  margin-top: 10px;
  float: right; }

.service__hr {
  margin-top: 20px; }

@media (min-width: 992px) {
  .service__headline {
    margin-top: 40px; } }

.contact-form {
  padding: 23px 0; }
  @media (min-width: 768px) {
    .contact-form {
      padding: 37px 0; } }
  @media (min-width: 992px) {
    .contact-form {
      padding: 74px 0; } }
  .contact-form__required {
    font-size: 12px; }
  .contact-form__data-policy-link {
    font-size: 10px; }
    @media (min-width: 992px) {
      .contact-form__data-policy-link {
        font-size: 14px; } }
  .contact-form__top-text {
    font-size: 10px; }
    @media (min-width: 992px) {
      .contact-form__top-text {
        font-size: 14px;
        text-align: center; } }
  @media (min-width: 768px) {
    .contact-form__second-wrapper {
      padding-top: 25px; } }
  @media (min-width: 992px) {
    .contact-form__second-wrapper {
      padding-top: 85px; } }

.vehicleReservation button.mat-mini-fab.mat-accent {
  position: absolute !important;
  right: -30px;
  background: black;
  height: 20px;
  width: 20px;
  top: 14px;
  box-shadow: none; }
  .vehicleReservation button.mat-mini-fab.mat-accent .mat-button-wrapper {
    display: block;
    padding: 0;
    line-height: 20px; }

.vehicleReservation__tooltip {
  position: absolute;
  right: -30px;
  height: 20px;
  width: 20px;
  top: 14px;
  z-index: 10;
  background: transparent;
  border: 0 solid transparent; }

.vehicleReservation .borderItem {
  border: 1px solid #cccccc;
  padding: 15px; }
  .vehicleReservation .borderItem--minHeight {
    min-height: 100%; }

.vehicleReservation .infoContainer {
  padding: 24px;
  padding-right: 33px; }

.vehicleReservation .vehicleDetail-m0170 {
  padding-left: 25px;
  padding-bottom: 12px;
  padding-right: 8px;
  padding-top: 19px; }
  .vehicleReservation .vehicleDetail-m0170 .a-03300 .description {
    font-size: 11px; }
  .vehicleReservation .vehicleDetail-m0170 .a-03300 .type {
    font-size: 11px;
    font-weight: bold; }
  .vehicleReservation .vehicleDetail-m0170 .a-03300 a {
    font-size: 11px; }

.vehicleReservation .mat-button.mat-primary .mat-button-focus-overlay {
  background-color: transparent;
  border-radius: 0;
  color: black; }
  .vehicleReservation .mat-button.mat-primary .mat-button-focus-overlay span,
  .vehicleReservation .mat-button.mat-primary .mat-button-focus-overlay p {
    color: black; }

.vehicleReservation .dataPolicyDisclaimer,
.vehicleReservation .dataPolicyDisclaimer a {
  font-size: 12px;
  line-height: 18px;
  color: #575656; }

.vehicleReservation .dataPolicyDisclaimer a {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-decoration: underline; }

.vehicleReservation .copy {
  color: #575656; }

.vehicleReservation .vehicleModel {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: black; }
  @media (min-width: 992px) {
    .vehicleReservation .vehicleModel {
      font-size: 28px;
      line-height: 38px; } }

.vehicleReservation .vehicleTitle {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto; }
  @media (min-width: 992px) {
    .vehicleReservation .vehicleTitle {
      font-size: 16px; } }

.vehicleReservation .vehiclePrice {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px; }
  @media (min-width: 992px) {
    .vehicleReservation .vehiclePrice {
      font-size: 24px; } }

.vehicleReservation .vehiclePriceMwSt {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto; }
  @media (min-width: 992px) {
    .vehicleReservation .vehiclePriceMwSt {
      font-size: 16px; } }
  @media (max-width: 992px) {
    .vehicleReservation .vehiclePriceMwSt {
      font-size: 14px; } }

@media (min-width: 992px) {
  .vehicleReservation .a-03700 {
    margin-top: -20px; } }

.vehicleReservation .a-03700__nav__item__bullet .iconCheck {
  display: none !important; }

.vehicleReservation .a-03700__nav__item__bullet {
  position: absolute !important;
  top: 0 !important; }

.vehicleReservation .a-03700__nav__item__label {
  font-size: 12px;
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  color: transparent; }

.vehicleReservation .a-03700__nav__item__label__line {
  top: 7px !important; }

.vehicleReservation .a-03700__nav__item__line {
  margin-top: 7px; }

.vehicleReservation .a-03700__nav__item {
  position: relative;
  text-align: center; }
  .vehicleReservation .a-03700__nav__item .a-03700__nav__item__bullet:before {
    position: relative;
    width: 12px !important;
    height: 12px !important;
    border-radius: 6px !important;
    top: -2px !important;
    left: -2px !important; }
  .vehicleReservation .a-03700__nav__item.active .a-03700__nav__item__label {
    color: white; }
  .vehicleReservation .a-03700__nav__item.active .a-03700__nav__item__bullet:before {
    width: 18px !important;
    height: 18px !important;
    border-radius: 10px !important;
    top: -4px !important;
    left: -1px !important; }

.vehicleReservation .a-03700__nav__item__label__number {
  position: absolute;
  left: 0px;
  width: 16px;
  top: -2px; }
  @media (max-width: 992px) {
    .vehicleReservation .a-03700__nav__item__label__number {
      top: 2px; } }

.vehicleReservation__headline {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: black;
  margin-top: 40px; }
  @media (min-width: 992px) {
    .vehicleReservation__headline {
      font-size: 28px;
      line-height: 38px; } }

.vehicleReservation__headlineSmall {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: black;
  margin-top: 40px; }
  @media (min-width: 992px) {
    .vehicleReservation__headlineSmall {
      font-size: 28px;
      line-height: 38px; } }

.vehicleReservation__subheadline {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto;
  color: #575656; }
  @media (min-width: 992px) {
    .vehicleReservation__subheadline {
      font-size: 16px; } }

.vehicleReservation .a-02100 .mat-form-field-appearance-legacy .mat-form-field-wrapper,
.vehicleReservation .a-02100 .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0 !important; }

.vehicleReservation .a-02100 .mat-form-field-label-wrapper {
  padding-top: 0;
  top: 0; }

.vehicleReservation .a-02100 .mat-form-field-underline {
  display: none; }

.vehicleReservation .a-02100 label.icon {
  position: absolute;
  width: 26px;
  height: 26px;
  padding: 0;
  left: 13px;
  top: 10px; }
  .vehicleReservation .a-02100 label.icon svg {
    width: 26px;
    height: 26px; }
    .vehicleReservation .a-02100 label.icon svg path {
      fill: #575656 !important; }
  .vehicleReservation .a-02100 label.icon.hasError svg path {
    fill: #ED0000 !important; }

.vehicleReservation .a-02100 input.withIcon {
  padding-left: 53px; }
  .vehicleReservation .a-02100 input.withIcon + label {
    padding-left: 53px; }

@media (min-width: 992px) {
  .vehicleReservation .m-0170__detaillist px-a03300 {
    margin-top: 29px;
    flex-basis: 30.9%;
    flex-grow: 1; } }

.vehicleReservation .a-04000--a {
  width: 100%;
  background-color: #ebebeb;
  color: #6c6c6c;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto;
  padding: 10px;
  position: relative; }
  @media (min-width: 992px) {
    .vehicleReservation .a-04000--a {
      font-size: 16px; } }
  @media (min-width: 992px) {
    .vehicleReservation .a-04000--a {
      padding: 15px 20px; } }

.vehicleReservation .a-04000 .bold {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-weight: 400; }

.vehicleReservation .a-04000 strong {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase; }

.vehicleReservation .a-04000 .content p {
  width: 90%;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto; }
  @media (min-width: 992px) {
    .vehicleReservation .a-04000 .content p {
      font-size: 16px; } }
  .vehicleReservation .a-04000 .content p:last-child {
    margin-bottom: 0; }

.vehicleReservation .a-04000 .content img.info,
.vehicleReservation .a-04000 .content span.info {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background-color: black;
  color: white;
  text-align: center;
  font-size: 26px;
  line-height: 29px; }
  .vehicleReservation .a-04000 .content img.info svg,
  .vehicleReservation .a-04000 .content span.info svg {
    vertical-align: top;
    position: relative; }

.vehicleReservation .a-04000 .content strong.tel {
  font-size: 12px;
  line-height: 18px; }
  @media (min-width: 992px) {
    .vehicleReservation .a-04000 .content strong.tel {
      font-size: 14px; } }

.vehicleReservation .a-04000 hr {
  margin: 15px 0 15px 0;
  background-color: #dedede; }

.vehicleReservation .a-04000 .footer img.edit,
.vehicleReservation .a-04000 .footer span.edit {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 10px; }
  .vehicleReservation .a-04000 .footer img.edit svg,
  .vehicleReservation .a-04000 .footer span.edit svg {
    max-width: 100%;
    max-height: 100%; }

.vehicleReservation .a-04000 .footer strong.description {
  color: black;
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px; }

.vehicleReservation .a-04000 .form .input {
  border: 1px solid #b8b8b8;
  margin: 15px 0 15px 0;
  background-color: white;
  padding: 3px 10px; }
  .vehicleReservation .a-04000 .form .input.hasError {
    border: 1px solid #ED0000 !important; }
    .vehicleReservation .a-04000 .form .input.hasError + label {
      color: #ED0000 !important; }
  .vehicleReservation .a-04000 .form .input span {
    font-size: 12px;
    line-height: 16px;
    color: black;
    font-weight: bold; }
    @media (min-width: 992px) {
      .vehicleReservation .a-04000 .form .input span {
        font-size: 14px; } }
  .vehicleReservation .a-04000 .form .input input {
    border: 0;
    width: 100%;
    outline: none;
    padding: 0;
    font-size: 14px;
    line-height: 12px;
    position: relative;
    top: -2px; }

.vehicleReservation .a-04000 .form input {
  background-color: white; }

.vehicleReservation .a-04000 .form input[type="submit"] {
  background-color: transparent;
  border: 2px solid black;
  text-transform: uppercase;
  padding: 10px;
  width: 100%;
  font-weight: bold; }

.vehicleReservation .a-03900 .wrap {
  padding: 9px 20px;
  border: 1px solid #B1B2B3;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  max-height: 50px; }
  .vehicleReservation .a-03900 .wrap input {
    margin-left: 1ch;
    letter-spacing: 1ch;
    display: inline-block;
    border: none;
    text-align: left;
    outline: none;
    caret-color: #ED0000;
    font-weight: bold;
    color: #575656;
    font-size: 20px;
    font-variant-numeric: tabular-nums;
    text-indent: 0.3ch;
    background: repeating-linear-gradient(90deg, #B1B2B3 0, #B1B2B3 1.6ch, transparent 0, transparent 2ch) 0 100%/calc(100% - 0.5ch) 1px no-repeat; }
    .vehicleReservation .a-03900 .wrap input:focus {
      outline-color: transparent;
      outline-style: none; }
    @media (min-width: 992px) {
      .vehicleReservation .a-03900 .wrap input {
        font-size: 24px; } }
  .vehicleReservation .a-03900 .wrap.focus {
    border: 1px solid black; }
  .vehicleReservation .a-03900 .wrap .icon {
    display: none;
    width: 19px;
    height: 19px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%); }
    @media (min-width: 992px) {
      .vehicleReservation .a-03900 .wrap .icon {
        right: 25px; } }
    .vehicleReservation .a-03900 .wrap .icon.iconValid svg {
      top: -4px;
      position: relative; }
    .vehicleReservation .a-03900 .wrap .icon svg {
      width: 100%;
      height: 100%; }
  .vehicleReservation .a-03900 .wrap.hasError {
    border: 1px solid #ED0000; }
    .vehicleReservation .a-03900 .wrap.hasError .icon {
      border-radius: 20px;
      background: #ED0000;
      padding: 5px; }
      .vehicleReservation .a-03900 .wrap.hasError .icon.iconError {
        display: block; }
        .vehicleReservation .a-03900 .wrap.hasError .icon.iconError svg {
          width: 50%;
          height: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%); }
          .vehicleReservation .a-03900 .wrap.hasError .icon.iconError svg polyline {
            fill: white !important; }
  .vehicleReservation .a-03900 .wrap.isValid .icon.iconValid {
    display: block; }

.vehicleReservation__smsBtn {
  max-width: 100%;
  margin-top: 20px; }

.vehicleReservation__stickyBtn {
  border: 0 solid transparent; }
  @media (max-width: 992px) {
    .vehicleReservation__stickyBtn {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1052;
      height: 50px;
      margin-bottom: 0;
      border-bottom: 2px solid white !important;
      font-family: "mmc-bold", Helvetica, Arial, sans-serif; } }

.vehicleReservation .a-03800 {
  border: 0 solid transparent;
  line-height: 24px; }
  @media (min-width: 992px) {
    .vehicleReservation .a-03800 {
      font-size: 14px; } }

.vehicleReservation__nextSteps {
  padding: 30px;
  background-color: #E4E4E5; }
  @media (max-width: 992px) {
    .vehicleReservation__nextSteps {
      padding: 15px; } }
  .vehicleReservation__nextSteps h2, .vehicleReservation__nextSteps .price {
    color: #575656;
    margin-bottom: 30px; }
    @media (max-width: 992px) {
      .vehicleReservation__nextSteps h2, .vehicleReservation__nextSteps .price {
        margin-bottom: 15px; } }

.vehicleReservation__nextStepWrapper {
  display: flex;
  align-items: center; }

.vehicleReservation__nextStepsIcon {
  width: 23px;
  height: 23px;
  flex-grow: 0; }
  .vehicleReservation__nextStepsIcon svg {
    width: 23px;
    height: 23px; }
    .vehicleReservation__nextStepsIcon svg path {
      fill: #575656; }

.vehicleReservation__nextStepsCopy {
  padding-left: 25px;
  flex-grow: 1;
  color: #575656;
  word-break: break-word;
  hyphens: auto; }

.vehicleReservation__completeRow {
  align-items: flex-end;
  padding-bottom: 15px; }
  @media screen and (min-width: 992px) {
    .vehicleReservation__completeRow {
      min-height: 165px; } }
  @media screen and (min-width: 1244px) {
    .vehicleReservation__completeRow {
      min-height: 215px; } }
  .vehicleReservation__completeRow h1 {
    margin-bottom: 20px; }

.vehicleReservation__garantieImg {
  max-width: 159px;
  margin-bottom: 30px; }
  @media (max-width: 992px) {
    .vehicleReservation__garantieImg {
      margin-top: 20px; } }

.vehicleReservation__garantieHeadline {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto;
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .vehicleReservation__garantieHeadline {
      font-size: 16px; } }

.vehicleReservation__garantieCopy {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #575656; }
  @media (min-width: 992px) {
    .vehicleReservation__garantieCopy {
      font-size: 12px; } }
  @media (max-width: 992px) {
    .vehicleReservation__garantieCopy {
      margin-bottom: 30px; } }
  .vehicleReservation__garantieCopy a {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    color: #575656;
    text-decoration: underline; }
    @media (min-width: 992px) {
      .vehicleReservation__garantieCopy a {
        font-size: 12px; } }

@media (max-width: 992px) {
  .vehicleReservation__detailCol {
    margin-top: 30px; } }

.vehicleReservation__radio input[type="radio"] {
  position: absolute;
  left: -9999px; }
  .vehicleReservation__radio input[type="radio"] + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 25px;
    display: inline-block;
    font-size: 12px;
    padding-left: 44px;
    font-weight: bold;
    text-transform: uppercase; }
    .vehicleReservation__radio input[type="radio"] + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 25px;
      height: 25px;
      border: 1px solid #B1B2B3;
      border-radius: 100%;
      background: white; }
    .vehicleReservation__radio input[type="radio"] + label:after {
      content: "";
      width: 17px;
      height: 17px;
      background: #ED0000;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%; }
  .vehicleReservation__radio input[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0); }
  .vehicleReservation__radio input[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); }

.vehicleReservation__radio + .vehicleReservation__radio {
  margin-left: 50px; }

.vehicleReservation__radioLabel {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px; }

.vehicleReservation__radioGroup {
  display: flex;
  flex-direction: row; }

.step1 button,
.step2 button,
.step3 button {
  width: 100%; }
  .step1 button[disabled],
  .step2 button[disabled],
  .step3 button[disabled] {
    opacity: 1;
    background-color: #B1B2B3; }

.cars {
  background-color: #E4E4E5; }

.cars-detail__container {
  margin: 0 auto;
  max-width: 100%;
  padding: 20px 27px 20px 27px; }
  @media (min-width: 576px) {
    .cars-detail__container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .cars-detail__container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .cars-detail__container {
      padding: 70px 15px 70px 15px;
      max-width: 960px; } }
  @media (min-width: 1244px) {
    .cars-detail__container {
      max-width: 1220px; } }

.cars-detail__headline {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: black; }
  @media (min-width: 992px) {
    .cars-detail__headline {
      font-size: 28px;
      line-height: 38px; } }
  .cars-detail__headline:empty, .cars-detail__headline.empty {
    margin: 0 !important; }

.cars-detail__notes {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto;
  color: #575656; }
  @media (max-width: 992px) {
    .cars-detail__notes {
      padding-top: 11px; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .cars-detail__notes {
      width: 100%; } }
  @media (min-width: 992px) {
    .cars-detail__notes {
      font-size: 16px; } }
  .cars-detail__notes ul {
    display: flex;
    list-style: none;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0; }
    .cars-detail__notes ul li {
      flex-basis: 100%;
      margin-bottom: 17px;
      display: flex;
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: black;
      word-wrap: break-word;
      hyphens: auto;
      color: #575656; }
      @media (min-width: 992px) {
        .cars-detail__notes ul li {
          font-size: 16px; } }
      .cars-detail__notes ul li:first-child {
        margin-top: 12px; }
      @media (min-width: 992px) {
        .cars-detail__notes ul li {
          margin-bottom: 0;
          margin-top: 12px;
          flex-basis: 33%; } }
      .cars-detail__notes ul li::before {
        content: url(../Icons/relaunch/icon_check_highlights.svg);
        width: 13px;
        height: 13px;
        position: relative;
        top: -1px;
        display: block;
        margin-right: 5px;
        flex-shrink: 0;
        flex-grow: 0; }

.step1 button,
.step2 button {
  width: 100%; }

px-expander-list {
  display: block;
  width: 100%; }

.opv :ng-deep .backLink {
  cursor: pointer; }

@media (min-width: 992px) {
  .opv {
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    z-index: 1;
    background-color: white; } }

.opv__mobileHeadline {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: black;
  margin-top: 5px;
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .opv__mobileHeadline {
      font-size: 28px;
      line-height: 38px; } }

.opv__mobileHeadlineTime {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 29px;
  color: black;
  margin-top: 20px;
  margin-bottom: 0px; }
  @media (min-width: 992px) {
    .opv__mobileHeadlineTime {
      font-size: 20px; } }

@media (max-width: 992px) {
  .opv__nav {
    padding: 0; } }

@media (min-width: 992px) {
  .opv__nav .a-03700__nav__item__label {
    white-space: nowrap; } }

.opv__wrapper {
  margin-bottom: 80px; }

@media (max-width: 992px) {
  .opv__stepper {
    padding: 0; } }

.opv__stepper .a-03700__nav__item.active .a-03700__nav__item__bullet .iconCheck {
  bottom: 3px !important; }
  @media (min-width: 992px) {
    .opv__stepper .a-03700__nav__item.active .a-03700__nav__item__bullet .iconCheck {
      bottom: 8px !important; } }
  .opv__stepper .a-03700__nav__item.active .a-03700__nav__item__bullet .iconCheck svg {
    max-width: 100%;
    max-height: 100%; }

.opv__content {
  overflow: hidden; }
  @media (max-width: 992px) {
    .opv__content {
      flex-direction: column-reverse; } }

.opv__contentCol {
  overflow: hidden; }

.opv__contentColInner {
  min-height: 100%;
  position: relative; }
  .opv__contentColInner > div {
    width: 100%; }

.opv__gradient {
  height: 20px;
  margin-left: -30px;
  margin-right: -30px;
  background: white;
  background: -moz-linear-gradient(top, white 0%, #f3f3f3 5%, #ededed 6%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, white 0%, #f3f3f3 5%, #ededed 6%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, white 0%, #f3f3f3 5%, #ededed 6%, rgba(255, 255, 255, 0) 100%); }
  @media (min-width: 992px) {
    .opv__gradient {
      margin-bottom: 80px; } }

.opv.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 100; }
  @media (max-width: 992px) {
    .opv.sticky {
      padding: 0 22px; } }
  .opv.sticky .opv__gradient {
    margin-bottom: 0; }

.opv__stickyWrapper {
  height: 80px; }
  .opv__stickyWrapper.firstPage, .opv__stickyWrapper.lastPage {
    height: auto; }
    .opv__stickyWrapper.firstPage .opv__gradient, .opv__stickyWrapper.lastPage .opv__gradient {
      margin-bottom: 0 !important; }
  @media (min-width: 992px) {
    .opv__stickyWrapper {
      height: 175px; }
      .opv__stickyWrapper.firstPage {
        height: auto; }
        .opv__stickyWrapper.firstPage .opv__gradient {
          margin-bottom: 65px; }
      .opv__stickyWrapper.lastPage {
        height: auto; }
        .opv__stickyWrapper.lastPage .opv__gradient {
          margin-bottom: 65px !important; } }

@media (min-width: 992px) {
  .opv__modelImage .a-00510 img {
    max-width: 100% !important;
    min-width: 100%; } }

.opv__footnote {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #575656;
  margin-top: 10px; }
  @media (min-width: 992px) {
    .opv__footnote {
      font-size: 14px; } }

@media (min-width: 992px) {
  .opv__botNav {
    position: fixed;
    display: flex;
    height: 118px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #E4E4E5;
    z-index: 10;
    flex-direction: column;
    justify-content: center; } }

.opv__botNav otv-a03800 .a-03800 {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif; }
  @media (max-width: 992px) {
    .opv__botNav otv-a03800 .a-03800 {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1055;
      padding: 15px 10px; } }
  @media (min-width: 992px) {
    .opv__botNav otv-a03800 .a-03800 {
      margin: 15px 0; } }

.opv .opv_envkv strong {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-weight: normal; }

@media (min-width: 992px) {
  .opv__bullet:nth-child(3) {
    margin-top: 40px;
    display: block; } }

@media (min-width: 992px) {
  .opv__bullet:nth-child(3) .a-04400::before {
    border-top: 1px solid #E4E4E5;
    content: "";
    display: block;
    width: 100%;
    position: relative;
    bottom: 25px; } }

@media (min-width: 992px) {
  .opv__bullet:nth-child(3) .a-04400 .icon {
    width: 40px !important;
    min-width: 40px !important;
    height: 40px !important; } }

@media (min-width: 992px) {
  .opv__bullet:nth-child(3) .a-04400 .wrap {
    margin-left: 30px !important; } }

@media (min-width: 992px) {
  .opv__bullet:last-child .a-04400:after {
    border-bottom-color: transparent !important; } }

.opv__bullet .a-04400 {
  padding: 15px 0px !important; }
  @media (min-width: 992px) {
    .opv__bullet .a-04400 {
      padding: 25px 15px !important; } }
  @media (min-width: 992px) {
    .opv__bullet .a-04400:after {
      border-bottom-color: #E4E4E5 !important;
      top: 25px !important; } }

@media (max-width: 992px) {
  .opv__bullet--returnDay .a-04400::before {
    border-top: 1px solid #E4E4E5;
    content: "";
    display: block;
    width: 100%;
    position: relative;
    bottom: 15px; } }

@media (max-width: 992px) {
  .opv__bullet--line {
    border-top: 1px solid #E4E4E5; } }

@media (max-width: 992px) {
  .opv .m-0380 {
    margin-left: -15px;
    margin-right: -15px; } }

@media (max-width: 992px) {
  .opv .m-0380__required {
    display: none; } }

@media (max-width: 992px) {
  .opv .m-0380 h3 {
    display: none; } }

@media (max-width: 992px) {
  .opv__contact {
    padding: 0; }
    .opv__contact o-240 .wrap {
      padding: 25px 15px 0; } }

.opv__pickerMobileHeadline {
  margin-top: 10px;
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 29px;
  color: black; }
  @media (min-width: 992px) {
    .opv__pickerMobileHeadline {
      font-size: 20px; } }

.opv__pickerMobilePickerBtn {
  display: block;
  margin-top: 20px;
  margin-bottom: 15px; }

.opv__pickerDesktopHeadline {
  margin-left: 12px;
  font-size: 24px;
  margin-bottom: 0;
  line-height: 36px; }
  .opv__pickerDesktopHeadline + .opv__footnote {
    margin-left: 12px;
    margin-bottom: 40px; }

.opv__backLink {
  display: block;
  padding: 10px; }
  .opv__backLink .text {
    text-transform: uppercase;
    text-align: center;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    color: #575656;
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 20px; }
    @media (min-width: 992px) {
      .opv__backLink .text {
        font-size: 18px; } }
  .opv__backLink .icon {
    display: inline-block;
    margin-right: 5px;
    color: black;
    position: relative; }
    .opv__backLink .icon svg {
      width: 15px;
      height: 15px;
      margin-bottom: 4px; }
  .opv__backLink:hover .text {
    color: #575656; }
  .opv__backLink:hover .icon {
    animation-name: moveBackLink;
    animation-duration: 0.2s; }
    .opv__backLink:hover .icon svg g, .opv__backLink:hover .icon svg path, .opv__backLink:hover .icon svg polygon, .opv__backLink:hover .icon svg rect {
      fill: #575656 !important; }

@media (min-width: 992px) {
  .opv .o-290 {
    margin-top: 85px; } }

@media (max-width: 992px) {
  .opv .m-0340 .a-03800 {
    z-index: 1056 !important; } }

@media (min-width: 992px) {
  .opv .m-0340 .a-03800 {
    display: none; } }

.opv .noFootnote .m-0380__required {
  display: none; }

.opv .a-00800 {
  cursor: pointer; }

.opv .o-240 .phoneNote p {
  font-size: 12px !important;
  line-height: 16px !important;
  color: #575656 !important; }

.opv .opv__pickerDesktopHeadline,
.opv .a-00700 .text,
.opv .a-00800 .text,
.opv .a-01010 .text,
.opv .a-02000 .label,
.opv .a-02000 .linkWrapper a,
.opv .labelActive,
.opv .a-02100 input.isValid ~ label,
.a-02100 .opv input.isValid ~ label,
.opv .a-02100 input:focus ~ label,
.a-02100 .opv input:focus ~ label,
.opv .a-02100 input.hasError ~ label,
.a-02100 .opv input.hasError ~ label,
.opv .a-02100 .input-group-calendar input.ng-valid ~ label,
.a-02100 .input-group-calendar .opv input.ng-valid ~ label,
.opv .a-02200 textarea.isValid + label,
.a-02200 .opv textarea.isValid + label,
.opv .a-02200 textarea:focus + label,
.a-02200 .opv textarea:focus + label,
.opv .a-02200 textarea.hasError + label,
.a-02200 .opv textarea.hasError + label,
.opv .a-02200--opv-rating textarea.ng-dirty + label,
.a-02200--opv-rating .opv textarea.ng-dirty + label,
.opv .a-02400 .labelText,
.opv .a-03700__nav__item.current,
.opv .a-03800 a,
.opv .a-03900 .wrap input,
.opv .a-04000--a .form .input span,
.opv .a-04000--a .form input[type="submit"],
.opv .a-04200 .date-header,
.opv .a-04300,
.opv .a-04300 a,
.opv .m-0330 .row .wrap .headline,
.opv .o-240 .header .headline,
.opv .o-240 .backLink .text .o-250 .header .headline,
.opv .o-250 .wrap .header .headline,
.opv .o-250 .backLink .text .o-280__wrapper .header h1,
.opv .o-290 .headline,
.opv .backLink .text,
.opv .o-250 .header .subHeadline {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important; }

@media (min-width: 992px) {
  .opv .a-04000--a strong,
  .opv .m-0380 h3 {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif !important;
    font-weight: 400 !important; } }

.opv .a-04000--a .bold,
.opv .o-250 .headline strong {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important; }

.opv .a-04400 .a04400-head,
.opv .a-04200 .date-red,
.opv .a-04100 ngb-datepicker .ngb-dp-header ngb-datepicker-navigation,
.opv .ngb-dp-month-name,
.opv .o-250 .header .formHeadline {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important; }

.opv .a-04100 ngb-datepicker .ngb-dp-header ngb-datepicker-navigation {
  height: 34px !important; }
  .opv .a-04100 ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow.right .ngb-dp-arrow-btn {
    left: auto !important;
    right: 0 !important; }
    @media (max-width: 992px) {
      .opv .a-04100 ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow.right .ngb-dp-arrow-btn {
        display: none; } }
  .opv .a-04100 ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow-btn {
    left: 0 !important;
    opacity: 1 !important; }
    @media (max-width: 992px) {
      .opv .a-04100 ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow-btn {
        display: none; } }

.opv .o-260 {
  margin: 0 auto; }
  .opv .o-260 .m-0330 otv-a04400:nth-child(3) .a-04400 .row .icon {
    width: 25px;
    min-width: 25px;
    margin-right: 0px; }
  @media (max-width: 992px) {
    .opv .o-260 .m-0020 {
      display: none; } }
  @media (min-width: 992px) {
    .opv .o-260 .m-0330 otv-a04400:nth-child(3) .a-04400 .row .icon {
      width: 30px;
      min-width: 30px;
      height: 25px;
      margin-right: 5px; }
    .opv .o-260 .m-0330 otv-a04400:nth-child(3) .a-04400 .row .wrap {
      margin-left: 10px !important; } }

.opv .ngb-dp-month-name {
  font-size: 14px !important;
  line-height: 18px !important; }
  @media (min-width: 992px) {
    .opv .ngb-dp-month-name {
      font-size: 20px !important;
      line-height: 32px !important; } }

.opv .a-03700__nav__item__label {
  white-space: nowrap;
  word-break: keep-all; }

.opv .a-03800.disabled {
  color: #B1B2B3;
  cursor: default; }
  .opv .a-03800.disabled svg g, .opv .a-03800.disabled svg path, .opv .a-03800.disabled svg polygon, .opv .a-03800.disabled svg rect {
    fill: #B1B2B3 !important; }
  .opv .a-03800.disabled a {
    display: block;
    cursor: pointer; }
  .opv .a-03800.disabled div {
    min-width: 15px; }
    .opv .a-03800.disabled div.prev {
      border-right: 1px solid #575656;
      padding-right: 10px;
      height: 20px;
      align-items: center; }
      .opv .a-03800.disabled div.prev a {
        display: flex;
        height: 20px;
        align-items: center; }
    .opv .a-03800.disabled div.wrap {
      display: flex;
      align-items: center; }
      .opv .a-03800.disabled div.wrap .text-right {
        margin-right: 15px; }

.opv .a-02100 input.isFilled + label,
.opv .a-02100 input.isValid + label {
  color: #575656 !important; }

.opv .backLink {
  display: block;
  padding: 16px 10px;
  margin: 15px 0;
  cursor: pointer; }
  .opv .backLink .text {
    text-align: center;
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .opv .backLink .text {
        font-size: 14px; } }
  .opv .backLink .icon {
    display: inline-block;
    margin-right: 5px;
    color: black;
    position: relative; }
    .opv .backLink .icon svg {
      width: 15px;
      height: 15px;
      margin-bottom: 4px; }
  .opv .backLink:hover .text {
    color: #575656; }
  .opv .backLink:hover .icon {
    animation-name: moveBackLink;
    animation-duration: 0.2s; }
    .opv .backLink:hover .icon svg g, .opv .backLink:hover .icon svg path, .opv .backLink:hover .icon svg polygon, .opv .backLink:hover .icon svg rect {
      fill: #575656 !important; }

.opv-rating__container {
  padding: 0 14px; }
  @media (min-width: 992px) {
    .opv-rating__container {
      padding: 0;
      padding-top: 150px;
      padding-bottom: 180px; } }

.opv-rating__model-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%; }

@media (min-width: 1244px) {
  .opv-rating__last-col {
    padding-left: 130px; } }

.opv-rating__headline {
  display: none; }
  @media (min-width: 992px) {
    .opv-rating__headline {
      display: block; } }

.opv-rating__question {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: black; }
  @media (min-width: 992px) {
    .opv-rating__question {
      font-size: 28px;
      line-height: 38px; } }

.opv-rating__thanks {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: black;
  text-align: center; }
  @media (min-width: 992px) {
    .opv-rating__thanks {
      font-size: 28px;
      line-height: 38px; } }
  @media (min-width: 992px) {
    .opv-rating__thanks {
      text-align: left; } }

.opv-rating__not-rateable {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: black;
  text-align: center; }
  @media (min-width: 992px) {
    .opv-rating__not-rateable {
      font-size: 28px;
      line-height: 38px; } }
  @media (min-width: 992px) {
    .opv-rating__not-rateable {
      text-align: left; } }

.opv-rating__button-rating {
  background: none;
  border: 0;
  margin-right: 23px;
  margin-top: 5px; }
  @media (min-width: 992px) {
    .opv-rating__button-rating {
      margin-top: 15px; } }
  .opv-rating__button-rating svg {
    height: 36px;
    width: 34px; }
    .opv-rating__button-rating svg polygon {
      fill: #cccccc !important; }
  .opv-rating__button-rating.active svg polygon {
    fill: #ED0000 !important; }

.opv-rating__cta-button {
  position: fixed;
  bottom: 50px;
  width: 100vw;
  left: 0;
  z-index: 1; }
  @media (min-width: 992px) {
    .opv-rating__cta-button {
      position: relative;
      bottom: 0;
      width: auto; } }
  .opv-rating__cta-button .a-00800 {
    max-width: 100%; }

.opv-rating__additional-info {
  display: none;
  margin-top: 25px; }
  @media (min-width: 992px) {
    .opv-rating__additional-info {
      display: block; } }

.opv-rating__hotline {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: normal; }
  @media (min-width: 992px) {
    .opv-rating__hotline {
      font-size: 18px; } }
  .opv-rating__hotline a {
    font-family: "mmc-bold", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: normal; }
    @media (min-width: 992px) {
      .opv-rating__hotline a {
        font-size: 18px; } }

.opv-rating__icon {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  border: 3px solid #575656;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 55px auto 350px auto; }
  @media (min-width: 992px) {
    .opv-rating__icon {
      margin: 55px auto; } }
  .opv-rating__icon--thanks {
    border-color: #88B139; }
    .opv-rating__icon--thanks svg {
      width: 20px;
      height: 20px; }
  .opv-rating__icon--notRateable svg {
    width: 20px;
    height: 20px; }

@media (min-width: 992px) {
  .o-280 {
    padding-bottom: 85px; } }

.o-280__wrapper .header h1,
.o-280 .o-280 .a-00700 .text {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important; }

@keyframes moveBackLink {
  0% {
    left: 0; }
  40% {
    left: -5px; }
  100% {
    left: 0; } }

px-cars-overview {
  display: block;
  width: 100vw;
  left: -50vw;
  position: relative;
  margin-left: 50%;
  padding: 0 11px; }
  @media (min-width: 768px) {
    px-cars-overview {
      padding: 0 15px; } }
  @media (min-width: 992px) {
    px-cars-overview {
      padding: 0;
      width: auto;
      left: 0;
      margin-left: 0; } }

.jobDetail,
.detailPages {
  padding: 74px 0; }
  .jobDetail .job-content,
  .detailPages .job-content {
    padding-bottom: 32px; }
  .jobDetail .goBack,
  .detailPages .goBack {
    padding-top: 20px; }
  .jobDetail .gcoCategory,
  .detailPages .gcoCategory {
    padding-bottom: 20px; }
  .jobDetail .sidebar,
  .detailPages .sidebar {
    padding-left: 40px; }
  .jobDetail .headline,
  .detailPages .headline {
    margin-bottom: 1.1rem; }
  .jobDetail .subHeadline,
  .detailPages .subHeadline {
    text-transform: none;
    font-size: 20px; }
  .jobDetail .image,
  .detailPages .image {
    margin-bottom: 30px; }
  .jobDetail .sidebarTeaser,
  .detailPages .sidebarTeaser {
    margin-bottom: 27px; }
  .jobDetail .date,
  .detailPages .date {
    margin-bottom: 0.5rem;
    font-size: 15px; }

.thankyou {
  margin: 20px 0;
  padding: 162px 0; }
  .thankyou .thankText {
    padding: 30px; }

.imprint,
.dataSecurity {
  padding: 74px 0; }
  .imprint .label,
  .dataSecurity .label {
    min-width: 11%;
    max-width: 100%;
    display: inline-block; }
  .imprint p strong, .imprint strong,
  .dataSecurity p strong,
  .dataSecurity strong {
    font-family: "mmc-bold", Helvetica, Arial, sans-serif; }

.imprint ul {
  list-style-position: inside; }

.dataSecurity ul,
.dataSecurity ol {
  margin-bottom: 16px;
  padding-left: 20px; }

.dataSecurity a {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400; }

.error-page__wrapper {
  position: relative;
  height: 400px;
  width: 100%;
  margin: 40px 0; }
  @media (min-width: 992px) {
    .error-page__wrapper {
      margin: 50px 0;
      height: 500px; } }
  .error-page__wrapper::after {
    content: "";
    background-image: url(../Images/404.jpg);
    display: block;
    background-size: cover;
    background-position: center;
    opacity: 0.6;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1; }

.error-page__text {
  position: absolute;
  top: 56%;
  left: 20px; }
  @media (min-width: 992px) {
    .error-page__text {
      width: 50%;
      top: 40%;
      right: 20px;
      left: auto; } }

@media (min-width: 1244px) {
  .campaignDetail .module-bulletList .container {
    max-width: 1920px; } }

.campaignDetail strong,
.campaignDetail b {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif; }

.campaignDetail .marginTop--middle {
  margin-top: 52px; }

.campaignDetail .marginTop--large {
  margin-top: 75px; }

.campaignDetail .marginBottom--middle {
  margin-bottom: 52px; }

.campaignDetail .marginBottom--large {
  margin-bottom: 75px; }

.campaignDetail .headlineTop.center {
  text-align: center; }

.campaignDetail .headlineTop.first {
  margin: 75px 0 56px; }

.campaignDetail .multicol--teaser .module-image {
  margin-bottom: 21px; }

.campaignDetail .multicol--teaser .module-content {
  margin-bottom: 52px; }

.campaignDetail .multicol--grey {
  background-color: #E4E4E5;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-left: -24px;
  margin-right: -24px; }

.campaignDetail .module-content {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto; }
  @media (min-width: 992px) {
    .campaignDetail .module-content {
      font-size: 16px; } }

.campaignDetail .module-image .col-12 {
  display: flex;
  justify-content: center; }

.campaignDetail .imageRight {
  padding-right: 0; }
  @media (min-width: 992px) {
    .campaignDetail .imageRight {
      padding-left: 115px; } }
  @media (min-width: 768px) {
    .campaignDetail .imageRight {
      padding-left: 45px; } }

.campaignDetail .fullwidthContainer {
  padding: 0; }

.campaignDetail .module-fullwidthImage .a-01100--inline .text {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: black;
  word-wrap: break-word;
  hyphens: auto; }
  @media (min-width: 992px) {
    .campaignDetail .module-fullwidthImage .a-01100--inline .text {
      font-size: 16px; } }

.campaignDetail .module-fullwidthImage .a-01100--inline:hover .text {
  color: #575656; }

.campaignDetail .module-fullwidthImage .a-01100--inline:focus .text {
  color: #ED0000; }

.campaignDetail .module-fullwidthImage .a-01100--inline.disabled .text {
  color: #B1B2B3; }

.campaignDetail .module-textImageMultiCTA .mobileContent .contentwrapper .fromTablet--flex {
  display: block !important; }
  .campaignDetail .module-textImageMultiCTA .mobileContent .contentwrapper .fromTablet--flex .emailLink {
    margin: 10px 0 !important; }

.opv-unsubscribe__wrapper {
  padding: 90px 15px 140px; }

px-teasers {
  width: 100%; }

#pazgEdit .border-bottom {
  border: 0;
  border-bottom: 0 !important; }

#pazgEdit .form-group--uvp {
  display: flex;
  justify-content: flex-end; }
  #pazgEdit .form-group--uvp .input-group {
    width: auto; }
  #pazgEdit .form-group--uvp .input-group-addon {
    padding: 0px 0 6px 15px; }

#pazgEdit .pazgForm {
  margin-top: 0; }

#pazgEdit .box {
  background-color: #ebebeb;
  padding: 15px 15px 25px 15px; }

#pazgEdit .cta-col {
  display: flex;
  justify-content: space-between;
  padding: 30px 15px 90px 15px; }

#pazgEdit .cta {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  border: 0;
  background: 0;
  color: white; }
  #pazgEdit .cta:hover {
    color: #212529; }

#pazgEdit .pazg-reset {
  background-color: #ED0000;
  color: white; }

.neuwagen-edit__configuration-label {
  margin-top: 5px; }

.fixedSavebuttons {
  position: fixed;
  bottom: -1px;
  left: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 20px 0 15px 0;
  max-width: 480px;
  margin: 0 auto;
  border: 1px solid; }

.gallery-edit-grid > div {
  margin-bottom: 30px; }

.gallery-edit-grid .previewGalleryWrapper {
  position: relative;
  width: 100%;
  height: 100%; }

.footnotes strong,
.footnotes b {
  color: black;
  font-family: 'mmc-bold', Helvetica, Arial, sans-serif;
  font-weight: normal; }

.footnotes a {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif;
  color: #ED0000;
  font-size: 15px; }
  .footnotes a:hover {
    font-family: "mmc-regular", Helvetica, Arial, sans-serif;
    color: #ED0000; }
  @media (min-width: 992px) {
    .footnotes a {
      font-size: 16px; } }

.errorpage__row .errorpage__imageWrapper img {
  display: block;
  margin: auto; }

.errorpage__row .errorpage__text {
  position: absolute;
  top: 50%;
  left: 44%; }

.errorpage__row.mobile .errorpage__text {
  position: absolute;
  top: 9%;
  left: 9%; }
  @media screen and (min-width: 426px) {
    .errorpage__row.mobile .errorpage__text {
      top: 25%;
      left: 44%; } }

.jumbotron-fluid > .row {
  margin: 0; }

.nuclear-element-headline {
  margin-top: 30px;
  font-size: 18px;
  font-weight: bold;
  font-family: Arial; }

.editMask .headlineTop {
  font-family: AlphaWeb,Helvetica,Arial,sans-serif; }

.editMask h2, .editMask .price, .editMask .topbar {
  font-family: AlphaWeb,Helvetica,Arial,sans-serif;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 18px;
  padding-bottom: 8px;
  font-size: 24px; }

.editMask .has-error .jqte {
  border: 2px solid #ed0000; }

.editMask .has-error .form-control {
  border: 2px solid #ed0000; }

.editMask .has-error .radio-inline {
  border: 2px solid #ed0000; }

.editMask .bild {
  padding: 10px;
  background-color: #E4E4E5; }

.editMask .bild.has-error {
  border: 2px solid #ed0000;
  padding: 2px;
  border-radius: 5px; }

.editMask .buttonSave {
  background-color: #29aab3;
  color: #ffffff;
  transition: 0.3s;
  margin-right: 6px; }
  .editMask .buttonSave:hover {
    background-color: #29aab380;
    border: 1px solid #29aab3; }

.editMask .buttonReset {
  background-color: #c2c2c2;
  color: #ffffff;
  transition: 0.3s;
  margin-right: 6px; }
  .editMask .buttonReset:hover {
    background-color: #c2c2c280;
    border: 1px solid #c2c2c2; }

.editMask .buttinInverted {
  background-color: #ffffff;
  color: #29aab3;
  border: 1px solid #29aab3;
  transition: 0.3s;
  margin-right: 6px; }
  .editMask .buttinInverted:hover {
    color: #ffffff;
    background-color: #29aab380; }

.editMask .buttonDropzone {
  width: 100%;
  border: 1px solid black;
  border-color: #7d0008;
  background-color: #e3000f;
  color: #FFFFFF; }

.editMask div[class^="js-dropzone"] {
  cursor: pointer; }

.editMask .infoFileUpload {
  margin-top: 20px;
  margin-bottom: 10px; }

.editMask .alert-info {
  margin-top: 20px;
  border-color: #b0000c;
  background-color: rgba(227, 0, 15, 0.05); }

.editMask .icon-trash {
  background-image: url("../Icons/relaunch/edit_garbage.svg");
  display: inline-block;
  background-repeat: no-repeat;
  width: 1em;
  float: right;
  height: 1em;
  vertical-align: middle; }

.editMask .preview {
  position: relative; }

.editMask .preview-delete {
  position: absolute;
  background: #FFFFFF;
  padding: 6px;
  border: 1px solid black;
  top: 0;
  right: 0;
  cursor: pointer; }
  .editMask .preview-delete .icon-trash, .editMask .preview-delete .glyphicon-trash {
    background-image: url("../Icons/relaunch/edit_garbage.svg");
    display: inline-block;
    background-repeat: no-repeat;
    width: 1.4em;
    height: 1.4em;
    vertical-align: middle; }

.editMask .imagesUploadBtn {
  text-align: center;
  margin-top: 20px;
  padding: 20px 0;
  background-color: #E4E4E5; }

.editMask #drop-edit-area {
  margin-bottom: 24px;
  background-color: #E4E4E5;
  padding: 10px; }

.editMask del {
  color: red; }

.editMask ins {
  color: green; }

.editMask--dse .errors p {
  background-color: #e3000f;
  color: white;
  padding: 10px 15px;
  border-radius: 5px; }

.editMask .has-error + label::before {
  border: 2px solid #ed0000; }

.editEmployee .alert-info {
  margin-top: 20px; }

.editGreenmobilityBox .editGreenmobilityText {
  margin-bottom: 18px; }

.checkbox input {
  height: 25px;
  width: 25px; }

.checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
  margin-left: 20px; }
  .checkbox input[type=checkbox]:checked + label:after {
    content: 'x';
    font-size: 22px;
    font-weight: 600;
    left: 0;
    position: absolute;
    margin: 1px 0 0 7px;
    top: 0; }

.checkbox label {
  padding-left: 35px;
  height: 25px;
  position: relative;
  line-height: 20px;
  margin-bottom: 20px; }
  .checkbox label::before {
    height: 25px;
    width: 25px;
    content: '';
    border: 1px solid #CCC;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0; }

.editPageSettings .spaceBottom {
  margin-bottom: 20px; }

.pencilButton button {
  border-color: #29aab3;
  background-color: #29aab380; }

.pencilButton .pencil-icon {
  background-image: url("../Icons/relaunch/edit_pencil.svg");
  display: inline-block;
  background-repeat: no-repeat;
  width: 1em;
  height: 1em;
  vertical-align: middle; }

.pencil-icon, .glyphicon-pencil {
  background-image: url("../Icons/relaunch/edit_pencil.svg");
  display: inline-block;
  background-repeat: no-repeat;
  width: 1em;
  height: 1em;
  vertical-align: middle; }

.glyphicon-plus-sign, .plus-sign {
  background-image: url("../Icons/relaunch/edit_plus.svg");
  display: inline-block;
  background-repeat: no-repeat;
  width: 1em;
  height: 1em;
  vertical-align: middle; }

.glyphicon-sort {
  background-image: url("../Icons/relaunch/edit_sort.svg");
  display: inline-block;
  background-repeat: no-repeat;
  width: 1em;
  height: 1em;
  vertical-align: middle; }

.icon-print {
  background-image: url("../Icons/relaunch/edit_print.svg");
  display: inline-block;
  background-repeat: no-repeat;
  width: 1em;
  height: 1em;
  vertical-align: middle; }

.logoutButton {
  background-color: #e3000f;
  border-color: #7d0008; }
  .logoutButton .glyphicon-log-out {
    background-image: url("../Icons/relaunch/icon_suchen_weiss.svg");
    display: inline-block;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle; }

.propertiesEditButton {
  margin-left: auto;
  max-width: 1220px;
  width: 100%;
  margin-right: auto;
  display: block;
  margin-top: 16px; }

.googleEditButton {
  display: block;
  margin-top: 16px; }

.btn-editMode {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif; }
  .btn-editMode--saveBtnCurrent {
    color: #29aab3;
    background-color: #fff;
    border-color: #29aab3; }

.alert-info {
  border-color: #b0000c;
  background-color: rgba(227, 0, 15, 0.05); }

.campagneEdit {
  margin-bottom: 20px;
  margin-top: 20px; }
  .campagneEdit h2, .campagneEdit .price {
    font-family: AlphaWeb,Helvetica,Arial,sans-serif;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 8px;
    margin-bottom: 18px; }
  .campagneEdit h5 {
    font-weight: bold; }
  .campagneEdit .campaign {
    margin-bottom: 30px; }
  .campagneEdit .pencil-icon {
    background-image: url("../Icons/relaunch/icon_suchen_weiss.svg");
    display: inline-block;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle; }
  .campagneEdit .buttonSelectCampaign {
    padding: .375rem;
    color: #b0000c;
    margin-top: 2px; }
  .campagneEdit .box {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 10px 15px; }

.neuwagenEdit {
  margin: 14px auto; }
  .neuwagenEdit h1, .neuwagenEdit h2, .neuwagenEdit .price, .neuwagenEdit h3 {
    color: black; }
  .neuwagenEdit .btnPrint {
    background-color: #E4E4E5; }
  .neuwagenEdit .btnNewCar {
    border: 1px solid black;
    background-color: #575656;
    color: #FFFFFF; }
  .neuwagenEdit .newCar2Checkbox {
    margin: auto; }
  .neuwagenEdit .alert {
    padding: 30px;
    border-radius: 0; }
  .neuwagenEdit span {
    font-size: 16px;
    color: #000; }
  .neuwagenEdit .alert-default {
    background-color: #E4E4E5;
    border: 1px solid #575656; }
  .neuwagenEdit .panel-heading {
    background-color: #575656;
    padding: 10px;
    margin: 10px 0; }
    .neuwagenEdit .panel-heading span {
      color: #FFFFFF; }
    .neuwagenEdit .panel-heading .panel-title h4 {
      color: #FFFFFF !important; }
    .neuwagenEdit .panel-heading .fahrzeugRow {
      align-items: center; }
  .neuwagenEdit .panel-collapse {
    margin-top: 20px; }
  .neuwagenEdit .spaceBetween {
    margin-bottom: 10px; }
  .neuwagenEdit .checkbox label {
    font-size: 16px; }
    .neuwagenEdit .checkbox label:before {
      background-color: #FFFFFF; }
  .neuwagenEdit .glyphicon-more {
    background-image: url("../Icons/relaunch/icon_arrow_weiss_right.svg");
    display: inline-block;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle; }
  .neuwagenEdit .glyphicon-arrow-down {
    background-image: url("../Icons/relaunch/icon_arrow_weiss_down.svg");
    display: inline-block;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle; }
  .neuwagenEdit .neuwagenSortFilter {
    padding: 30px 0 10px;
    margin-top: 20px; }
  .neuwagenEdit .filterIcons {
    padding: 6px 0;
    margin-right: 15px; }
    .neuwagenEdit .filterIcons img {
      width: auto;
      height: 24px; }
  .neuwagenEdit .filterRow {
    display: flex;
    flex-direction: row; }
    .neuwagenEdit .filterRow--end {
      justify-content: flex-end;
      flex-grow: 1; }
    .neuwagenEdit .filterRow .selectpicker {
      width: auto;
      margin-right: 15px; }
      .neuwagenEdit .filterRow .selectpicker:last-child {
        margin-right: 0; }

.newCarsEdit .optionForm {
  background-color: #E4E4E5;
  padding: 12px; }

.newCarsEdit .input-group .input-group-addon {
  background-color: #575656; }

.editCampaigns h5 {
  font-weight: bold; }

.sortGalleries .sortableWrapper {
  background-color: #E4E4E5;
  margin-bottom: 12px; }

.sortGalleries Ich #slide-aktionen {
  margin-bottom: 12px; }

.sortGalleries .sortEmployee .sortableWrapper {
  margin-bottom: 12px;
  background-color: #E4E4E5; }

.input-group .input-group-addon {
  cursor: pointer;
  flex-basis: 20%;
  background-color: #E4E4E5;
  border-radius: 0;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .input-group .input-group-addon .iconCalendar {
    background-image: url("../Icons/relaunch/ic_calendar_datepicker_red.svg");
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    height: 28px;
    width: 28px;
    position: relative;
    vertical-align: middle; }

.ui-datepicker {
  width: auto;
  max-width: 100%;
  padding: 0;
  background: none;
  background-color: white; }
  .ui-datepicker td {
    width: 42px;
    height: 42px;
    max-width: 42px;
    max-height: 42üx; }
    .ui-datepicker td span,
    .ui-datepicker td a {
      text-align: center; }
  .ui-datepicker .ui-state-default,
  .ui-datepicker .ui-widget-content .ui-state-default {
    border: none;
    padding: 10px 0;
    color: black; }
  .ui-datepicker .ui-state-active,
  .ui-datepicker .ui-widget-content .ui-state-active,
  .ui-datepicker .ui-widget-header .ui-state-active {
    color: white !important;
    background-color: #575656;
    padding: 10px; }
  .ui-datepicker .ui-datepicker-header {
    background-color: #E4E4E5;
    padding: 10px 10px; }
  .ui-datepicker .ui-state-disabled,
  .ui-datepicker .ui-widget-content .ui-state-disabled,
  .ui-datepicker .ui-widget-header .ui-state-disabled {
    opacity: 1; }
    .ui-datepicker .ui-state-disabled .ui-state-default,
    .ui-datepicker .ui-widget-content .ui-state-disabled .ui-state-default,
    .ui-datepicker .ui-widget-header .ui-state-disabled .ui-state-default {
      background: transparent;
      color: black !important; }
  .ui-datepicker .ui-datepicker-week-end .ui-state-default {
    color: #999999 !important; }
  .ui-datepicker .ui-datepicker-title {
    color: black;
    font-weight: bold;
    text-align: center; }
  .ui-datepicker .ui-datepicker-prev,
  .ui-datepicker .ui-datepicker-next {
    opacity: 1; }
  .ui-datepicker .ui-icon-circle-triangle-w {
    content: '<'; }
  .ui-datepicker .ui-icon-circle-triangle-e, .ui-datepicker .ui-icon-circle-triangle-w {
    color: black;
    position: absolute;
    cursor: pointer; }
  .ui-datepicker .ui-icon-circle-triangle-e {
    right: 10px; }
  .ui-datepicker .ui-datepicker-calendar thead {
    background-color: #eaeaea;
    text-align: center; }
  .ui-datepicker .ui-datepicker-calendar tbody {
    background-color: white;
    text-align: center; }
  .ui-datepicker .ui-datepicker-calendar th {
    padding: 10px 0; }

.edit-mask-panel .buttonReset {
  background-color: #c2c2c2;
  color: #ffffff;
  transition: 0.3s;
  margin-right: 6px; }
  .edit-mask-panel .buttonReset:hover {
    background-color: #c2c2c280;
    border: 1px solid #c2c2c2; }

.edit-mask-panel .buttonSave {
  background-color: #29aab3;
  color: #ffffff;
  transition: 0.3s;
  margin-right: 6px; }
  .edit-mask-panel .buttonSave:hover {
    background-color: #29aab380;
    border: 1px solid #29aab3; }

.edit-mask-panel .m-0070 {
  padding-top: 35px; }

.edit-mask-panel .teaserEdit__newTeaserRow .editTeaser {
  top: 41px; }

.teaserEdit__content--campaigns {
  width: 100vw;
  max-width: 1220px; }
  .teaserEdit__content--campaigns .grid-wrapper {
    height: 680px !important; }

.teaserEdit__createForm p.error {
  margin: 0;
  color: #ED0000;
  font-size: 14px; }

.checkedArrow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  opacity: 0.5; }

.currentHeroImage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .currentHeroImage img {
    width: 100%; }

.heroImage__newTeaserContent {
  position: relative;
  height: 100%;
  padding: 15px 15px 65px;
  border: 1px solid #dee2e6; }
  .heroImage__newTeaserContent .btn {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 15px;
    float: none;
    width: calc(100% - 30px); }

.iconHeroimageEditHacken {
  position: absolute;
  width: 33%;
  top: 0;
  left: 50%;
  transform: translateX(-50%); }

.landingEdit__header {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px; }
  .landingEdit__header h2, .landingEdit__header .price {
    font-weight: bold;
    border: none; }
  .landingEdit__header button {
    margin-right: 10px; }

.landingEdit__item {
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.1); }

.landingEdit__itemImage {
  margin: 0px;
  padding: 0px; }

.landingEdit__itemButtons {
  padding-top: 15px; }
  .landingEdit__itemButtons button {
    margin-bottom: 5px;
    float: right;
    margin-right: 10px; }

.landingEdit__itemDescription {
  padding-top: 15px; }
  .landingEdit__itemDescription h2:first-child, .landingEdit__itemDescription .price:first-child {
    font-size: 14px; }

.editLandingButton button {
  width: 100%;
  border-radius: 0px; }

.landingChooseText {
  margin-bottom: 30px; }

.landingPageImage {
  padding: 0px 20px 0 20px; }

.langingType {
  margin-bottom: 50px; }

.landingButton {
  width: 150px; }

.mainFormHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  width: 100%;
  margin-bottom: 40px; }
  .mainFormHeader .form-group {
    margin-right: 50px; }
  @media (max-width: 767px) {
    .mainFormHeader {
      flex-direction: column !important; } }
  @media (max-width: 767px) {
    .mainFormHeader .teaserEdit__itemChecboxLabel {
      margin: 20px auto; } }

.imageTextForm .errorForm, .carForm .errorForm, .richTextForm .errorForm, .buttonForm .errorForm, .mainForm .errorForm {
  color: #e3000f;
  font-size: 12px;
  margin: 0px;
  padding: 0px; }

.langingPageStickyBar {
  position: sticky;
  bottom: 0px;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: white;
  border-top: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  margin-top: 20px;
  z-index: 1; }
  @media (max-width: 767px) {
    .langingPageStickyBar {
      bottom: 50px; } }

.textImageArea {
  display: flex; }

.landingTypeImage {
  position: absolute;
  right: 0px;
  padding-right: 10px;
  padding-top: 80px; }

@media screen and (max-width: 770px) {
  .landingTypeImage2, .landingTypeImageButton, .landingTypeImage, .landingTypeImageCar1, .landingTypeImageCar2, .landingTypeImageRichText, .landingTypeEmployee, .landingTypeImageRichText3 {
    display: none; } }

.landingTypeImage2, .landingTypeImageButton, .landingTypeImageCar1, .landingTypeImageCar2, .landingTypeImageRichText, .landingTypeEmployee, .landingTypeImageRichText3 {
  position: absolute;
  right: 0px; }

.landingTypeImage2 img {
  position: relative;
  bottom: 300px;
  padding-right: 10px; }

.landingTypeImageButton img {
  position: relative;
  bottom: 180px;
  padding-right: 10px; }

.landingTypeImageCar1 img {
  position: relative;
  top: -30px;
  padding-right: 10px; }

.landingTypeImageCar2 img {
  position: relative;
  top: -450px;
  padding-right: 10px; }

.landingTypeImageRichText img {
  position: relative;
  top: -240px;
  padding-right: 10px; }

.landingTypeEmployee img  {
  position: relative;
  bottom: 130px;
  padding-right: 10px; }

.landingTypeImageRichText3 img {
  position: relative;
  top: -240px;
  padding-right: 10px; }

px-edit-button[identifier="campaignslider"] {
  position: absolute;
  left: 15px;
  z-index: 10; }

@media (min-width: 992px) {
  .modal .modal-lg {
    max-width: 1220px; } }

.editModal__spinnerWrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.32);
  z-index: 1060; }
  .editModal__spinnerWrapper.active {
    display: flex;
    justify-content: center;
    align-items: center; }

@media (max-width: 992px) {
  .cmpboxrecall {
    bottom: 60px; } }

*.cmpbox *, *.cmpbox > * {
  font-family: "mmc-regular", Helvetica, Arial, sans-serif !important; }

*.cmpboxhl, .cmpfullscreen *.cmpprpshl, *.cmptbl th {
  font-family: "mmc-bold", Helvetica, Arial, sans-serif !important;
  font-weight: normal !important; }

.carousel {
  outline: none !important; }
  .carousel .carousel-item {
    opacity: 0;
    display: block;
    transition: opacity .3s ease-in-out;
    z-index: 0;
    background-color: white; }
    .carousel .carousel-item.active {
      opacity: 1;
      z-index: 1; }
  .carousel .carousel-control-prev,
  .carousel .carousel-control-next {
    width: 50px;
    cursor: pointer;
    opacity: .35 !important; }
    @media (max-width: 1243px) {
      .carousel .carousel-control-prev,
      .carousel .carousel-control-next {
        display: none; } }
  .carousel .carousel-control-prev {
    left: -60px; }
    .carousel .carousel-control-prev:before {
      content: "";
      background-image: url(/typo3conf/ext/mitsubishi_partnerseiten/Resources/Public/Icons/relaunch/icon_arrow_schwarz_left.svg);
      width: 25px;
      height: 40px;
      display: block;
      background-repeat: no-repeat; }
    .carousel .carousel-control-prev .carousel-control-prev-icon {
      display: none; }
  .carousel .carousel-control-next {
    right: -60px; }
    .carousel .carousel-control-next:before {
      content: "";
      background-image: url(/typo3conf/ext/mitsubishi_partnerseiten/Resources/Public/Icons/relaunch/icon_arrow_schwarz_right.svg);
      width: 25px;
      height: 40px;
      display: block;
      background-repeat: no-repeat; }
    .carousel .carousel-control-next .carousel-control-next-icon {
      display: none; }
  .carousel .carousel-indicators {
    height: 35px;
    bottom: -35px;
    display: flex;
    justify-content: center; }
    .carousel .carousel-indicators li {
      width: 5px;
      height: 5px;
      margin-left: 5px;
      border-radius: 50%;
      background-color: white;
      border: 2px solid transparent;
      box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.35);
      transition: all .3s ease-in-out; }
      .carousel .carousel-indicators li.active {
        background-color: #ED0000;
        border: 2px solid #ED0000; }

.c0010__container-wrapper_inner {
  position: relative; }
  .c0010__container-wrapper_inner .image-primitive px-lazy-image img {
    width: 100%; }
    @media (min-width: 992px) {
      .c0010__container-wrapper_inner .image-primitive px-lazy-image img {
        object-fit: cover;
        height: 811px; } }
    @media (min-width: 1244px) {
      .c0010__container-wrapper_inner .image-primitive px-lazy-image img {
        object-fit: cover;
        object-position: top;
        height: 940px; } }
  .c0010__container-wrapper_inner .c0010_content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
    .c0010__container-wrapper_inner .c0010_content .c0010_inner {
      padding: 0 15px 15px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; }
      @media (min-width: 577px) {
        .c0010__container-wrapper_inner .c0010_content .c0010_inner {
          padding: 0 30px 30px; } }
      @media (min-width: 768px) {
        .c0010__container-wrapper_inner .c0010_content .c0010_inner {
          padding: 0 40px 40px; } }
      @media (min-width: 992px) {
        .c0010__container-wrapper_inner .c0010_content .c0010_inner {
          padding: 0 50px 50px;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end; } }
      @media (min-width: 1244px) {
        .c0010__container-wrapper_inner .c0010_content .c0010_inner {
          padding: 0 80px 80px;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end; } }
      @media screen and (min-width: 992px) {
        .c0010__container-wrapper_inner .c0010_content .c0010_inner {
          flex-direction: row; } }
      .c0010__container-wrapper_inner .c0010_content .c0010_inner .c0010_text {
        width: 100%; }
        @media (min-width: 992px) {
          .c0010__container-wrapper_inner .c0010_content .c0010_inner .c0010_text {
            width: 860px;
            max-width: calc(100% - 290px); } }
        .c0010__container-wrapper_inner .c0010_content .c0010_inner .c0010_text .c0010_headline {
          color: white;
          font-size: 25px;
          line-height: 1.2;
          margin-bottom: 30px;
          word-break: break-word;
          hyphens: none; }
          @media (min-width: 577px) {
            .c0010__container-wrapper_inner .c0010_content .c0010_inner .c0010_text .c0010_headline {
              font-size: 40px; } }
          @media (min-width: 768px) {
            .c0010__container-wrapper_inner .c0010_content .c0010_inner .c0010_text .c0010_headline {
              font-size: 50px;
              margin-bottom: 40px; } }
          @media (min-width: 992px) {
            .c0010__container-wrapper_inner .c0010_content .c0010_inner .c0010_text .c0010_headline {
              margin-bottom: 0px;
              width: unset; } }
      .c0010__container-wrapper_inner .c0010_content .c0010_inner .c0010_btn .a-01000.a-01000--c {
        width: 250px;
        height: 42px; }
        @media (min-width: 577px) {
          .c0010__container-wrapper_inner .c0010_content .c0010_inner .c0010_btn .a-01000.a-01000--c {
            width: 220px;
            height: 42px; } }
        @media (min-width: 768px) {
          .c0010__container-wrapper_inner .c0010_content .c0010_inner .c0010_btn .a-01000.a-01000--c {
            width: 260px;
            height: 50px; } }
        .c0010__container-wrapper_inner .c0010_content .c0010_inner .c0010_btn .a-01000.a-01000--c:hover {
          background-color: transparent;
          opacity: 0.4; }
        .c0010__container-wrapper_inner .c0010_content .c0010_inner .c0010_btn .a-01000.a-01000--c .text, .c0010__container-wrapper_inner .c0010_content .c0010_inner .c0010_btn .a-01000.a-01000--c span {
          text-transform: none;
          font-size: 12px;
          line-height: 14px; }
          @media (min-width: 768px) {
            .c0010__container-wrapper_inner .c0010_content .c0010_inner .c0010_btn .a-01000.a-01000--c .text, .c0010__container-wrapper_inner .c0010_content .c0010_inner .c0010_btn .a-01000.a-01000--c span {
              font-size: 14px;
              line-height: 17px; } }

.c0011__container-wrapper {
  display: flex;
  justify-content: center;
  padding: 40px 15px; }
  @media (min-width: 576px) {
    .c0011__container-wrapper {
      padding: 70px 19px; } }
  @media (min-width: 992px) {
    .c0011__container-wrapper {
      padding: 105px 19px; } }
  @media (min-width: 992px) {
    .c0011__container-wrapper .inner-wrap {
      max-width: 800px; } }
  .c0011__container-wrapper .heading {
    font-size: 16px;
    margin-bottom: 23px; }
    @media (min-width: 576px) {
      .c0011__container-wrapper .heading {
        font-size: 25px;
        margin-bottom: 25px; } }
    @media (min-width: 992px) {
      .c0011__container-wrapper .heading {
        font-size: 30px; } }
  .c0011__container-wrapper .text-content, .c0011__container-wrapper .text-content p {
    font-size: 15px;
    line-height: 20px; }
    @media (min-width: 576px) {
      .c0011__container-wrapper .text-content, .c0011__container-wrapper .text-content p {
        font-size: 14px;
        line-height: 24px; } }
    @media (min-width: 992px) {
      .c0011__container-wrapper .text-content, .c0011__container-wrapper .text-content p {
        font-size: 16px;
        line-height: 30px; } }
    .c0011__container-wrapper .text-content :last-child, .c0011__container-wrapper .text-content p :last-child {
      margin-bottom: 0; }
  .c0011__container-wrapper strong {
    font-family: mmc-bold,Helvetica,Arial,sans-serif;
    font-weight: 400; }

.enkv-box {
  position: relative;
  color: #fff;
  font-size: 12px;
  line-height: 15px; }
  @media (max-width: 576px) {
    .enkv-box {
      margin-top: -1px; } }
  .enkv-box__inner {
    position: relative;
    width: 100%;
    max-height: 400px;
    overflow: hidden;
    transition: max-height 0.1s ease-in-out; }
    .enkv-box__inner.closed {
      max-height: 0; }
      .enkv-box__inner.closed + .enkv-box__arrow {
        transform: rotate(180deg); }
        .enkv-box__inner.closed + .enkv-box__arrow--black svg,
        .enkv-box__inner.closed + .enkv-box__arrow--black svg path {
          fill: black !important; }
  .enkv-box__content {
    padding: 18px;
    white-space: normal; }
    @media (min-width: 577px) {
      .enkv-box__content {
        padding: 9px 48px 9px 20px; } }
    @media (min-width: 992px) {
      .enkv-box__content {
        padding: 13px 80px 13px 30px; } }
    .enkv-box__content--bg-transpartent {
      background-color: #202020; }
      @media (min-width: 577px) {
        .enkv-box__content--bg-transpartent {
          background-color: rgba(28, 28, 28, 0.6); } }
    .enkv-box__content p {
      color: white;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 0; }
      @media (min-width: 768px) {
        .enkv-box__content p {
          font-size: 16px;
          line-height: 21px; } }
      .enkv-box__content p strong, .enkv-box__content p b {
        font-family: "mmc-bold", Helvetica, Arial, sans-serif;
        font-weight: normal; }
  .enkv-box__arrow {
    position: absolute;
    display: flex;
    height: 100%;
    min-height: 40px;
    width: 40px;
    bottom: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease-in-out;
    cursor: pointer; }
    @media (max-width: 576px) {
      .enkv-box__arrow {
        display: none; } }
    .enkv-box__arrow:hover {
      opacity: 0.4; }
    .enkv-box__arrow svg {
      width: 16px;
      height: 16px;
      transition: fill 0.5s ease-in-out; }
      @media (min-width: 768px) {
        .enkv-box__arrow svg {
          width: 22px;
          height: 22px; } }
      .enkv-box__arrow svg path {
        transition: fill 0.5s ease-in-out; }

.c-0012 {
  position: relative;
  font-size: 0;
  line-height: 0; }
  @media (max-width: 575px) {
    .c-0012 .slick-track {
      background-color: #202020; } }
  .c-0012 .m-0320_key_facts {
    display: none; }
    @media (min-width: 576px) {
      .c-0012 .m-0320_key_facts {
        display: block; } }
  .c-0012 .slick-next {
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 15px;
    cursor: pointer;
    transform: translateY(-15px); }
    @media (max-width: 575px) {
      .c-0012 .slick-next {
        top: 50vh; } }
    .c-0012 .slick-next::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      width: 30px;
      height: 30px;
      background-image: url(../Icons/arrow-next.svg);
      background-size: contain; }
    @media (min-width: 768px) {
      .c-0012 .slick-next {
        right: 30px;
        width: 45px;
        height: 45px;
        transform: translateY(-22px); }
        .c-0012 .slick-next::before {
          width: 45px;
          height: 45px; } }
    @media (min-width: 992px) {
      .c-0012 .slick-next {
        right: 60px;
        width: 60px;
        height: 60px;
        transform: translateY(-30px); }
        .c-0012 .slick-next::before {
          width: 60px;
          height: 60px; } }
  .c-0012 .slick-prev {
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 15px;
    cursor: pointer;
    transform: translateY(-15px) rotate(180deg); }
    @media (max-width: 575px) {
      .c-0012 .slick-prev {
        top: 50vh; } }
    .c-0012 .slick-prev::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      width: 30px;
      height: 30px;
      background-image: url(../Icons/arrow-prev.svg);
      background-size: contain; }
    @media (min-width: 768px) {
      .c-0012 .slick-prev {
        width: 45px;
        height: 45px;
        left: 30px;
        transform: translateY(-22px) rotate(180deg); }
        .c-0012 .slick-prev::before {
          width: 45px;
          height: 45px; } }
    @media (min-width: 992px) {
      .c-0012 .slick-prev {
        width: 60px;
        height: 60px;
        left: 60px;
        transform: translateY(-30px) rotate(180deg); }
        .c-0012 .slick-prev::before {
          width: 60px;
          height: 60px; } }
  .c-0012 .a-01000--c:hover {
    background-color: transparent;
    opacity: 0.4; }
  @media (max-width: 575px) {
    .c-0012 .m-0320 .image-primitive px-lazy-image img {
      min-height: 165.21vw; } }

.c0013__container-wrapper_inner {
  position: relative; }
  .c0013__container-wrapper_inner .image-primitive px-lazy-image img {
    width: 100%; }
    @media (min-width: 577px) {
      .c0013__container-wrapper_inner .image-primitive px-lazy-image img {
        object-fit: cover;
        height: 322px; } }
    @media (min-width: 992px) {
      .c0013__container-wrapper_inner .image-primitive px-lazy-image img {
        object-fit: cover;
        height: 625px; } }
  .c0013__container-wrapper_inner .c0013_content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
    .c0013__container-wrapper_inner .c0013_content .c0013_inner {
      padding: 0 15px 30px; }
      @media (min-width: 576px) {
        .c0013__container-wrapper_inner .c0013_content .c0013_inner {
          padding: 0 30px 30px; } }
      @media (min-width: 768px) {
        .c0013__container-wrapper_inner .c0013_content .c0013_inner {
          padding: 0 40px 30px; } }
      @media (min-width: 992px) {
        .c0013__container-wrapper_inner .c0013_content .c0013_inner {
          padding: 0 50px 50px; } }
      @media (min-width: 1244px) {
        .c0013__container-wrapper_inner .c0013_content .c0013_inner {
          padding: 0 80px 50px; } }
      .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_text .c0013_subheadline {
        color: white;
        font-size: 20px;
        line-height: 1.2;
        margin-bottom: 0; }
        @media (min-width: 576px) {
          .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_text .c0013_subheadline {
            font-size: 25px; } }
        @media (min-width: 992px) {
          .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_text .c0013_subheadline {
            font-size: 30px;
            line-height: 36px; } }
      .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_text h2.c0013_headline, .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_text .c0013_headline.price {
        color: white;
        font-family: "mmc-regular", Helvetica, Arial, sans-serif;
        font-size: 25px;
        line-height: 1.2;
        margin-bottom: 30px;
        word-break: break-word;
        hyphens: none; }
        @media (min-width: 576px) {
          .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_text h2.c0013_headline, .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_text .c0013_headline.price {
            font-size: 40px; } }
        @media (min-width: 992px) {
          .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_text h2.c0013_headline, .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_text .c0013_headline.price {
            font-size: 50px;
            margin-bottom: 80px; } }
      .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_btn .a-01000--c {
        width: 250px;
        height: 42px; }
        @media (min-width: 577px) {
          .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_btn .a-01000--c {
            width: 220px;
            height: 42px; } }
        @media (min-width: 768px) {
          .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_btn .a-01000--c {
            width: 260px;
            height: 50px; } }
        .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_btn .a-01000--c:hover {
          background-color: transparent;
          opacity: 0.4; }
        .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_btn .a-01000--c .text, .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_btn .a-01000--c span {
          text-transform: none;
          font-size: 12px; }
          @media (min-width: 768px) {
            .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_btn .a-01000--c .text, .c0013__container-wrapper_inner .c0013_content .c0013_inner .c0013_btn .a-01000--c span {
              font-size: 14px; } }

.c-0014 {
  position: relative;
  width: 100%; }
  .c-0014 .a-00500 img {
    width: 100%;
    margin-top: -1px; }
    @media (min-width: 992px) {
      .c-0014 .a-00500 img {
        object-fit: cover;
        height: 811px; } }
    @media (min-width: 1244px) {
      .c-0014 .a-00500 img {
        object-fit: cover;
        height: 940px;
        object-position: 50% 15%; } }
  .c-0014__content {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0; }
    .c-0014__content_inner {
      padding: 0 15px 30px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 26px; }
      @media (min-width: 576px) {
        .c-0014__content_inner {
          padding: 0 30px 30px;
          gap: 30px; } }
      @media (min-width: 768px) {
        .c-0014__content_inner {
          padding: 0 40px 40px; } }
      @media (min-width: 992px) {
        .c-0014__content_inner {
          padding: 0 50px 40px;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end; } }
      @media (min-width: 1244px) {
        .c-0014__content_inner {
          padding: 0 80px 50px; } }
      .c-0014__content_inner_text h1 {
        color: white;
        font-size: 25px;
        line-height: 1.2;
        margin-bottom: 10px; }
        @media (min-width: 576px) {
          .c-0014__content_inner_text h1 {
            font-size: 40px;
            margin-bottom: 15px; } }
        @media (min-width: 768px) {
          .c-0014__content_inner_text h1 {
            font-size: 50px;
            margin-bottom: 15px; } }
        @media (min-width: 992px) {
          .c-0014__content_inner_text h1 {
            font-size: 50px; } }
      .c-0014__content_inner_text .span-price {
        color: white;
        font-size: 16px;
        line-height: 19px; }
        @media (min-width: 576px) {
          .c-0014__content_inner_text .span-price {
            font-size: 25px;
            line-height: 30px; } }
        @media (min-width: 992px) {
          .c-0014__content_inner_text .span-price {
            font-size: 30px;
            line-height: 36px; } }
      .c-0014__content_inner_btn {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: 0;
        height: 100%;
        gap: 14px; }
        @media (min-width: 768px) {
          .c-0014__content_inner_btn {
            gap: 20px; } }
        .c-0014__content_inner_btn .a-01000--c {
          width: 250px;
          height: 42px; }
          @media (min-width: 577px) {
            .c-0014__content_inner_btn .a-01000--c {
              width: 220px;
              height: 42px; } }
          @media (min-width: 768px) {
            .c-0014__content_inner_btn .a-01000--c {
              width: 260px;
              height: 50px; } }
          .c-0014__content_inner_btn .a-01000--c:hover {
            background-color: transparent;
            opacity: 0.4; }
          .c-0014__content_inner_btn .a-01000--c .text,
          .c-0014__content_inner_btn .a-01000--c span {
            text-transform: none;
            font-size: 12px;
            line-height: 14px; }
            @media (min-width: 768px) {
              .c-0014__content_inner_btn .a-01000--c .text,
              .c-0014__content_inner_btn .a-01000--c span {
                font-size: 14px;
                line-height: 17px; } }

.c-0015 {
  position: relative;
  width: 100%; }
  .c-0015 .video_wrapper {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 61.5vw;
    width: 100%; }
    .c-0015 .video_wrapper .video_trigger {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      z-index: 9;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column; }
    .c-0015 .video_wrapper .video_layer {
      position: relative;
      display: block;
      height: inherit;
      width: inherit;
      margin: auto; }
      .c-0015 .video_wrapper .video_layer .cmplazypreviewiframe {
        width: 100% !important;
        height: 100% !important;
        background-color: transparent !important;
        background-image: none !important;
        text-align: center !important; }
      .c-0015 .video_wrapper .video_layer #ytplayer {
        width: 100%;
        height: 100%; }
  .c-0015 .c-0015__envkv {
    position: static;
    width: 100%; }
    @media (min-width: 768px) {
      .c-0015 .c-0015__envkv {
        position: absolute;
        bottom: 0; } }

.c-0016 {
  width: 100%;
  background-color: #000000; }
  .c-0016__content {
    text-align: center;
    padding: 15px 0; }
    @media (min-width: 576px) {
      .c-0016__content {
        padding: 30px 0; } }
    @media (min-width: 768px) {
      .c-0016__content {
        padding: 45px 0; } }
    @media (min-width: 992px) {
      .c-0016__content {
        padding: 53px 0; } }
    .c-0016__content_inner {
      padding: 25px 15px 0; }
      @media (min-width: 576px) {
        .c-0016__content_inner {
          padding: 30px 15px 0; } }
      @media (min-width: 768px) {
        .c-0016__content_inner {
          padding: 45px 15px 0; } }
    .c-0016__content_headline {
      color: white;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      margin-bottom: 0;
      padding: 0 15px; }
      @media (min-width: 576px) {
        .c-0016__content_headline {
          font-size: 21px;
          line-height: 25px; } }
      @media (min-width: 992px) {
        .c-0016__content_headline {
          font-size: 25px;
          line-height: 30px; } }
    .c-0016__content_price_wrap {
      margin-bottom: 0; }
    .c-0016__content_price_for {
      color: white;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 0; }
      @media (min-width: 576px) {
        .c-0016__content_price_for {
          font-size: 19px;
          line-height: 23px; } }
      @media (min-width: 992px) {
        .c-0016__content_price_for {
          font-size: 21px;
          line-height: 25px; } }
    .c-0016__content sup {
      font-size: 10px;
      line-height: 13px;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      width: 13px;
      height: 13px;
      display: inline-block;
      position: relative;
      top: -16px;
      left: 3px; }
    .c-0016__content_price_total {
      color: white;
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 4px; }
      @media (min-width: 576px) {
        .c-0016__content_price_total {
          font-size: 28px;
          line-height: 34px;
          margin-bottom: 1px; } }
      @media (min-width: 992px) {
        .c-0016__content_price_total {
          font-size: 31px;
          line-height: 37px;
          margin-bottom: 3px; } }
    .c-0016__content_copyText {
      color: white;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 0px; }
      @media (min-width: 576px) {
        .c-0016__content_copyText {
          font-size: 14px;
          line-height: 17px; } }
      @media (min-width: 992px) {
        .c-0016__content_copyText {
          font-size: 16px;
          line-height: 19px; } }
    .c-0016__content_text {
      color: white;
      font-family: "mmc-bold", Helvetica, Arial, sans-serif;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      padding: 15px 0;
      margin-bottom: 0; }
      @media (min-width: 576px) {
        .c-0016__content_text {
          font-size: 19px;
          line-height: 23px;
          padding: 15px 0 11px; } }
      @media (min-width: 992px) {
        .c-0016__content_text {
          font-size: 21px;
          line-height: 25px;
          padding: 15px 0 13px; } }

.c-0017 {
  position: relative;
  width: 100%; }
  .c-0017 .a-00500.a-00500--d img {
    width: 100%;
    margin-bottom: -1px; }
    @media (min-width: 992px) {
      .c-0017 .a-00500.a-00500--d img {
        object-fit: cover;
        min-height: 811px; } }
    @media (min-width: 1920px) {
      .c-0017 .a-00500.a-00500--d img {
        object-fit: cover;
        height: 940px; } }
  .c-0017__content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0; }
    .c-0017__content_text {
      padding: 25px 15px 0;
      width: 100%;
      max-width: 450px; }
      @media (min-width: 576px) {
        .c-0017__content_text {
          padding: 70px 30px 0;
          max-width: unset; } }
      @media (min-width: 768px) {
        .c-0017__content_text {
          padding: 70px 40px 0;
          max-width: 880px; } }
      @media (min-width: 992px) {
        .c-0017__content_text {
          padding: 70px 50px 0;
          max-width: 900px;
          justify-content: flex-end; } }
      @media (min-width: 1244px) {
        .c-0017__content_text {
          padding: 70px 80px 0;
          max-width: 960px; } }
      .c-0017__content_text .subHeadline {
        color: white;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 0;
        text-transform: none; }
        @media (min-width: 576px) {
          .c-0017__content_text .subHeadline {
            font-size: 25px;
            line-height: 30px; } }
        @media (min-width: 768px) {
          .c-0017__content_text .subHeadline {
            font-size: 30px;
            line-height: 36px; } }
      .c-0017__content_text .headline {
        color: white;
        font-family: "mmc-regular", Helvetica, Arial, sans-serif;
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 15px; }
        @media (min-width: 576px) {
          .c-0017__content_text .headline {
            font-size: 40px;
            line-height: 48px; } }
        @media (min-width: 768px) {
          .c-0017__content_text .headline {
            font-size: 50px;
            line-height: 60px; } }
      .c-0017__content_text .copy {
        color: white;
        font-size: 15px;
        line-height: 1.4;
        word-break: break-word;
        hyphens: none; }
        @media (min-width: 576px) {
          .c-0017__content_text .copy {
            font-size: 15px;
            line-height: 1.6;
            max-width: 540px; } }
        @media (min-width: 768px) {
          .c-0017__content_text .copy {
            font-size: 16px;
            line-height: 30px;
            max-width: unset; } }
  .c-0017__envkv {
    position: static;
    width: 100%; }
    @media (min-width: 768px) {
      .c-0017__envkv {
        position: absolute;
        bottom: 0; } }

.c-0018 {
  width: calc(100% + 3px);
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .c-0018 {
      flex-direction: row; } }
  .c-0018 .image-primitive {
    width: 100%;
    margin-top: -2px;
    margin-bottom: -2px;
    margin-right: -1px;
    margin-left: -1px; }
    .c-0018 .image-primitive img {
      width: 100%; }
    @media (min-width: 768px) {
      .c-0018 .image-primitive {
        width: 50%; } }

.c-0019 {
  width: 100%;
  overflow: hidden; }
  .c-0019__content {
    padding: 30px 30px 0; }
    @media (min-width: 576px) {
      .c-0019__content {
        padding: 50px 30px 0; } }
    @media (min-width: 768px) {
      .c-0019__content {
        padding: 48px 40px 0; } }
    @media (min-width: 992px) {
      .c-0019__content {
        padding: 50px 50px 0; } }
    @media (min-width: 992px) {
      .c-0019__content {
        padding: 50px 80px 0; } }
    .c-0019__content__subheadline h3 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0; }
      @media (min-width: 576px) {
        .c-0019__content__subheadline h3 {
          font-size: 25px;
          line-height: 30px; } }
      @media (min-width: 768px) {
        .c-0019__content__subheadline h3 {
          font-size: 30px;
          line-height: 36px; } }
    .c-0019__content__headline h2, .c-0019__content__headline .price {
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 20px; }
      @media (min-width: 576px) {
        .c-0019__content__headline h2, .c-0019__content__headline .price {
          font-size: 40px;
          line-height: 48px;
          margin-bottom: 40px; } }
      @media (min-width: 768px) {
        .c-0019__content__headline h2, .c-0019__content__headline .price {
          font-size: 50px;
          line-height: 60px;
          margin-bottom: 47px; } }
      @media (min-width: 992px) {
        .c-0019__content__headline h2, .c-0019__content__headline .price {
          margin-bottom: 65px; } }
  .c-0019__sliderWrapper .slick-list {
    overflow: visible;
    margin: 0 0; }
    @media (min-width: 768px) {
      .c-0019__sliderWrapper .slick-list {
        margin: 0 -30px; } }
    @media (min-width: 992px) {
      .c-0019__sliderWrapper .slick-list {
        margin: 0 -60px; } }
  .c-0019__sliderWrapper .slick-slide {
    opacity: 40%;
    width: 100vw;
    margin: 0 0;
    transition: opacity 0.3s ease-in-out; }
    @media (min-width: 768px) {
      .c-0019__sliderWrapper .slick-slide {
        max-width: 768px;
        margin: 0 30px; } }
    @media (min-width: 992px) {
      .c-0019__sliderWrapper .slick-slide {
        max-width: 950px;
        margin: 0 60px; } }
    .c-0019__sliderWrapper .slick-slide.slick-current {
      opacity: 1;
      transition: opacity 0.3s ease-in-out; }
  .c-0019__sliderWrapper .slick-active {
    opacity: 1; }
  .c-0019__sliderWrapper .slick-next {
    position: absolute;
    top: calc((((100vw / 4) * 3) / 2) - 15px);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: rgba(16, 16, 16, 0.6);
    z-index: 1;
    right: -27px;
    cursor: pointer; }
    .c-0019__sliderWrapper .slick-next:hover {
      background: rgba(16, 16, 16, 0.6);
      opacity: 0.4; }
    .c-0019__sliderWrapper .slick-next:focus {
      background: rgba(16, 16, 16, 0.6); }
    @media (min-width: 576px) {
      .c-0019__sliderWrapper .slick-next {
        top: calc((((100vw / 4) * 3) / 2) - 37px);
        right: -39px;
        width: 75px;
        height: 75px; } }
    @media (min-width: 768px) {
      .c-0019__sliderWrapper .slick-next {
        top: calc((((768px / 4) * 3) / 2) - 37px);
        right: 30px; } }
    @media (min-width: 992px) {
      .c-0019__sliderWrapper .slick-next {
        top: calc((((950px / 4) * 3) / 2) - 37px);
        right: 80px; } }
    .c-0019__sliderWrapper .slick-next::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url(../Icons/relaunch/icon_arrow_weiss_right.svg);
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: 10px center; }
      @media (min-width: 576px) {
        .c-0019__sliderWrapper .slick-next::before {
          background-size: 19px 19px;
          background-position: 10px center; } }
      @media (min-width: 768px) {
        .c-0019__sliderWrapper .slick-next::before {
          background-size: 19px 19px;
          background-position: center center; } }
    .c-0019__sliderWrapper .slick-next.slick-disabled {
      opacity: 0;
      cursor: default; }
  .c-0019__sliderWrapper .slick-prev {
    position: absolute;
    top: calc((((100vw / 4) * 3) / 2) - 15px);
    width: 55px;
    height: 55px;
    z-index: 1;
    border-radius: 50%;
    background: rgba(16, 16, 16, 0.6);
    left: -27px;
    cursor: pointer; }
    .c-0019__sliderWrapper .slick-prev:hover {
      background: rgba(16, 16, 16, 0.6);
      opacity: 0.4; }
    .c-0019__sliderWrapper .slick-prev:focus {
      background: rgba(16, 16, 16, 0.6); }
    @media (min-width: 576px) {
      .c-0019__sliderWrapper .slick-prev {
        top: calc((((100vw / 4) * 3) / 2) - 37px);
        left: -39px;
        width: 75px;
        height: 75px; } }
    @media (min-width: 768px) {
      .c-0019__sliderWrapper .slick-prev {
        top: calc((((768px / 4) * 3) / 2) - 37px);
        left: 30px; } }
    @media (min-width: 992px) {
      .c-0019__sliderWrapper .slick-prev {
        top: calc((((950px / 4) * 3) / 2) - 37px);
        left: 80px; } }
    .c-0019__sliderWrapper .slick-prev::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url(../Icons/relaunch/icon_arrow_weiss_left.svg);
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: 30px center; }
      @media (min-width: 576px) {
        .c-0019__sliderWrapper .slick-prev::before {
          background-size: 19px 19px;
          background-position: 43px center; } }
      @media (min-width: 768px) {
        .c-0019__sliderWrapper .slick-prev::before {
          background-size: 19px 19px;
          background-position: center center; } }
    .c-0019__sliderWrapper .slick-prev.slick-disabled {
      opacity: 0;
      cursor: default; }
  .c-0019__sliderWrapper .c-0019__sliderItem {
    padding: 0;
    max-width: 100vw; }
    @media (min-width: 768px) {
      .c-0019__sliderWrapper .c-0019__sliderItem {
        max-width: 768px; } }
    @media (min-width: 992px) {
      .c-0019__sliderWrapper .c-0019__sliderItem {
        max-width: 950px; } }
    .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__content {
      padding: 20px 15px; }
      @media (min-width: 576px) {
        .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__content {
          padding: 25px 30px; } }
      @media (min-width: 992px) {
        .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__content {
          padding: 30px 40px; } }
      .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__content .m-0065__headline {
        font-family: "mmc-regular", Helvetica, Arial, sans-serif;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 15px; }
        @media (min-width: 576px) {
          .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__content .m-0065__headline {
            font-size: 21px;
            line-height: 25px; } }
        @media (min-width: 768px) {
          .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__content .m-0065__headline {
            font-size: 25px;
            line-height: 30px; } }
      .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__content .m-0065__copy {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 20px; }
        @media (min-width: 576px) {
          .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__content .m-0065__copy {
            line-height: 26px; } }
        @media (min-width: 768px) {
          .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__content .m-0065__copy {
            line-height: 30px; } }
        @media (min-width: 992px) {
          .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__content .m-0065__copy {
            font-size: 16px;
            line-height: 30px; } }
      .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__content .m-0065__price {
        font-family: "mmc-regular", Helvetica, Arial, sans-serif;
        color: black;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px; }
        @media (min-width: 576px) {
          .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__content .m-0065__price {
            font-size: 21px;
            line-height: 25px; } }
        @media (min-width: 768px) {
          .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__content .m-0065__price {
            font-size: 25px;
            line-height: 30px; } }
    .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__image-wrapper {
      position: relative; }
      .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__image-wrapper .m-033__envkv {
        position: static; }
        @media (min-width: 576px) {
          .c-0019__sliderWrapper .c-0019__sliderItem .m-0330__image-wrapper .m-033__envkv {
            position: absolute;
            bottom: 0;
            z-index: 10; } }
  .c-0019__sliderWrapper ul.slick-dots {
    position: static;
    padding: 0 0 15px; }
    @media (min-width: 768px) {
      .c-0019__sliderWrapper ul.slick-dots {
        padding: 15px 0 33px; } }
    @media (min-width: 992px) {
      .c-0019__sliderWrapper ul.slick-dots {
        padding: 46px 0 53px; } }
    .c-0019__sliderWrapper ul.slick-dots li {
      width: 15px;
      height: 15px;
      margin: 0 15px 0 0; }
      .c-0019__sliderWrapper ul.slick-dots li.slick-active button, .c-0019__sliderWrapper ul.slick-dots li:hover button {
        background-color: #000;
        border: 2px solid #000; }
      .c-0019__sliderWrapper ul.slick-dots li button {
        width: 15px;
        height: 15px;
        background-color: #D2D2D2;
        border: 2px solid #D2D2D2; }
        .c-0019__sliderWrapper ul.slick-dots li button::before {
          width: 15px;
          height: 15px; }

.campaignDetail .fullwidthContainer {
  overflow: hidden;
  margin-top: -1px; }

.campaignDetail .module-slider .col-12 {
  padding: 0; }

.module-bulletList .container {
  max-width: 100%; }

.c-0020 {
  position: relative; }
  .c-0020 .o-070__sections-desktop {
    margin: 0;
    padding: 25px 15px; }
    @media (min-width: 576px) {
      .c-0020 .o-070__sections-desktop {
        padding: 45px 40px; } }
    @media (min-width: 768px) {
      .c-0020 .o-070__sections-desktop {
        padding: 50px 40px; } }
    @media (min-width: 992px) {
      .c-0020 .o-070__sections-desktop {
        padding: 55px 55px; } }
    @media (min-width: 1920px) {
      .c-0020 .o-070__sections-desktop {
        padding: 55px 80px; } }
    .c-0020 .o-070__sections-desktop .m-0160 h3.cars-detail__headline {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 26px; }
      @media (min-width: 576px) {
        .c-0020 .o-070__sections-desktop .m-0160 h3.cars-detail__headline {
          font-size: 25px;
          line-height: 30px;
          margin-bottom: 44px;
          max-width: 630px; } }
      @media (min-width: 992px) {
        .c-0020 .o-070__sections-desktop .m-0160 h3.cars-detail__headline {
          font-size: 30px;
          line-height: 38px;
          margin-bottom: 47px;
          max-width: 951px; } }
    .c-0020 .o-070__sections-desktop .m-0160__bulletlist--campaign {
      row-gap: 20px;
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 337px)); }
      @media (min-width: 576px) {
        .c-0020 .o-070__sections-desktop .m-0160__bulletlist--campaign {
          gap: 25px;
          row-gap: 25px;
          grid-template-columns: repeat(2, minmax(0, 281px));
          max-width: 653px;
          justify-content: space-between; } }
      @media (min-width: 992px) {
        .c-0020 .o-070__sections-desktop .m-0160__bulletlist--campaign {
          max-width: 1172px;
          justify-content: space-between;
          grid-template-columns: repeat(3, minmax(0, 324px)); } }
      @media (min-width: 1244px) {
        .c-0020 .o-070__sections-desktop .m-0160__bulletlist--campaign {
          max-width: unset;
          grid-template-columns: repeat(4, minmax(0, 324px)); } }
      .c-0020 .o-070__sections-desktop .m-0160__bulletlist--campaign px-a02700 {
        max-width: 337px;
        width: 100%;
        margin: 0; }
        @media (min-width: 576px) {
          .c-0020 .o-070__sections-desktop .m-0160__bulletlist--campaign px-a02700 {
            max-width: 281px; } }
        @media (min-width: 992px) {
          .c-0020 .o-070__sections-desktop .m-0160__bulletlist--campaign px-a02700 {
            max-width: 324px; } }
        .c-0020 .o-070__sections-desktop .m-0160__bulletlist--campaign px-a02700 .a-02700__text {
          font-size: 15px;
          line-height: 18px; }
          @media (min-width: 992px) {
            .c-0020 .o-070__sections-desktop .m-0160__bulletlist--campaign px-a02700 .a-02700__text {
              font-size: 16px;
              line-height: 19px; } }

.c0014__teaser__container-wrapper_inner {
  position: relative; }
  .c0014__teaser__container-wrapper_inner .image-primitive px-lazy-image img {
    width: 100%; }
    @media (min-width: 577px) {
      .c0014__teaser__container-wrapper_inner .image-primitive px-lazy-image img {
        object-fit: cover;
        height: 290px; } }
    @media (min-width: 992px) {
      .c0014__teaser__container-wrapper_inner .image-primitive px-lazy-image img {
        object-fit: cover;
        height: 425px; } }
  .c0014__teaser__container-wrapper_inner .c0014__teaser_content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
    .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner {
      padding: 0 15px 30px; }
      @media (min-width: 576px) {
        .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner {
          padding: 0 30px 30px; } }
      @media (min-width: 768px) {
        .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner {
          padding: 0 40px 30px; } }
      @media (min-width: 992px) {
        .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner {
          padding: 0 50px 50px; } }
      @media (min-width: 1244px) {
        .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner {
          padding: 0 80px 50px; } }
      .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_text {
        width: 500px;
        max-width: 100%; }
        @media (min-width: 1244px) {
          .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_text {
            width: 100%; } }
        .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_text .c0014__teaser_subheadline {
          color: white;
          font-size: 20px;
          line-height: 1.2;
          margin-bottom: 0; }
          @media (min-width: 576px) {
            .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_text .c0014__teaser_subheadline {
              font-size: 25px; } }
          @media (min-width: 992px) {
            .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_text .c0014__teaser_subheadline {
              font-size: 30px;
              line-height: 36px; } }
        .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_text h2.c0014__teaser_headline, .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_text .c0014__teaser_headline.price {
          color: white;
          font-family: "mmc-regular", Helvetica, Arial, sans-serif;
          font-size: 25px;
          line-height: 1.2;
          margin-bottom: 30px;
          word-break: break-word;
          hyphens: none; }
          @media (min-width: 576px) {
            .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_text h2.c0014__teaser_headline, .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_text .c0014__teaser_headline.price {
              font-size: 40px; } }
          @media (min-width: 992px) {
            .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_text h2.c0014__teaser_headline, .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_text .c0014__teaser_headline.price {
              font-size: 50px;
              margin-bottom: 80px; } }
      .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_btn .a-01000--c {
        width: 250px;
        height: 42px; }
        @media (min-width: 577px) {
          .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_btn .a-01000--c {
            width: 220px;
            height: 42px; } }
        @media (min-width: 768px) {
          .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_btn .a-01000--c {
            width: 260px;
            height: 50px; } }
        .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_btn .a-01000--c:hover {
          background-color: transparent;
          opacity: 0.4; }
        .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_btn .a-01000--c .text, .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_btn .a-01000--c span {
          text-transform: none;
          font-size: 12px; }
          @media (min-width: 768px) {
            .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_btn .a-01000--c .text, .c0014__teaser__container-wrapper_inner .c0014__teaser_content .c0014__teaser_inner .c0014__teaser_btn .a-01000--c span {
              font-size: 14px; } }

.c-0021 {
  width: 100%;
  overflow: hidden;
  padding: 0 0 50px 0; }
  .c-0021__content {
    padding: 30px 30px 0;
    width: 100%; }
    @media (min-width: 576px) {
      .c-0021__content {
        padding: 50px 30px 0; } }
    @media (min-width: 768px) {
      .c-0021__content {
        padding: 48px 40px 0; } }
    @media (min-width: 992px) {
      .c-0021__content {
        padding: 50px 50px 0; } }
    @media (min-width: 992px) {
      .c-0021__content {
        padding: 50px 80px 0; } }
    .c-0021__content__subheadline h3 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0; }
      @media (min-width: 576px) {
        .c-0021__content__subheadline h3 {
          font-size: 25px;
          line-height: 36px; } }
      @media (min-width: 992px) {
        .c-0021__content__subheadline h3 {
          font-size: 30px;
          line-height: 36px; } }
    .c-0021__content__headline h2, .c-0021__content__headline .price {
      font-family: "mmc-regular", Helvetica, Arial, sans-serif;
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 20px; }
      @media (min-width: 576px) {
        .c-0021__content__headline h2, .c-0021__content__headline .price {
          font-size: 40px;
          line-height: 60px;
          margin-bottom: 47px; } }
      @media (min-width: 992px) {
        .c-0021__content__headline h2, .c-0021__content__headline .price {
          font-size: 50px;
          line-height: 60px;
          margin-bottom: 65px; } }
  .c-0021__sliderWrapper {
    width: 100%; }
    .c-0021__sliderWrapper .slick-list {
      padding: 0 0 20px 0;
      width: 100%; }
      @media (min-width: 576px) {
        .c-0021__sliderWrapper .slick-list {
          padding: 0 30px 20px; } }
      @media (min-width: 768px) {
        .c-0021__sliderWrapper .slick-list {
          padding: 0 40px 20px; } }
      @media (min-width: 992px) {
        .c-0021__sliderWrapper .slick-list {
          padding: 0 50px 20px; } }
      @media (min-width: 992px) {
        .c-0021__sliderWrapper .slick-list {
          padding: 0 60px 20px; } }
    .c-0021__sliderWrapper .slick-slide {
      width: 420px;
      margin: 0 0;
      max-width: 422px; }
    .c-0021__sliderWrapper .slick-next {
      position: absolute;
      top: calc((((100vw / 4) * 3) / 2) - 27px);
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background: rgba(16, 16, 16, 0.6);
      z-index: 1;
      right: -27px;
      cursor: pointer; }
      .c-0021__sliderWrapper .slick-next:hover {
        background: rgba(16, 16, 16, 0.6);
        opacity: 0.4; }
      .c-0021__sliderWrapper .slick-next:focus {
        background: rgba(16, 16, 16, 0.6); }
      @media (min-width: 576px) {
        .c-0021__sliderWrapper .slick-next {
          top: calc((((100vw / 4) * 3) / 2) - 27px);
          right: -39px;
          width: 75px;
          height: 75px; } }
      @media (min-width: 768px) {
        .c-0021__sliderWrapper .slick-next {
          top: calc((((768px / 4) * 3) / 2) - 37px);
          right: 30px; } }
      @media (min-width: 992px) {
        .c-0021__sliderWrapper .slick-next {
          top: calc((((950px / 4) * 3) / 2) - 37px);
          right: 80px; } }
      .c-0021__sliderWrapper .slick-next::before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(../Icons/relaunch/icon_arrow_weiss_right.svg);
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: 10px center; }
        @media (min-width: 576px) {
          .c-0021__sliderWrapper .slick-next::before {
            background-size: 19px 19px;
            background-position: 10px center; } }
        @media (min-width: 768px) {
          .c-0021__sliderWrapper .slick-next::before {
            background-size: 19px 19px;
            background-position: center center; } }
      .c-0021__sliderWrapper .slick-next.slick-disabled {
        opacity: 0;
        cursor: default; }
    .c-0021__sliderWrapper .slick-prev {
      position: absolute;
      top: calc((((100vw / 4) * 3) / 2) - 27px);
      width: 55px;
      height: 55px;
      z-index: 1;
      border-radius: 50%;
      background: rgba(16, 16, 16, 0.6);
      left: -27px;
      cursor: pointer; }
      .c-0021__sliderWrapper .slick-prev:hover {
        background: rgba(16, 16, 16, 0.6);
        opacity: 0.4; }
      .c-0021__sliderWrapper .slick-prev:focus {
        background: rgba(16, 16, 16, 0.6); }
      @media (min-width: 576px) {
        .c-0021__sliderWrapper .slick-prev {
          top: calc((((100vw / 4) * 3) / 2) - 27px);
          left: -39px;
          width: 75px;
          height: 75px; } }
      @media (min-width: 768px) {
        .c-0021__sliderWrapper .slick-prev {
          top: calc((((768px / 4) * 3) / 2) - 37px);
          left: 30px; } }
      @media (min-width: 992px) {
        .c-0021__sliderWrapper .slick-prev {
          top: calc((((950px / 4) * 3) / 2) - 37px);
          left: 80px; } }
      .c-0021__sliderWrapper .slick-prev::before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(../Icons/relaunch/icon_arrow_weiss_left.svg);
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: 30px center; }
        @media (min-width: 576px) {
          .c-0021__sliderWrapper .slick-prev::before {
            background-size: 19px 19px;
            background-position: 43px center; } }
        @media (min-width: 768px) {
          .c-0021__sliderWrapper .slick-prev::before {
            background-size: 19px 19px;
            background-position: center center; } }
      .c-0021__sliderWrapper .slick-prev.slick-disabled {
        opacity: 0;
        cursor: default; }
    .c-0021__sliderWrapper .c-0021__sliderItem {
      width: 390px;
      max-width: calc(100vw - 30px);
      height: 820px;
      padding: 0 0 0 20px; }
      @media (min-width: 576px) {
        .c-0021__sliderWrapper .c-0021__sliderItem {
          max-width: 390px; } }
      .c-0021__sliderWrapper .c-0021__sliderItem .m-0060 .a-00600 {
        height: 270px;
        width: 100%; }
        .c-0021__sliderWrapper .c-0021__sliderItem .m-0060 .a-00600 img {
          height: 270px;
          width: auto; }
      .c-0021__sliderWrapper .c-0021__sliderItem .m-0350__default-wrapper .a-00600 {
        height: 270px;
        width: 100%; }
        .c-0021__sliderWrapper .c-0021__sliderItem .m-0350__default-wrapper .a-00600 img {
          height: 270px;
          width: auto; }
      .c-0021__sliderWrapper .c-0021__sliderItem .m-0350__wrapper .a-00600 {
        height: 270px;
        width: 100%; }
        .c-0021__sliderWrapper .c-0021__sliderItem .m-0350__wrapper .a-00600 img {
          height: 270px;
          width: auto; }
    .c-0021__sliderWrapper ul.slick-dots {
      position: static;
      padding: 0 0 15px; }
      @media (min-width: 768px) {
        .c-0021__sliderWrapper ul.slick-dots {
          padding: 15px 0 33px; } }
      @media (min-width: 992px) {
        .c-0021__sliderWrapper ul.slick-dots {
          padding: 46px 0 53px; } }
      .c-0021__sliderWrapper ul.slick-dots li {
        width: 15px;
        height: 15px;
        margin: 0 15px 0 0; }
        .c-0021__sliderWrapper ul.slick-dots li.slick-active button, .c-0021__sliderWrapper ul.slick-dots li:hover button {
          background-color: #000;
          border: 2px solid #000; }
        .c-0021__sliderWrapper ul.slick-dots li button {
          width: 15px;
          height: 15px;
          background-color: #D2D2D2;
          border: 2px solid #D2D2D2; }
          .c-0021__sliderWrapper ul.slick-dots li button::before {
            width: 15px;
            height: 15px; }
